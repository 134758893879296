import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  //https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  //https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  //https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,
    backend: {
        loadPath: '/locales/{{lng}}.json',
    },
    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;