import React, { Component } from 'react';
import { LogoutOutlined, MessageOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import BASE_URL from '../../config';
import logoYs from '../../assets/img/logoYS.png';
import { Badge } from 'antd';
import { Link } from 'react-router-dom';
class Header extends Component {
  
  btn_logout;
  nav_fixed_top;
  state = {
    bg: ""
  }
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.lang = this.lang.bind(this);
  }

  lang = (langue) => (e) => {
    this.props.i18n.changeLanguage(langue);

  }

  logout() {
    this.props.callLogout()
      .then(() => {

      });

  }
  componentDidMount() {
    if (this.props.isAuth === true) {
      setTimeout(() => {
        /*const Authorization = localStorage.getItem('token');
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': Authorization
        };

        axios.get(`${BASE_URL}/api/config`, { headers })
          .then(res => {
            const configs = res.data.config;
            configs.forEach((item) => {
              if (item.key === "bg") {
                this.setState({ bg: item.value });
              }
            })
          })
          .catch(error => {
            console.log(error);
          });*/
      }, 500);
    }
  }
  handleClick = () => {
    this.props.history.push("/about");
  };
  render() {

    let src_logo;
    let btn_logout;
    if (this.props.isAuth === true) {
      src_logo = this.state.bg;

      btn_logout = (
        <span>
          <button className="btn btn-link" onClick={this.logout}> <LogoutOutlined /></button>
        </span>
      );
    } else {
      src_logo = "bg.svg";
    }

    return (
      <nav className="navbar navbar-white bg-white flex-md-nowrap p-0 justify-content-between">
        <a className="navbar-brand col-sm-3 col-md-2 mr-0 py-1" href="/">
          <img src={logoYs}
            width="120"
            alt="Yachting Spirit logo"
            title="Yachting Spirit"
          />
        </a>
        <ul className="nav justify-content-end">
          {/* <li className="nav-item">
            <span className="nav-link" onClick={this.lang('en')}>EN</span>
          </li>
          <li className="nav-item">
            <span className="nav-link" onClick={this.lang('fr')}>FR</span>
          </li> */}
          {/* <li className="nav-item ml-4">
            <span>
              <Badge color='blue' count={5}>
                <button className="btn btn-link" onClick={this.handleClick}> <ShoppingCartOutlined width="1em" height="1em" /></button>
              </Badge>
            </span>
          </li> */}
          <li className="nav-item ml-4">
            {btn_logout}
          </li>
        </ul>
      </nav>
    );
  }
}


export default withTranslation()(Header);
