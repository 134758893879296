import React, { useEffect, useState } from 'react';
import { withTranslation  } from 'react-i18next';
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Button, Select, Form, Input, Card, Typography } from "antd";
import BASE_URL from "../../../config.js";
import SweetAlert from 'sweetalert2-react';
import { Utils } from '../../../services/Utils.js';
const { Title } = Typography;

const EditCategory = (props) => {
    const params = useParams();
    const [formCategory] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [notif, setNotif] = useState("");
    
    const [category, setCategory] = useState([]);
    const getCategory = () => {
      const Authorization = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Authorization,
      };
      axios
        .get(`${BASE_URL}/api/v1/category/list?parent_id=`, { headers })
        .then((res) => {
          const categoryData = res.data?.data;
          setCategory(categoryData);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(() => {
        getCategory();
        getCategoryId(params.id);
    }, []);

    const getCategoryId = (id) => {
        const Authorization = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Authorization,
        };
        axios
          .get(`${BASE_URL}/api/v1/category/show/${id}`, { headers })
          .then(async (res) => {
            const category = res.data?.data;
            let parent = null; 
            if(category.parent_id!=null){
                parent = parseInt(category.parent_id)
            }
            formCategory.setFieldsValue({
                label: category.label,
                description: category.description,
                parent_id: parent
            });
    
            
          })
          .catch((error) => {
            console.log(error);
          });
    };

    const saveCategory = async (value) => {
        setIsLoading(true);
        const parent_id = null;
        const { label, description } = value;
        // const { label, description, parent_id } = value;
        const Authorization = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Authorization,
        };
        try {
            const response = await axios.post(`${BASE_URL}/api/v1/category/edit/${params.id}`, { label, description, parent_id }, { headers });

            setIsLoading(false);

            if(response.data.success){
                let type = 'success'
                let mess = response.data?.message;

                Utils.notif(type, mess);
            } 

        } catch (error) {
            setIsLoading(false);
            console.log(error);    
        } 
    };
    return (
        <>
            <Card title={<Title level={3}>{props.t('edit_categorie')}</Title>}>
            <div className="row">
            <div className="col-md-12">
            <Form
                form={formCategory}
                name="edit-category"
                labelCol={{
                span: 8,
                }}
                wrapperCol={{
                span: 16,
                }}
                style={{
                maxWidth: 600,
                }}
                onFinish={saveCategory}
                onFinishFailed={null}
                autoComplete="off"
            >
                <Form.Item
                label={props.t("label")}
                name="label"
                rules={[
                    {
                    required: true,
                    message: "Please input label!",
                    },
                ]}
                >
                <Input />
                </Form.Item>

                <Form.Item name="description" label={props.t("description")}>
                    <Input.TextArea />
                </Form.Item>
                
                {/* <Form.Item
                label={props.t("parent_id")}
                name="parent_id"
                rules={[
                    {
                    required: true,
                    message: "Please input role!",
                    },
                ]}
                >
                <Select
                    allowClear
                    placeholder={props.t('parent_id')}
                    showSearch
                    optionFilterProp="children"
                >
                    {
                        category && category.map((item) => (
                            <Select.Option
                                key={`key-${item.id}`}
                                value={item.id}
                            >
                                {item.label}
                            </Select.Option>
                        ))
                    }
    
                </Select>
                </Form.Item> */}
                <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    className="mr-3"
                >
                    {props.t("btn_save")}
                </Button>
                <Link
                    to='/dashboard/category'
                >
                    {props.t('retour_liste')}
                </Link>
                </Form.Item>
            </Form>
            </div>
        </div>
        </Card>
        <SweetAlert
            show={show}
            type="success"
            text={notif}
            onConfirm={() => setShow(false)}
        />
        </>
    );
}

export default withTranslation()(EditCategory)