import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Button, Card, Input, Table, Form, Modal, Typography, Select, Checkbox , Switch , Collapse } from "antd";
import BASE_URL from "../../../config.js";
import { RollbackOutlined, EyeOutlined, LoadingOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import DetailCommande from "../../layouts/detail_commande.js";
import '../../../assets/css/commande.scss';
import { Utils } from "../../../services/Utils.js";
const { Title } = Typography;
const { confirm } = Modal;

const Commande = (props) => {
  const [commande, setCommande] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [idClicked, setIdClicked] = useState(null);
  const [modal2Open, setModal2Open] = useState(false);
  const [statusR, setStatusR] = useState(10);
  const [modeR, setModeR] = useState("all");
  const [role, setRole] = useState(localStorage.getItem("label") || null);
  const [status, setStatus] = useState([]);
  const [isRestoreStock, setIsRestoreStock] = useState(false);

  const detailCommande = (record) =>{
    setIdClicked(record.id); 
    setModal2Open(true);
  }

  const changeMode = (value) =>{
    setModeR(value);
    getCommande({type:value})
  }

  const changeStatus = (value) =>{
    if(value=="all"){
      setStatusR(null);
      getCommande()
    }else{
      setStatusR(value);
      getCommande({status:value})
    }
  }

  const changeDeadline = (value) =>{
    console.log(value);
    if(value){
      getCommande({not_payed_in_deadline: 1});
      setIsRestoreStock(true);
    }else{
      getCommande({not_payed_in_deadline: 0});
      setIsRestoreStock(false);
    }
  }

  const showConfirm = (value) => {
    confirm({
      title: "Do you want to restore the stocks ?",
      content: "",
      async onOk() {
        try {
          const Authorization = localStorage.getItem("token");
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Authorization,
          };
          axios
            .post(
              `${BASE_URL}/api/v1/command/restore/${value}`,
              {},
              { headers }
            )
            .then((response) => {
              if (response.data.success) {
                let type = "success";
                let mess = "Stocks restored successfully.";
                Utils.notif(type, mess);
                getCommande({not_payed_in_deadline: 1});

              }
            })
            .catch((error) => {
              if (error.response.data.errors.email != null) {
                let type = "error";
                let mess = error.response.data.errors.email[0];
                Utils.notif(type, mess);
              } else {
                let type = "error";
                Utils.notif(type, "Error");
              }
            });
          return null;
        } catch (e) {
          return console.log("Oops errors!");
        }
      },
      onCancel() {},
    });
  };

  const editDraft = (data_draft) => {
    confirm({
      title: "Do you want to edit this on Shopping Cart ?",
      content: "",
      async onOk() {
        try {
          if("currentOrder" in localStorage){
            localStorage.removeItem('currentOrder');
          }
          localStorage.setItem('currentOrder', data_draft?.data_json);
          setTimeout(() => {
            props.history.push("/dashboard/items");
          }, 1000);
        } catch (e) {
          return console.log("Oops errors!");
        }
      },
      onCancel() {},
    });
  };

  const getCommande = (filtre={}) => {
    setIsLoadingData(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    let fil = "";
    if(filtre?.status){
      if(modeR!=null){
        fil = `?status_id=${filtre?.status}&type_payment=${modeR}`;
      }
    }
    if(filtre?.type){
      if(statusR!=null){
        fil = `?status_id=${statusR}&type_payment=${filtre?.type}`;
      }else{
        fil = `?type_payment=${filtre?.type}`;
      }
    }
    if(filtre?.not_payed_in_deadline){
      fil = `?not_payed_in_deadline=${filtre?.not_payed_in_deadline}`;
    }

    axios
      .get(`${BASE_URL}/api/v1/command/list${fil}`
    ,{ headers })
      .then((res) => {
        const commandeData = res.data?.data;
        console.log("comma=>",commandeData)
        setCommande(commandeData);
        // setCommande(mook);
        setIsLoadingData(false);
      })
      .catch((error) => {
        setIsLoadingData(false);
        console.log(error);
      });
  };

  const columnsD = [
    {
      title: 'Product name',
      dataIndex: 'catalogue_name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Quantity',
      align:"right",
      dataIndex: 'quantity'
    },
    {
      title: 'Price',
      dataIndex: 'prix_ligne',
      align:"right",
      render: (text) => <p style={{marginBottom:'0'}}>${text}</p>,
    },
  ];
  
  const dataD = [
    {
      key: '1',
      name: 'TEST BE',
      quantity: 10,
      price: 2000,
    },
    {
      key: '2',
      name: 'TEE-SHORT',
      quantity: 1,
      price: 4000,
    },
    {
      key: '3',
      name: 'TEE-SHORT BE',
      quantity: 500,
      price: 4000,
    },
  ];

  const getTotal = (allProduct) =>{
    var total = 0;
    allProduct.map((item)=>{
      total+=parseFloat(item.prix_ligne);
    })
    return total;
  }
  const columns = [
    {
      title: "N° order",
      dataIndex: [],
      align:"left",
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (text,record) => {
        return (
          <>
            <p style={{marginBottom:'-5px',fontWeight:'bold'}} className="p-0">
              {
                (record?.status?.id == 16) ? (
                  <>DRAFT{record?.command_daft?.id}</>
                ) : (
                  <>CM{record.number}</>
                )
              }
              
            </p>
            {/* <br /> */}
            <span style={{marginBottom:'0',fontSize:'12px',color:'#8c8c8c'}}>
              {record.date_commande}
            </span>

            { (record.id === idClicked && role !== "4") &&(
                <Modal
                  title="Order detail"
                  centered
                  open={modal2Open}
                  onOk={() => setModal2Open(false)}
                  onCancel={() => setModal2Open(false)}
                  footer={[]}
                  width={1200}
                >
                  <DetailCommande order={record} role={role} orderId={record?.status?.id} statusLabel={record?.status?.label} show={props.show}/>
                </Modal>
              )
            }
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: [],
      align:"center",
      render: (text,record) => {
        return (
          <>
            <p className="text-uppercase font-weight-bold mb-0">
              {record?.status?.label}
            </p>
            <div className="dot">
              {
                record.command_lines.filter(line => line.stocks.length !== 0).map((item, i)=>{
                  if(i<8){
                    return (
                      <div title={item.status.label} className="dot-item" style={
                        {
                          backgroundColor:item.status.code
                        }
                      }>
                      
                      </div>
                    )
                  }
                })
              }
            </div>  
          </>
        );
      },
    },
    {
      title: "Sale's rep.",
      dataIndex: "client.name",
      align:"center",
      render: (text,record) => {
        return (
          <span>
            {record.command_owner.name}
          </span>
        );
      },
    },
    {
      title: "Contact",
      dataIndex: [],
      align:"center",
      render: (text,record) => {
        return (
          <span>
            {record.command_owner.email}
          </span>
        );
      },
    },
    {
      title: "Balance payment",
      dataIndex: [],
      align:"right",
      className: `tdColumn ${ role === "4" && "d-none"}`,
      render: (text,record) => {
        return (
          <span>
            {Utils.formatMoney(parseFloat(record.prix_remise)-parseFloat(record.acompte))}
          </span>
        );
      },
    },
    {
      title: props.t("actions"),
      dataIndex: "id",
      align:"center",
      render: (text, record) => {
        return (
          <>
          {
            (record?.status?.id == 16 && role === "3") ? (
              <>
                {/* <Link
                  to={{
                    pathname: `/dashboard/commande/show/${text}`,
                    title: "Edit",
                    state: { modal: true },
                  }}
                  > 
                  <ShoppingCartOutlined />
                </Link> */}
                <Button
                  onClick={() => editDraft(record?.command_daft)}
                  shape="circle" 
                  icon={<ShoppingCartOutlined />}
                  type="link"
                  title="Edit cart"
                />
              </>
            ) : (
              <>
                <Link
                  to={{
                    pathname: `/dashboard/commande/show/${text}`,
                    state: { modal: true },
                  }}
                  > 
                  <EyeOutlined />
                </Link>
                {
                  ((role === "1" || role === "2") && isRestoreStock ) && (
                    <Button
                      onClick={() => showConfirm(text)}
                      shape="circle" 
                      icon={<RollbackOutlined />}
                      type="link"
                      title="Restore stock"
                      className="ml-3"
                    />
                  )
                }
              </>
            )
          }
          </>
        );
      },
    },
  ];

  const getStatus = () => {
    const Authorization = localStorage.getItem("token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Authorization,
    };
    try {
        axios.get(`${BASE_URL}/api/v1/status-list`, { headers })
        .then((res)=>{
        if(res.data.success){
            const statu = res.data?.data;
            var temp = [];
            statu.map((item)=>{
            temp.push({value:item.id,label:item.label})
            })
            setStatus([...temp]);
        }
        })
        .catch(err => {
        console.log(err)
        })
    } catch (error) {
        console.log(error)
    }
  };
  
  const convertCommandLinesToCart = (commandLines) => {
    const cart = [];
  
    for (const commandLine of commandLines) {
      const product = {
        id: commandLine.catalogue_id,
        reference: commandLine.catalogue_reference,
        name: commandLine.catalogue_name,
        // ... other product properties can be added here from commandLine data
        quantity: commandLine.quantity,
        colors: [],
        variants: [],
      };
  
      const stock = commandLine.stocks[0]; // Assuming there's only one stock per command line
      const variant = stock.childs[0]; // Assuming there's only one child per stock (variant)
  
      product.colors.push({
        id: stock.color, // Assuming color ID can be derived from stock.color
        label: stock.color,
        // ... other color properties can be added here from stock data
      });
  
      product.variants.push({
        id: variant.variants[0].id, // Assuming there's only one variant per child
        key: "SIZE", // Assuming size is the key for variants
        value: variant.variants[0].value,
        reference_ys: variant.variants[0].reference_ys,
        stocks: {
          in_stock: [
            {
              id: variant.variants[0].id,
              quantity: variant.quantity,
              // ... other stock properties can be added here from variant data
            },
          ],
          future_one: [],
          future_two: [],
        },
      });
  
      cart.push({
        product,
        commande: {
          // ... commande properties can be added here, if needed
        },
        in: "5", // Assuming "in" property is always 5
        max: stock.real_quantity, // Assuming max quantity comes from stock.real_quantity
        variant: product.variants[0],
      });
    }
  
    return cart;
  }

  useEffect(() => {
    getCommande();
    getStatus();
  }, []); 

  return (
    <Card 
      className="commande-list"
        title={<Title level={3}>{props.t("list_commandes")}</Title>}
    >
      <Card style={{top:'46px',position:'sticky',zIndex:'99',marginBottom:'1px'}}>
        <div  className="row">
            <div className="col-md-12">
                <Form layout="inline"  style={{justifyContent:'center'}} className="row">
                    <div className="col-md-4">
                      <Form.Item label="Order status:" name='orderStatus'>
                          <Select
                              style={{ minWidth: '200px',marginBottom:'10px' }}
                              showArrow
                              placeholder="Order status"
                              showSearch
                              optionFilterProp="children"
                              onChange={changeStatus}
                              defaultValue="all"
                          >
                            <Select.Option key={`key-0`} value="all">
                            All status
                            </Select.Option>
                              {status &&
                              status.map((item) => (
                                  <Select.Option key={`key-${item.value}`} value={item.value}>
                                  {item.label}
                                  </Select.Option>
                              ))}
                          </Select> 
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item label="Mode of payement:" name='payementMode'>
                          <Select
                              style={{ minWidth: '175px',marginBottom:'10px' }}
                              showArrow
                              placeholder="Mode of payement"
                              showSearch
                              optionFilterProp="children"
                              onChange={changeMode}
                              defaultValue="all"
                          >
                            <Select.Option key={`key-1`} value="all">
                            All mode
                            </Select.Option>
                            <Select.Option key={`key-2`} value="cb">
                            Pay with cart
                            </Select.Option>
                            <Select.Option key={`key-3`} value="vb">
                            Pay with bank
                            </Select.Option>
                          </Select> 
                      </Form.Item>
                    </div>
                    <div className="col-md-4 d-flex align-items-center">
                      <Form.Item name="in_stock">
                          <Switch defaultChecked={false} onChange={(value) => {
                                  changeDeadline(value);
                              }
                          } className="rounded" />
                      </Form.Item>
                      <span>Not payed in deadline</span>
                    </div>
                </Form>
            </div>
        </div>
        
        
        
    </Card>
      <div style={{ overflowX: 'auto' }}>
        <Table
            columns={columns}
            dataSource={commande}
            bordered
            rowKey="rowAction"
            pagination={{ hideOnSinglePage: true, pageSize: 15 }}
            loading={{indicator: <LoadingOutlined/>, spinning: isLoadingData}}
        />
      </div>
    </Card>
  );
};

export default withTranslation()(withRouter(Commande));
