const initialState = {
    quantities: {}
};

export const quantitiesReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case 'SET_QUANTITIES':
            return {
                quantities: action.payload
            }
        default:
            return state
    }
}