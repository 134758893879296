import React, { Component } from 'react';
import LeftMenu from './leftMenu'
import RightMenu from './rightMenu'
import { Drawer, Button } from 'antd';
class Navbar extends Component {
  state = {
    current: 'mail',
    visible: false
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
onClose = () => {
    this.setState({
      visible: false,
    });
  };
render() {
    return (
        <nav className="menuBar" style={{top:'0',position:'sticky',zIndex:'99'}}>
          <div className="menuCon">
            <div className="leftMenu">
              <LeftMenu />
            </div>
            {/* <div className="rightMenu">
                <RightMenu />
            </div>
            <Button className="barsMenu" type="primary" onClick={this.showDrawer}>
              <span className="barsBtn"></span>
            </Button> */}
            <Drawer
              title="Basic Drawer"
              placement="right"
              closable={false}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <LeftMenu />
              <RightMenu />
            </Drawer>
</div>
        </nav>
    );
  }
}
export default Navbar;