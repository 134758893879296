import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, InputNumber, Popconfirm, Table } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import './index.module.scss';
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <InputNumber min={1} ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const OrderTable = (props) => {
  const [dataSource, setDataSource] = useState(props.dataSource);
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    const findOrderToDel = dataSource.find((item) => item.key === key);
    setDataSource(newData);

    const currentOrder = localStorage.getItem('currentOrder');
    if(currentOrder) {
      const updateOrder = JSON.parse(currentOrder);
      updateOrder.cart = updateOrder.cart.map(oc => {
        if(oc.product.id === findOrderToDel.productId) {
          oc.commande = oc.commande.filter(occom => occom.variant.id !== findOrderToDel.variantId)
        }
        return oc
      }).filter( oc => oc !== null);

      localStorage.setItem('currentOrder', JSON.stringify(updateOrder));
      
    }
  };
  const defaultColumns = [
    {
      title: 'Product name',
      dataIndex: 'productName',
    },
    {
      title: 'Color',
      dataIndex: 'color',
    },
    {
      title: 'Size',
      dataIndex: 'size',
    },
    {
      title: 'Quantity ordered',
      dataIndex: 'quantity',
      editable: true,
    },
    {
      title: 'Stock YS',
      dataIndex: 'max_stock'
    },
    {
      title: 'Unit price',
      dataIndex: 'unitPrice',
      render: (val, record) => {
        return val ? parseFloat(val).toFixed(2) : '';
      }
    },
    {
      title: 'Total price',
      dataIndex: 'totalPrice',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (val, record) =>
        (dataSource.length >= 1 && val != 999) ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <Button type="danger" shape="circle" icon={<CloseCircleOutlined />} />
          </Popconfirm>
        ) : null,
    },
  ];

  const handleSave = (row) => {
    if(row.key !== 'total'){
      const newData = [...dataSource];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...{...row, totalPrice: (row.unitPrice * Number(row.quantity)).toFixed(2)},
      });
      setDataSource(newData);

    }

    const currentOrder = localStorage.getItem('currentOrder');
    if(currentOrder) {
      const updateOrder = JSON.parse(currentOrder);
      updateOrder.cart = updateOrder.cart.map(oc => {
        if(oc.product.id === row.productId) {
          oc.commande = oc.commande.map(occom => {
            if(occom.variant.id === row.variantId) {
              occom.in = row.quantity + ''
            }
            return occom;
          })
        }
        return oc
      });
      localStorage.setItem('currentOrder', JSON.stringify(updateOrder));
    }
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  // Calculate total quantity ordered and sum total price
  const totalQuantity = dataSource.reduce((total, item) => total + parseInt(item.quantity), 0);
  const totalPrice = dataSource.reduce((total, item) => total + parseFloat(item.totalPrice), 0).toFixed(2);

  // Add a new row for displaying total quantity and total price
  const totalRow = {
    key: 'total',
    productName: 'Total',
    color: '',
    size: '',
    disponibility: '',
    quantity: totalQuantity,
    max: '',
    unitPrice: '',
    totalPrice: totalPrice,
    operation: 999
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div>
      <Table
        components={components}
        rowClassName={(record) => (record.key === 'total' ? 'no-editable-row' : 'editable-row')}
        bordered
        dataSource={[...dataSource, totalRow]}
        columns={columns}
      />
    </div>
  );
};
export default OrderTable;