import React, { Component } from 'react';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../assets/css/login.css';
import BASE_URL from '../../config';
import { Utils } from '../../services/Utils.js';
import { withTranslation  } from 'react-i18next';
class ForgotPassword extends Component {
    state = {
        email: "",
        msg_ko: "",
        isLoading: false
    };
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        if (this.props.isAuth === true) {
            this.props.history.push("/dashboard");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isAuth === true) {
            this.props.history.push("/dashboard");
        }
    }
    handleSubmit = (values) => {
        this.setState({
            isLoading: true
        });
        const { email } = values;
        axios.post(`${BASE_URL}/api/v1/forgotten-password`, { email })
            .then(response => {
                this.setState({
                    isLoading: false
                })
                if (response.data.success) {
                    this.setState({ show: true, msg_ko: "Veulliez vérifer votre Email ! " });
                    let type = 'success'
                    let mess = response.data.message;
                    Utils.notif(type, mess);
                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                })
                if (error.response.data.message === "We can't find a user with that email address.") {
                    let type = 'error'
                    let mess = error.response.data.message;
                    Utils.notif(type, mess);
                }
            });
    };
    render() {
        return (
            <section className="main">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5 col-10">
                            <h1 className="text-center my-3">{this.props.t('forgot_password')}</h1>
                            <Form
                                name="sendMailPass"
                                labelCol={{
                                span: 8,
                                }}
                                wrapperCol={{
                                span: 16,
                                }}
                                style={{
                                maxWidth: 600,
                                }}
                                initialValues={{
                                remember: true,
                                }}
                                onFinish={this.handleSubmit}
                                onFinishFailed={null}
                                autoComplete="off"
                            >
                                <Form.Item
                                label={this.props.t('email')}
                                name="email"
                                rules={[
                                    {
                                      type: 'email',
                                      message: 'The input is not valid Email',
                                    },
                                    {
                                      required: true,
                                      message: 'Please input your Email!',
                                    },
                                ]}
                                >
                                <Input/>
                                </Form.Item>
                                <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                                >
                                <Button type="primary" htmlType="submit" loading={this.state.isLoading}>
                                    {this.props.t('submit')}
                                </Button>
                                </Form.Item>
                            </Form>
                            <hr className="w-100" />
                            <div className="d-flex justify-content-around py-3">
                                <Link to="/login">{this.props.t('btn_login')}</Link>
                                {/* <span> | </span>
                                <Link to="/register">{this.props.t('signup')}</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation()(ForgotPassword);
