import { withTranslation } from "react-i18next";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Image,
  Input,
  Select,
  Table,
  Typography,
  Modal,
  Badge,
  Checkbox,
  InputNumber,
} from "antd";
import "../../../assets/css/editCommande.scss";
import { DeleteOutlined, PrinterOutlined } from "@ant-design/icons";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";
import BASE_URL from "../../../config";
import { Utils } from "../../../services/Utils";
import PaymentForm from "../payement";
const { Title,Text } = Typography;
const { confirm } = Modal;

const EditCommande = (props) => {
  let componentRef = useRef();
  const params = useParams();
  const [formEditOrder] = Form.useForm();
  const [formProof] = Form.useForm();
  const [catalog_file, setCatalog_file] = useState(null);
  const [typeprod, setTypeprod] = useState(null);
  const [orderId, setOrderId] = useState(0);
  const [allCheck, setAllCheck] = useState(false);
  const [order, setOrder] = useState();
  const [status, setStatus] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [role, setRole] = useState(localStorage.getItem("label") || null);
  const [modal2Open, setModal2Open] = useState(false);
  const [isExpress, setIsExpress] = useState(false);

  useEffect(() => {
    getItem(params.id);
    getStatus();

    formProof?.setFieldsValue({
      wire_transfer_file: null,
    });
  }, [formProof]);
  const [footer, setFooter] = useState({
    totalPrice: 0,
    totalPriceFabrice: 0,
    remise: 0,
    totalPay: 0,
    acompte: 0,
    leftPay: 0,
    quantity: 0,
    quantityFabrice: 0,
  });
  const getTotal = (allProduct) => {
    var total = 0;
    allProduct.map((item) => {
      total += parseFloat(item.prix_ligne);
    });
    return total;
  };
  const columnsD = [
    {
      title: "Product name",
      dataIndex: "catalogue_name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Quantity",
      align: "right",
      dataIndex: "quantity",
    },
  ];
  const columnsDD = [
    {
      title: "Product name",
      dataIndex: "catalogue_name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Quantity",
      align: "right",
      dataIndex: "quantity",
    },
    {
      title: "Additionnal Quantity (Ys)",
      dataIndex: "id",
      align: "right",
      render: (text) => <InputNumber defaultValue={0} />,
    },
  ];
  const changeYSQ = (i, j, k, event, key_ligne) => {
    const temp = { ...order };
    temp.command_lines?.map((item, a) => {
      item.stocks.map((itemT, t) => {
        let it = false;
        if (i == t) {
          it = true;
        }
        itemT.childs.map((itemV, v) => {
          let jv = false;

          if (j == v) {
            jv = true;
          }
          itemV?.variants.map((itemVV, vv) => {
            let kvv = false;
            if (k == vv) {
              kvv = true;
            }
            if (it == true && jv == true && kvv == true) {
              const tempo = {
                ...temp?.command_lines[key_ligne].stocks[t].childs[v].variants[vv],
                additional_quantity: event,
              };
              // temp?.command_lines[key_ligne].stocks[t].childs[v].variants.splice(vv,1);
              temp?.command_lines[key_ligne].stocks[t].childs[v].variants.splice(
                vv,
                1,
                tempo
              );
            }
          });
        });
      });
    });
    setOrder({ ...temp });
    console.log(temp);
  };

  // const changeAdditionnal = (i, j, k, event) => {
  //   const temp = { ...order };
  // }
  const validProduction = () => {
    setIsLoading(true);
    const obj = [];
    order.command_lines.map((item) => {
      item.stocks.map((itemS) => {
        itemS.childs.map((itemC) => {
          itemC.variants.map((itemV) => {
            const temp = {
              id: itemV.id,
              variant_id: itemV.variant_id,
              additional_quantity: itemV.additional_quantity
                ? itemV.additional_quantity
                : 0,
              type: itemV.type,
            };
            obj.push(temp);
          });
        });
      });
    });
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .post(
        `${BASE_URL}/api/v1/command/in-production/${order.id}`,
        { stocks: obj },
        { headers }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          let type = "success";
          Utils.notif(type, "Success");
        }
        getItem(params.id);
        setModal2Open(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response.data.errors.email[0]);
        if (error.response.data.errors.email != null) {
          let type = "error";
          let mess = error.response.data.errors.email[0];
          Utils.notif(type, mess);
        }
      });
  };
  const [isLoading, setIsLoading] = useState(false);
  const changeProduction = (type) => {
    // console.log(order) etozaooo
    setTypeprod(type);
    setModal2Open(true);
    // alert(commande)
  };
  const getFooter = (commande) => {
    console.log("commande", commande);
    const res = {
      totalPrice: 0,
      totalPriceFabrice: 0,
      remise: 0,
      totalPay: 0,
      acompte: 0,
      leftPay: 0,
      quantity: 0,
      quantityFabrice: 0,
    };
    commande.command_lines.map((item, key_ligne) => {
      res.quantity += item.quantity;
      item.stocks.map((itemS) => {
        itemS.childs.map((itemC) => {
          itemC.variants.map((itemV) => {
            res.totalPrice = parseFloat(res.totalPrice) + parseFloat(itemV?.price);
            res.totalPriceFabrice = parseFloat(res.totalPriceFabrice) + (parseFloat(itemV?.quantity + itemV?.additional_quantity) * itemV?.unit_price_fabriquant);
            res.quantityFabrice = res.quantityFabrice + (itemV.quantity + itemV?.additional_quantity);
          });
        });
      });
    });

    res.remise = (res.totalPrice * parseFloat(commande.remise)) / 100;
    res.totalPay = parseFloat(res.totalPrice) - parseFloat(res.remise);
    res.acompte =
      (parseFloat(res.totalPay) * parseFloat(commande.acompte_percent)) / 100;
    res.leftPay = res.totalPay - res.acompte;
    setFooter({ ...res });
  };

  useEffect(() => {
    if (orderId == 10) {
      const interval = setInterval(() => {
        const Authorization = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Authorization,
        };
        axios
          .post(
            `${BASE_URL}/api/v1/payment/check-payment`,
            { command_id: params.id },
            { headers }
          )
          .then((response) => {
            if (response.data.data.success) {
              setOrderId(parseInt(response.data.data.status_id));
              formEditOrder.setFieldsValue({
                globalStatus: parseInt(response.data.data.status_id),
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
        return null;
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [orderId]);

  const setStatusE = (id) => {
    setOrderId(id);
    formEditOrder.setFieldsValue({
      globalStatus: id,
    });
  };
  const addDate = (i, j, k, event, key_ligne) => {
    const temp = { ...order };
    temp.command_lines?.map((item, a) => {
      item.stocks.map((itemT, t) => {
        let it = false;
        if (i == t) {
          it = true;
        }
        itemT.childs.map((itemV, v) => {
          let jv = false;

          if (j == v) {
            jv = true;
          }
          itemV?.variants.map((itemVV, vv) => {
            let kvv = false;
            if (k == vv) {
              kvv = true;
            }
            if (it == true && jv == true && kvv == true) {
              const tempo = {
                ...temp?.command_lines[key_ligne].stocks[t].childs[v].variants[vv],
                date_disponibility: event.target.value,
              };
              // temp?.command_lines[key_ligne].stocks[t].childs[v].variants.splice(vv,1);
              temp?.command_lines[key_ligne].stocks[t].childs[v].variants.splice(
                vv,
                1,
                tempo
              );
            }
          });
        });
      });
    });
    setOrder({ ...temp });
  };
  const onChangeDImage = (event) =>{
    var photo = Object.assign(event.target.files[0], {
      preview: URL.createObjectURL(event.target.files[0]),
    });
    setCatalog_file(photo);
  }

  const sendImageProof = (values) =>{
    console.log(values);
    var image = [];
    image.push(catalog_file);
    const data = new FormData();
    data.append("command_id", order?.id);
    if(!catalog_file?.path){
      data.append("wire_transfer_file", catalog_file);
    }
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
    .post(`${BASE_URL}/api/v1/payment/wire-transfer`, data, { headers })
      .then((response) => {
        if (response.data.success) {
          let type = "success";
          Utils.notif(type, "Success");
        }
        setCatalog_file(null);
      })
      .catch((error) => {
        if (error.response.data.errors != null) {
          let type = "error";
          let mess = error.response.data.errors;
          Utils.notif(type, mess);
        }
      });
  }
  const addPrice = (i, j, k, event, key_ligne) => {
    const temp = { ...order };
    temp.command_lines?.map((item, a) => {
      item.stocks.map((itemT, t) => {
        let it = false;
        if (i == t) {
          it = true;
        }
        itemT.childs.map((itemV, v) => {
          let jv = false;

          if (j == v) {
            jv = true;
          }
          itemV?.variants.map((itemVV, vv) => {
            let kvv = false;
            if (k == vv) {
              kvv = true;
            }
            if (it == true && jv == true && kvv == true) {
              const tempo = {
                ...temp?.command_lines[key_ligne].stocks[t].childs[v].variants[vv],
                extracost: event.target.value,
              };
              // temp?.command_lines[key_ligne].stocks[t].childs[v].variants.splice(vv,1);
              temp?.command_lines[key_ligne].stocks[t].childs[v].variants.splice(
                vv,
                1,
                tempo
              );
            }
          });
        });
      });
    });
    setOrder({ ...temp });
  };

  const checkAll = (event) => {
    const temp = { ...order };
    temp.command_lines?.map((item, a) => {
      item.stocks.map((itemT, t) => {
        itemT.childs.map((itemV, v) => {
          itemV?.variants.map((itemVV, vv) => {
            const tempo = {
              ...temp?.command_lines[a].stocks[t].childs[v].variants[vv],
              ready: event,
            };
            temp?.command_lines[a].stocks[t].childs[v].variants.splice(
              vv,
              1,
              tempo
            );
          });
        });
      });
    });
    setAllCheck(event);
    setOrder({ ...temp });
  };

  const changeAvailable = (i, j, k, event, key_ligne) => {
    console.log(i, j, k, event.target.value, key_ligne)
    const temp = { ...order };
    temp.command_lines?.map((item, a) => {
      item.stocks.map((itemT, t) => {
        let it = false;
        if (i == t) {
          it = true;
        }
        itemT.childs.map((itemV, v) => {
          let jv = false;

          if (j == v) {
            jv = true;
          }
          itemV?.variants.map((itemVV, vv) => {
            let kvv = false;
            if (k == vv) {
              kvv = true;
            }
            if (it == true && jv == true && kvv == true) {
              const tempo = {
                ...temp?.command_lines[key_ligne].stocks[t].childs[v].variants[vv],
                available: event.target.value,
              };
              // temp?.command_lines[a].stocks[t].childs[v].variants.splice(vv,1);
              temp?.command_lines[key_ligne].stocks[t].childs[v].variants.splice(
                vv,
                1,
                tempo
              );
            }
          });
        });
      });
    });
    setOrder({ ...temp });
    console.log(temp)
  };

  const changeChiping = (i, j, k, event, type, key_ligne) => {
    const temp = { ...order };
    temp.command_lines?.map((item, a) => {
      item.stocks.map((itemT, t) => {
        let it = false;
        if (i == t) {
          it = true;
        }
        itemT.childs.map((itemV, v) => {
          let jv = false;

          if (j == v) {
            jv = true;
          }
          itemV?.variants.map((itemVV, vv) => {
            let kvv = false;
            if (k == vv) {
              kvv = true;
            }
            if (it == true && jv == true && kvv == true) {
              let tempo = {
                ...temp?.command_lines[key_ligne].stocks[t].childs[v].variants[vv]
              };
              
              if (type === 0) {
                tempo.input_chipping_from_ys = event.target.value;
              } else if (type === 1) {
                tempo.input_chipping_from_factory = event.target.value;
              }

              temp?.command_lines[key_ligne].stocks[t].childs[v].variants.splice(
                vv,
                1,
                tempo
              );
            }
          });
        });
      });
    });
    setOrder({ ...temp });
  };

  const recheck = (i, j, disp, key_ligne) => {
    const temp = { ...order };
    temp.command_lines?.map((item, a) => {
      item.stocks.map((itemT, t) => {
        let it = false;
        if (i == t) {
          it = true;
        }
        itemT.childs.map((itemV, v) => {
          let jv = false;

          if (j == v) {
            jv = true;
          }
          itemV?.variants.map((itemVV, vv) => {
            let kvv = false;
            if (
              itemVV?.reference_ys == disp?.ref &&
              itemVV?.is_express == "1" &&
              disp?.is_express == "1" &&
              ((itemVV?.is_accelered == "0" && disp?.is_accelered == "1") ||
                (itemVV?.is_accelered == "1" && disp?.is_accelered == "0"))
            ) {
              kvv = true;
            }

            if (it == true && jv == true && kvv == true) {
              // console.log("vv",itemVV)
              // console.log("disp",disp)
              // if(disp.is_express!="1"){
              const tempo = {
                ...temp?.command_lines[key_ligne].stocks[t].childs[v].variants[vv],
                ready: false,
              };
              temp?.command_lines[key_ligne].stocks[t].childs[v].variants.splice(
                vv,
                1,
                tempo
              );
              // }else{

              // }
            }
          });
        });
      });
    });
    setOrder({ ...temp });
  };

  const checkValider = (i, j, k, event, disp, key_ligne) => {
    // console.log("DISP::: ", disp);
    const temp = { ...order };
    temp.command_lines?.map((item, a) => {
      item.stocks.map((itemT, t) => {
        let it = false;
        if (i == t) {
          it = true;
        }
        itemT.childs.map((itemV, v) => {
          let jv = false;

          if (j == v) {
            jv = true;
          }
          itemV?.variants.map((itemVV, vv) => {
            let kvv = false;
            if (disp.id_variante == itemVV.id) { //etozao
              kvv = true;
            }
            if (it == true && jv == true && kvv == true) {
              if (disp.is_express != "1") {
                const tempo = {
                  ...temp?.command_lines[key_ligne].stocks[t].childs[v].variants[vv],
                  ready: event,
                };
                temp?.command_lines[key_ligne].stocks[t].childs[v].variants.splice(
                  vv,
                  1,
                  tempo
                );
              } else {
                const tempo = {
                  ...temp?.command_lines[key_ligne].stocks[t].childs[v].variants[vv],
                  ready: event,
                };
                temp?.command_lines[key_ligne].stocks[t].childs[v].variants.splice(
                  vv,
                  1,
                  tempo
                );
                recheck(i, j, disp, key_ligne);
                // console.log(disp.ready)
              }
            }
          });
        });
      });
    });
    setOrder({ ...temp });
  };

  const deleteLigne = (i, j, k, key_ligne) => {
    confirm({
      title: "Are you sure you want to delete this line?",
      content: "",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => {
        const temp = { ...order };
        temp.command_lines?.map((item, a) => {
          item.stocks.map((itemT, t) => {
            let it = false;
            if (i == t) {
              it = true;
            }
            itemT.childs.map((itemV, v) => {
              let jv = false;

              if (j == v) {
                jv = true;
              }
              itemV?.variants.map((itemVV, vv) => {
                let kvv = false;
                if (k == vv) {
                  kvv = true;
                }
                if (it == true && jv == true && kvv == true) {
                  temp?.command_lines[key_ligne].stocks[t].childs[v].variants.splice(
                    vv,
                    1
                  );
                }
              });
            });
          });
        });
        getFooter({ ...temp });
        setOrder({ ...temp });
      },
      onCancel: () => {},
    });
  };

  const getStatus = () => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    try {
      axios
        .get(`${BASE_URL}/api/v1/status-list`, { headers })
        .then((res) => {
          if (res.data.success) {
            const statu = res.data?.data;
            var temp = [];
            statu.map((item) => {
              temp.push({ value: item.id, label: item.label });
            });
            // let filtered;
            // if (role === 3) {
            //   filtered = [...temp].filter(
            //     (item) =>
            //       item.value !== 3 && item.value !== 5 && item.value !== 8
            //   );
            // } else {
            //   filtered = [...temp];
            // }
            setStatus(temp);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getItem = (id) => {
    setIsLoadingData(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    try {
      axios
        .get(`${BASE_URL}/api/v1/command/show/${id}`, { headers })
        .then((res) => {
          if (res.data.success) {
            const commande = res.data?.data.proposition_one;
            formEditOrder.setFieldsValue({
              globalStatus: parseInt(commande?.status.id),
            });
            commande?.command_lines.map((item) => {
              item.stocks.map((itemT, t) => {
                itemT.childs.map((itemV, v) => {
                  itemV?.variants.map((itemVV, vv) => {
                    if (itemVV.type != "in_stock") {
                      setIsExpress(true);
                    }
                  });
                });
              });
            });
            getFooter(commande);
            setOrder(commande);
            setOrderId(commande?.status.id);
            setIsLoadingData(false);
            setTitre(props.t("view_commande"));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const [titre, setTitre] = useState(null);
  const payVB = () => {
    confirm({
      title: "Pay by bank transfer",
      content: "You will receive the invoice for your purchase ....",
      async onOk() {
        try {
          const Authorization = localStorage.getItem("token");
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Authorization,
          };

          axios
            .post(
              `${BASE_URL}/api/v1/payment/by-bank`,
              { command_id: params.id },
              { headers }
            )
            .then((response) => {
              console.log(response);
              if (response.data.success) {
                let type = "success";
                let mess = response.data?.message;
                Utils.notif(type, mess);
                setOrderId(10);
                formEditOrder.setFieldsValue({
                  globalStatus: 10,
                });
              }
            })
            .catch((error) => {
              if (error.response.data.errors.email != null) {
                let type = "error";
                let mess = error.response.data.errors.email[0];
                Utils.notif(type, mess);
              } else {
                let type = "error";
                Utils.notif(type, "Error");
              }
            });
          return null;
        } catch (e) {
          return console.log("Oops errors!");
        }
      },
      onCancel() {},
    });
  };
  const changeCancel = () => {
    confirm({
      title: "Would you like to cancel the order?",
      content: "",
      async onOk() {
        try {
          const Authorization = localStorage.getItem("token");
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Authorization,
          };

          axios
            .post(
              `${BASE_URL}/api/v1/command/change-status/${params.id}`,
              { status_id: 7 },
              { headers }
            )
            .then((response) => {
              if (response.data.success) {
                let type = "success";
                let mess = response.data?.message;
                Utils.notif(type, mess);
                setOrderId(7);
                formEditOrder.setFieldsValue({
                  globalStatus: 7,
                });
              }
            })
            .catch((error) => {
              if (error.response.data.errors.email != null) {
                let type = "error";
                let mess = error.response.data.errors.email[0];
                Utils.notif(type, mess);
              } else {
                let type = "error";
                Utils.notif(type, "Error");
              }
            });
          return null;
        } catch (e) {
          return console.log("Oops errors!");
        }
      },
      onCancel() {},
    });
  };
  const showConfirm = (value) => {
    confirm({
      title: "Do you want to change the status of the command?",
      content: "",
      async onOk() {
        try {
          const Authorization = localStorage.getItem("token");
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Authorization,
          };

          axios
            .post(
              `${BASE_URL}/api/v1/command/change-status/${params.id}`,
              {
                id: order?.id,
                status_id: value,
                command_lines: order?.command_lines,
              },
              { headers }
            )
            .then((response) => {
              if (response.data.success) {
                let type = "success";
                let mess = response.data?.message;
                Utils.notif(type, mess);
                setOrderId(value);
                formEditOrder.setFieldsValue({
                  globalStatus: parseInt(value),
                });
                getItem(params.id);
              }
            })
            .catch((error) => {
              if (error.response.data.errors.email != null) {
                let type = "error";
                let mess = error.response.data.errors.email[0];
                Utils.notif(type, mess);
              } else {
                let type = "error";
                Utils.notif(type, "Error");
              }
            });
          return null;
        } catch (e) {
          return console.log("Oops errors!");
        }
      },
      onCancel() {},
    });
  };

  const showConfirmChipping = () => {
    confirm({
      title: "Do you want to submit Chipping ?",
      content: "",
      async onOk() {
        try {
          // let stocks = order?.command_lines[0].stocks;
          let stocks = [];
          // order?.command_lines.map((item, index) => {
          //   const stockTemp = item.stocks;
          //   stocks.push(stockTemp);
          // });
          for (let index = 0; index < order?.command_lines.length; index++) {
            const element = order?.command_lines[index];
            stocks.push(element.stocks);
          }
          const Authorization = localStorage.getItem("token");
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Authorization,
          };
          // console.log(stocks);
          // return;
          axios
            .post(
              `${BASE_URL}/api/v1/command/tracking-production/${params.id}`,
              { stocks }, //etozao
              { headers }
            )
            .then((response) => {
              if (response.data.success) {
                let type = "success";
                let mess = response.data?.message;
                Utils.notif(type, mess);
                getItem(params.id);
              } else {
                let type = "error";
                Utils.notif(type, "Error");
              }
            })
            .catch((error) => {
              let type = "error";
              Utils.notif(type, "Error");
            });
          return null;
        } catch (e) {
          return console.log("Oops errors!", e);
        }
      },
      onCancel() {},
    });
  };

  function formatDate() {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const validerOrder = () => {
    // console.log("==>",order?.command_lines);
    const send = order?.command_lines;
    // console.log("send",send);
    // console.log("{id:order?.id,status_id:6,command_lines:send}",{id:order?.id,status_id:6,command_lines:send});
    // return ;
    confirm({
      title: "Do you want to valid the command?",
      content: "",
      async onOk() {
        if (role === "3") {
          try {
            const Authorization = localStorage.getItem("token");
            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Authorization,
            };
            axios
              .post(
                `${BASE_URL}/api/v1/command/change-status/${params.id}`,
                { id: order?.id, status_id: 6, command_lines: send },
                { headers }
              )
              .then((response) => {
                if (response.data.success) {
                  let type = "success";
                  let mess = response.data?.message;
                  Utils.notif(type, mess);
                  setOrderId(6);
                  formEditOrder.setFieldsValue({
                    globalStatus: parseInt(6),
                  });
                  getItem(params.id);
                }
              })
              .catch((error) => {
                if (error.response.data.errors.email != null) {
                  let type = "error";
                  let mess = error.response.data.errors.email[0];
                  Utils.notif(type, mess);
                } else {
                  let type = "error";
                  Utils.notif(type, "Error");
                }
              });
            return null;
          } catch (e) {
            return console.log("Oops errors!");
          }
        } else {
          try {
            const Authorization = localStorage.getItem("token");
            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Authorization,
            };
            // console.log(send); return;
            axios
              .post(
                `${BASE_URL}/api/v1/command/change-status/${params.id}`,
                { id: order?.id, status_id: 9, command_lines: send },
                { headers }
              )
              .then((response) => {
                if (response.data.success) {
                  let type = "success";
                  let mess = response.data?.message;
                  Utils.notif(type, mess);
                  setOrderId(9);
                  formEditOrder.setFieldsValue({
                    globalStatus: parseInt(9),
                  });
                  getItem(params.id);
                }
              })
              .catch((error) => {
                if (error.response.data.errors.email != null) {
                  let type = "error";
                  let mess = error.response.data.errors.email[0];
                  Utils.notif(type, mess);
                } else {
                  let type = "error";
                  Utils.notif(type, "Error");
                }
              });
            return null;
          } catch (e) {
            return console.log("Oops errors!");
        }
        }
      },
      onCancel() {},
    });
  };
  return (
    <Card
      className="commande-edit"
      title={
      <Title level={3} className="d-flex justify-content-between">
        {titre} 
        <div className='text-center'>
            <ReactToPrint
                trigger={() => <Button type="primary" icon={<PrinterOutlined />}>Print</Button>}
                content={() => componentRef}
            />
        </div>  
      </Title>}
      loading={isLoadingData}
    >
      <div ref={(el) => (componentRef = el)}>
      <div className="row">
        <div className="col-md-5">
          {/* <h1>Order n° 5</h1> */}
          <p>
            <span className="title-label">Order :</span>{" "}
            {
              (order?.status?.id == 16) ? (
                <>DRAFT{order?.command_daft?.id}</>
              ) : (
                <>{" CM" + order?.number}</>
                )
            }
          </p>
          <p>
            <span className="title-label">Sale's rep. :</span>{" "}
            {order?.command_owner?.name}
          </p>
          <p>
            <span className="title-label">Contact :</span>{" "}
            {order?.command_owner?.email}
          </p>
        </div>
        <div className="col-md-4">
          <p>
            <span className="title-label">Date of order :</span>{" "}
            {order?.date_commande}{" "}
          </p>
        </div>
        <div className="col-md-3 text-center">
          {role !== "4" && (
            <p class="p-1 mb-1 border bg-white text-dark">Stock YS</p>
          )}
          <p
            class="p-1 mb-1 border text-dark"
            style={{ backgroundColor: "#fff7e3" }}
          >
            Standard production lead time
          </p>
          <p
            class="p-1 mb-1 border text-dark"
            style={{ backgroundColor: "rgb(227 255 242)" }}
          >
            Express production lead time
          </p>
        </div>
      </div>
      <div
        style={{ overflowX: "auto", marginTop: "15px", marginBottom: "15px" }}
      >
        <table class="table table-bordered">
          <thead>
            <tr>
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
              >
                Product name
              </th>
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
              >
                Color
              </th>
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
              >
                YS REF
              </th>
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
              >
                Size
              </th>
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
              >
                {
                  (role == "3") ? (
                    "Estimated Day of Shipping"
                  ) : (

                    "Product availability"
                  )
                }
              </th>
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
              >
                Quantity ordered
              </th>
              {(role === "1" || role === "2") &&
                props.show == false &&
                orderId == 1 && (
                  <>
                    <th
                      scope="col"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      Quantity in stock
                    </th>
                    <th
                      scope="col"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      Available quantity
                    </th>
                  </>
                )}
              {role === "4" && orderId === 8 ? (
                <th
                  scope="col"
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  Price
                </th>
              ) : (
                <th
                  scope="col"
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  {role === "4" ? (
                    "Price"
                  ) : (
                    "Purchase Price"
                  )
                  }
                </th>
              )}

              {/* {role === "4" && orderId === 8 && (
                <th
                  scope="col"
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  Unit extra cost
                </th>
              )} */}

              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
              > 
                {
                  role == "4" ? "Total" : "Price"
                }
              </th>

              {props.show == false &&
                (orderId == 9 ||
                  orderId == 1 ||
                  orderId == 2 ||
                  (orderId == 8 && role === "4")) && (
                  <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                    {/* { */}
                    {/* ((role !== "4" && orderId !== 8) || (role !== "3" && orderId !== 9)) &&( */}
                    <Checkbox
                      onChange={() => checkAll(!allCheck)}
                      checked={allCheck}
                    ></Checkbox>
                    {/* ) */}
                    {/* } */}
                  </th>
                )}

              {
                order?.in_production == 1 && (
                  <>
                    <th
                      scope="col"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      Shipping from Factory
                    </th>
                    <th
                      scope="col"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      Shipping from YS
                    </th>
                  </>
                )
              }
                
            </tr>
          </thead>
          <tbody>
            {order?.command_lines?.map((item, key_cmd_ligne) => {
              var row = 0;
              item.stocks.map((itemT) => {
                itemT.childs.map((chil) => {
                  row += chil.variants.length;
                });
              });
              var test = true;
              var istest = false;

              const ord = { name: item.catalogue_name };
              return (
                <>
                  {item.stocks.map((itemT, t) => {
                    var rowV = 0;
                    itemT.childs.map((chil) => {
                      rowV += chil.variants.length;
                    });
                    var testT = true;
                    var istestT = false;
                    if (t > 0) {
                      test = false;
                    }
                    const col = { ...ord, color: itemT.color };
                    let index = 0;
                    return (
                      <>
                        {itemT.childs.map((itemV, v) => {
                          index = index + 1;
                          var testV = true;
                          if (v > 0) {
                            test = false;
                            testT = false;
                          }
                          const siz = { ...col, size: itemV.size };
                          itemV?.variants.sort(function (a, b) {
                            return parseInt(a.id) - parseInt(b.id);
                          });
                          return (
                            <>
                              {itemV?.variants.map((itemVV, vv) => {
                                // console.log(itemVV)

                                let rowVV = itemV?.variants.length;
                                let isCheck = false;
                                if (
                                  itemVV.is_accelered == "1" &&
                                  itemV?.variants.length > 0
                                ) {
                                  if (
                                    (itemV?.variants[vv - 1]?.is_express ==
                                      "1" &&
                                      itemV?.variants[vv - 1]?.ready == true) ||
                                    (itemV?.variants[vv + 1]?.is_accelered ==
                                      "1" &&
                                      itemV?.variants[vv + 1]?.ready == true)
                                  ) {
                                    isCheck = true;
                                  } else {
                                    isCheck = false;
                                  }
                                }
                                const disp = {
                                  ...siz,
                                  ref: itemVV.reference_ys,
                                  size: itemV.size,
                                  type: itemVV.type,
                                  quantite: itemVV.quantity,
                                  additional_quantity: itemVV.additional_quantity,
                                  unit_price: itemVV.unit_price,
                                  unit_price_fabriquant: itemVV.unit_price_fabriquant,
                                  price: itemVV.price,
                                  price_fabriquant: itemVV.price_fabriquant,
                                  real_quantity: itemVV.real_quantity,
                                  ready: itemVV.ready ? itemVV.ready : false,
                                  status: parseInt(itemVV.status_id),
                                  date_disponibility: itemVV.date_disponibility,
                                  is_valid: itemVV.is_valid,
                                  is_accelered: itemVV.is_accelered,
                                  is_express: itemVV.is_express,
                                  is_check: isCheck,
                                  chipping_from_factory: itemVV.chipping_from_factory,
                                  chipping_from_ys: itemVV.chipping_from_ys,
                                  id_variante: itemVV.id

                                };
                                // console.log(disp)
                                var colorSS = "white";
                                var classColors = "white_print";
                                if (
                                  disp.type !== "in_stock" ||
                                  (disp.is_accelered == "0" &&
                                    disp.is_express == "1")
                                ) {
                                  colorSS = "#fff7e3";
                                  classColors = "yellow_print";
                                }
                                if (
                                  disp.is_accelered == "1" &&
                                  disp.is_express == "1"
                                ) {
                                  colorSS = "rgb(227 255 242)";
                                  classColors = "green_print";
                                }

                                if (vv > 0) {
                                  test = false;
                                  testT = false;
                                  testV = false;
                                }
                                // if(t>0 || v>0 || vv>0){
                                //     istest = true;
                                // }
                                // if(t>0 || v>0){
                                //     istestT = true;
                                // }
                                if (
                                  itemVV?.is_express == "1" &&
                                  itemVV?.is_accelered == "0"
                                ) {
                                } else if (
                                  itemVV?.is_express == "1" &&
                                  itemVV?.is_accelered == "0"
                                ) {
                                }
                                return (
                                  <>
                                    <tr>
                                      {(test == true ||
                                        (item?.stocks[t - 1]?.childs[v]
                                          ?.variants.length == 0 &&
                                          vv == 0) ||
                                        (item?.stocks[t]?.childs[v - 1]
                                          ?.variants.length == 0 &&
                                          vv == 0)) && (
                                        <td
                                          style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                          }}
                                          rowSpan={row}
                                        >
                                          <span style={{ marginLeft: "10px" }}>
                                            {disp.name}
                                          </span>
                                        </td>
                                      )}
                                      {(testT == true ||
                                        (item?.stocks[t - 1]?.childs[v]
                                          ?.variants.length == 0 &&
                                          vv == 0) ||
                                        (item?.stocks[t]?.childs[v - 1]
                                          ?.variants.length == 0 &&
                                          vv == 0)) && (
                                        <td
                                          style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                          }}
                                          rowSpan={rowV}
                                        >
                                          {disp.color}
                                        </td>
                                      )}
                                      {test == true && testT == true ? (
                                        <></>
                                      ) : (
                                        <></>
                                      )}
                                      {testV == true && (
                                        <>
                                          <td
                                            style={{
                                              textAlign: "center",
                                              verticalAlign: "middle",
                                            }}
                                            rowSpan={rowVV}
                                          >
                                            {disp.ref}
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "center",
                                              verticalAlign: "middle",
                                            }}
                                            rowSpan={rowVV}
                                          >
                                            {disp.size}{" "}
                                            {/* {
                                              item?.stocks[t - 1]?.childs[v]
                                                ?.variants.length
                                            } */}
                                          </td>

                                        </>
                                      )}
                                      {role === "4" ? (
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "150px",
                                            verticalAlign: "middle",
                                            backgroundColor: `${colorSS}`,
                                          }}
                                          className='p-0'
                                        >
                                          <div className={`${classColors} w-100 h-100 p-2`}>
                                            {disp.status == "8" ? (
                                              <Input
                                                type="date"
                                                onChange={(event) =>
                                                  addDate(t, v, vv, event, key_cmd_ligne)
                                                }
                                              />
                                            ) : (
                                              <>{disp.date_disponibility}</>
                                            )}

                                          </div>
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                            backgroundColor: `${colorSS}`,
                                          }}
                                          className='p-0'
                                        >
                                          <div className={`${classColors} w-100 h-100 p-2`}>
                                            {disp.type == "in_stock" &&
                                            disp.is_express === "0"
                                              ? formatDate()
                                              : disp.date_disponibility}

                                          </div>
                                        </td>
                                      )}

                                      <td
                                        style={{
                                          textAlign: "right",
                                          verticalAlign: "middle",
                                          backgroundColor: `${colorSS}`,
                                        }}
                                        className='p-0'
                                      >
                                        <div className={`${classColors} w-100 h-100 p-2`}>
                                          {
                                            (disp?.additional_quantity != null && (role == 1 || role == 2 || role == 4)) ? (
                                              <>
                                                {disp.quantite + disp.additional_quantity}
                                              </>

                                            ) : (
                                              <>{disp.quantite}</>  
                                            )
                                          }
                                        </div>
                                      </td>
                                      {(role === "1" || role === "2") &&
                                        props.show == false &&
                                        orderId == 1 && (
                                          <>
                                            <td
                                              style={{
                                                textAlign: "right",
                                                verticalAlign: "middle",
                                                backgroundColor: `${colorSS}`,
                                              }}
                                              className='p-0'
                                            >
                                              <div className={`${classColors} w-100 h-100 p-2`}>
                                                {disp.real_quantity}

                                              </div>
                                            </td>
                                            <td
                                              style={{
                                                width: "115px",
                                                backgroundColor: `${colorSS}`,
                                              }}
                                              className='p-0'
                                            >
                                              <div className={`${classColors} w-100 h-100 p-2`}>
                                                <Input
                                                  type="number"
                                                  onChange={(e) =>
                                                    changeAvailable(t, v, vv, e, key_cmd_ligne)
                                                  }
                                                />

                                              </div>
                                            </td>
                                          </>
                                        )}
                                      <td
                                        style={{
                                          textAlign: "right",
                                          verticalAlign: "middle",
                                          backgroundColor: `${colorSS}`,
                                        }}
                                        className='p-0'
                                      >
                                        {(role === "4" && orderId === 8) ? (
                                          <div className={`${classColors} w-100 h-100 p-2`}>
                                            {disp.is_accelered == "1" ? (
                                              <Input
                                                type="number"
                                                onChange={(event) =>
                                                  addPrice(t, v, vv, event, key_cmd_ligne)
                                                }
                                              />
                                            ) : (
                                              <div className={`${classColors} w-100 h-100 p-2`}>
                                                {Utils.formatMoney(
                                                  parseFloat(disp.unit_price_fabriquant)
                                                )}

                                              </div>
                                            )}

                                          </div>
                                        ) : (
                                          <div className={`${classColors} w-100 h-100 p-2`}>
                                            {(role === "4") ? (
                                              Utils.formatMoney(
                                                parseFloat(disp.unit_price_fabriquant)
                                              ) 
                                            ) : (
                                              Utils.formatMoney(
                                                parseFloat(disp.unit_price)
                                              )
                                            )}
                                          </div>
                                        )}
                                      </td>
                                      {/* {role === "4" && orderId === 8 && (
                                        <td
                                          style={{
                                            textAlign: "right",
                                            width: "100px",
                                            verticalAlign: "middle",
                                            backgroundColor: `${colorSS}`,
                                          }}
                                          className='p-0'
                                        >
                                        </td>
                                      )} */}

                                      <td
                                        style={{
                                          textAlign: "right",
                                          verticalAlign: "middle",
                                          backgroundColor: `${colorSS}`,
                                        }}
                                        className='p-0'
                                      >
                                        <div className={`${classColors} w-100 h-100 p-2`}>
                                        {(role === "4") ? (
                                          Utils.formatMoney(
                                            parseFloat(disp.unit_price_fabriquant * (disp.quantite + disp?.additional_quantity))
                                          )
                                        ) : (
                                          Utils.formatMoney(
                                            parseFloat(disp.price)
                                          )
                                        )}

                                        </div>
                                      </td>

                                      {props.show == false &&
                                        (orderId == 9 ||
                                          orderId == 1 ||
                                          orderId == 2 ||
                                          (orderId == 8 && role === "4")) && (
                                          <>
                                            <td
                                              style={{
                                                width: "115px",
                                                verticalAlign: "middle",
                                                textAlign: "center",
                                                backgroundColor: `${colorSS}`,
                                              }}
                                              className='p-0'
                                            >
                                              <div className={`${classColors} w-100 h-100 p-2`}>
                                                {disp.ready == true && (
                                                  <Checkbox
                                                    onChange={() =>
                                                      checkValider(
                                                        t,
                                                        v,
                                                        vv,
                                                        false,
                                                        disp,
                                                        key_cmd_ligne
                                                      )
                                                    }
                                                    checked={true}
                                                  ></Checkbox>
                                                )}
                                                {disp.ready == false && (
                                                  <Checkbox
                                                    onChange={() =>
                                                      checkValider(
                                                        t,
                                                        v,
                                                        vv,
                                                        true,
                                                        disp,
                                                        key_cmd_ligne
                                                      )
                                                    }
                                                    checked={false}
                                                  ></Checkbox>
                                                )}

                                              </div>
                                            </td>
                                            {role !== "4" && (
                                              <td
                                                style={{
                                                  width: "115px",
                                                  verticalAlign: "middle",
                                                  textAlign: "center",
                                                  backgroundColor: `${colorSS}`,
                                                }}
                                                className='p-0'
                                              >
                                                <div className={`${classColors} w-100 h-100 p-2`}>
                                                  <Button
                                                    type="danger"
                                                    onClick={() =>
                                                      deleteLigne(t, v, vv, key_cmd_ligne)
                                                    }
                                                    shape="circle"
                                                    icon={<DeleteOutlined />}
                                                  ></Button>

                                                </div>
                                              </td>
                                            )}
                                          </>
                                        )}
                                        {
                                          order?.in_production == 1 && (
                                            <>
                                              <td style={{ backgroundColor: `${colorSS}`, textAlign: "center"}} className='p-0' >
                                                <div className={`${classColors} w-100 h-100 p-2`}>
                                                  {
                                                    disp?.chipping_from_factory !== null ? (
                                                      <a href={disp?.chipping_from_factory} target="_blank" rel="noreferrer">
                                                        {disp?.chipping_from_factory}
                                                      </a>
                                                    ) : (
                                                      <Input type="text" defaultValue={disp?.chipping_from_factory}
                                                        disabled={role == 3}
                                                        placeholder="Factory Tracking number"
                                                        onChange={(e) => changeChiping(t, v, vv, e, 1, key_cmd_ligne)}
                                                      />
                                                    )
                                                  }
                                                  
                                                </div>
                                              </td>
                                              <td style={{ backgroundColor: `${colorSS}`, textAlign: "center"}} className='p-0'>
                                                <div className={`${classColors} w-100 h-100 p-2`}>
                                                  {
                                                    disp?.chipping_from_ys !== null ? (
                                                      <a href={disp?.chipping_from_ys} target="_blank" rel="noreferrer">
                                                        {disp?.chipping_from_ys}
                                                      </a>
                                                    ) : (
                                                      <Input type="text" defaultValue={disp?.chipping_from_ys}
                                                        disabled={role == 4 || role == 3}
                                                        placeholder="YS Tracking number"
                                                        onChange={(e) => changeChiping(t, v, vv, e, 0, key_cmd_ligne)}
                                                      />
                                                    )
                                                  }
                                                  
                                                </div>
                                              </td>
                                            </>
                                          )
                                        }
                                    </tr>
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-5">
              {(orderId === 4 || orderId === 5 || orderId === 7) ? (
                <>
                  <Form
                    layout="inline"
                    initialValues={{ layout: "inline" }}
                    form={formEditOrder}
                    name="formCreateItem"
                  >
                    <Form.Item label="Global status:" name="globalStatus">
                      <Select
                        onChange={showConfirm}
                        style={{ minWidth: "170px" }}
                        disabled={true}
                        defaultValue={parseInt(orderId)}
                        options={status}
                      />
                    </Form.Item>
                    {/* <Form.Item
                                                    label="Date de livraison :"
                                                > */}
                    {/* <input style={{border:'1px solid #d9d9d9'}} disabled={props.show} type="date" defaultValue={order?.date_commande} /> */}
                    {/* <DatePicker disabled={props.show} style={{ width: '150px' }} /> */}
                    {/* <DatePicker defaultValue={dayjs(order?.date_commande, 'YYYY-MM-DD')} disabled={props.show} style={{ width: '150px' }} /> */}
                    {/* </Form.Item> */}
                  </Form>
                </>
              ) : (
                <>
                  {(role === "4" && orderId === 8) ||
                  (role === "3" && orderId === 9) ? (
                    <Button
                      type="primary"
                      onClick={validerOrder}
                      className="btn-shadow mr-2"
                    >
                      Validate
                    </Button>
                  ) : role !== "4" && orderId !== 8 ? (
                    <Form
                      layout="inline"
                      initialValues={{ layout: "inline" }}
                      form={formEditOrder}
                      name="formCreateItem"
                    >
                      <Form.Item label="Global status:" name="globalStatus">
                        <Select
                          onChange={showConfirm}
                          style={{ minWidth: "170px" }}
                          disabled={props.show}
                          defaultValue={parseInt(orderId)}
                          options={status}
                        />
                      </Form.Item>
                      {/* <Form.Item
                                                        label="Date de livraison :"
                                                    > */}
                      {/* <input style={{border:'1px solid #d9d9d9'}} disabled={props.show} type="date" defaultValue={order?.date_commande} /> */}
                      {/* <DatePicker disabled={props.show} style={{ width: '150px' }} /> */}
                      {/* <DatePicker defaultValue={dayjs(order?.date_commande, 'YYYY-MM-DD')} disabled={props.show} style={{ width: '150px' }} /> */}
                      {/* </Form.Item> */}
                    </Form>
                  ) : (
                    <></>
                  )}
                </>
              )}

              {(role == "1" || role == "2") &&
                orderId == 4 && (
                  <>
                    <br></br>
                    {
                        order.in_production!="1" ? (
                          <>
                            <Button
                            type="primary"
                            style={{ backgroundColor: '#51C41B', border: 'none' }}
                            onClick={(e)=> changeProduction(1)}
                            className="btn-shadow mr-3"
                            >
                            Preparation
                            </Button>

                            <Button
                            type="primary"
                            onClick={(e)=> changeProduction(0)}
                            className="btn-shadow"
                            >
                            Production
                            </Button>
                          </>
                        ):(
                            <>
                              <Button
                              type="primary"
                              disabled
                              className="btn-shadow"
                              >
                              In production
                              </Button>
                            </>
                        )
                    }
                    
                    {true === true && (
                      <Modal
                        title={
                          typeprod === 0 ? "Confirm production" : "Confirm preparation"
                        }
                        centered
                        width={"1000"}
                        open={modal2Open}
                        onOk={() => setModal2Open(false)}
                        onCancel={() => setModal2Open(false)}
                        footer={[]}
                      >
                        <div
                          style={{
                            overflowX: "auto",
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                        >
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  Product name
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  Color
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  Supplier REF
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  YS REF
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  Size
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {
                                    (role == "3") ? (
                                      "Estimated Day of Shipping"
                                    ) : (

                                      "Product availability"
                                    )
                                  }
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  Quantity ordered
                                </th>
                                {(role === "1" || role === "2") &&
                                  props.show == false &&
                                  orderId == 1 && (
                                    <>
                                      <th
                                        scope="col"
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        Quantity in stock
                                      </th>
                                      <th
                                        scope="col"
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        Available quantity
                                      </th>
                                    </>
                                  )}
                                {
                                  typeprod === 0 && (
                                    <th
                                      scope="col"
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      Additionnal Quantity (Ys)
                                    </th>
                                  )
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {order?.command_lines?.map((item, key_cmd_ligne) => {
                                var row = 0;
                                item.stocks.map((itemT) => {
                                  itemT.childs.map((chil) => {
                                    row += chil.variants.length;
                                  });
                                });
                                var test = true;
                                var istest = false;

                                const ord = { name: item.catalogue_name, supplier_reference: item?.catalogue_reference };
                                return (
                                  <>
                                    {item.stocks.map((itemT, t) => {
                                      var rowV = 0;
                                      itemT.childs.map((chil) => {
                                        rowV += chil.variants.length;
                                      });
                                      var testT = true;
                                      var istestT = false;
                                      if (t > 0) {
                                        test = false;
                                      }
                                      const col = {
                                        ...ord,
                                        color: itemT.color,
                                      };
                                      let index = 0;
                                      return (
                                        <>
                                          {itemT.childs.map((itemV, v) => {
                                            index = index + 1;
                                            var testV = true;
                                            if (v > 0) {
                                              test = false;
                                              testT = false;
                                            }
                                            const siz = {
                                              ...col,
                                              size: itemV.size,
                                            };
                                            itemV?.variants.sort(function (
                                              a,
                                              b
                                            ) {
                                              return (
                                                parseInt(a.id) - parseInt(b.id)
                                              );
                                            });
                                            return (
                                              <>
                                                {itemV?.variants.map(
                                                  (itemVV, vv) => {
                                                    let rowVV =
                                                      itemV?.variants.length;
                                                    let isCheck = false;
                                                    if (
                                                      itemVV.is_accelered ==
                                                        "1" &&
                                                      itemV?.variants.length > 0
                                                    ) {
                                                      if (
                                                        (itemV?.variants[vv - 1]
                                                          ?.is_express == "1" &&
                                                          itemV?.variants[
                                                            vv - 1
                                                          ]?.ready == true) ||
                                                        (itemV?.variants[vv + 1]
                                                          ?.is_accelered ==
                                                          "1" &&
                                                          itemV?.variants[
                                                            vv + 1
                                                          ]?.ready == true)
                                                      ) {
                                                        isCheck = true;
                                                      } else {
                                                        isCheck = false;
                                                      }
                                                    }
                                                    const disp = {
                                                      ...siz,
                                                      ref: itemVV.reference_ys,
                                                      size: itemV.size,
                                                      type: itemVV.type,
                                                      quantite: itemVV.quantity,
                                                      additional_quantity: itemVV.additional_quantity,
                                                      unit_price:
                                                        itemVV.unit_price,
                                                      price: itemVV.price,
                                                      real_quantity:
                                                        itemVV.real_quantity,
                                                      ready: itemVV.ready
                                                        ? itemVV.ready
                                                        : false,
                                                      status: parseInt(
                                                        itemVV.status_id
                                                      ),
                                                      date_disponibility:
                                                        itemVV.date_disponibility,
                                                      is_valid: itemVV.is_valid,
                                                      is_accelered:
                                                        itemVV.is_accelered,
                                                      is_express:
                                                        itemVV.is_express,
                                                      is_check: isCheck,
                                                    };
                                                    var colorSS = "white";
                                                    var classColors = "white_print";
                                                    if (
                                                      disp.type !==
                                                        "in_stock" ||
                                                      (disp.is_accelered == "0" && disp.is_express == "1")
                                                    ) {
                                                      colorSS = "#fff7e3";
                                                      classColors = "yellow_print";
                                                    }
                                                    if (disp.is_accelered == "1" && disp.is_express == "1"
                                                    ) {
                                                      colorSS = "rgb(227 255 242)";
                                                      classColors = "green_print";
                                                    }

                                                    if (vv > 0) {
                                                      test = false;
                                                      testT = false;
                                                      testV = false;
                                                    }
                                                    if (
                                                      itemVV?.is_express ==
                                                        "1" &&
                                                      itemVV?.is_accelered ==
                                                        "0"
                                                    ) {
                                                    } else if (
                                                      itemVV?.is_express ==
                                                        "1" &&
                                                      itemVV?.is_accelered ==
                                                        "0"
                                                    ) {
                                                    }
                                                    return (
                                                      <>
                                                        <tr>
                                                          {(test == true ||
                                                            (item?.stocks[t - 1]
                                                              ?.childs[v]
                                                              ?.variants
                                                              .length == 0 &&
                                                              vv == 0) ||
                                                            (item?.stocks[t]
                                                              ?.childs[v - 1]
                                                              ?.variants
                                                              .length == 0 &&
                                                              vv == 0)) && (
                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                                verticalAlign:
                                                                  "middle",
                                                              }}
                                                              rowSpan={row}
                                                            >
                                                              <span
                                                                style={{
                                                                  marginLeft:
                                                                    "10px",
                                                                }}
                                                              >
                                                                {disp.name}
                                                              </span>
                                                            </td>
                                                          )}
                                                          {(testT == true ||
                                                            (item?.stocks[t - 1]
                                                              ?.childs[v]
                                                              ?.variants
                                                              .length == 0 &&
                                                              vv == 0) ||
                                                            (item?.stocks[t]
                                                              ?.childs[v - 1]
                                                              ?.variants
                                                              .length == 0 &&
                                                              vv == 0)) && (
                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                                verticalAlign:
                                                                  "middle",
                                                              }}
                                                              rowSpan={rowV}
                                                            >
                                                              {disp.color}
                                                            </td>
                                                          )}
                                                          {test == true &&
                                                          testT == true ? (
                                                            <></>
                                                          ) : (
                                                            <></>
                                                          )}
                                                          {testV == true && (
                                                            <>
                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                                verticalAlign:
                                                                  "middle",
                                                              }}
                                                              rowSpan={rowVV}
                                                            >
                                                              {disp.supplier_reference}
                                                            </td>
                                                              <td
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                  verticalAlign:
                                                                    "middle",
                                                                }}
                                                                rowSpan={rowVV}
                                                              >
                                                                {disp.ref}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                  verticalAlign:
                                                                    "middle",
                                                                }}
                                                                rowSpan={rowVV}
                                                              >
                                                                {disp.size}{" "}
                                                                {/* {
                                                                  item?.stocks[
                                                                    t - 1
                                                                  ]?.childs[v]
                                                                    ?.variants
                                                                    .length
                                                                } */}
                                                              </td>
                                                            
                                                            </>
                                                          )}
                                                          {role === "4" ? (
                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                                width: "150px",
                                                                verticalAlign:
                                                                  "middle",
                                                                backgroundColor: `${colorSS}`,
                                                              }}
                                                              className='p-0'
                                                            >
                                                              <div className={`${classColors} w-100 h-100 p-2`}>
                                                                {disp.status ==
                                                                "8" ? (
                                                                  <Input
                                                                    type="date"
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      addDate(
                                                                        t,
                                                                        v,
                                                                        vv,
                                                                        event,
                                                                        key_cmd_ligne
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <>
                                                                    {
                                                                      disp.date_disponibility
                                                                    }
                                                                  </>
                                                                )}

                                                              </div>
                                                            </td>
                                                          ) : (
                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                                verticalAlign:
                                                                  "middle",
                                                                backgroundColor: `${colorSS}`,
                                                              }}
                                                              className='p-0'
                                                            >
                                                              <div className={`${classColors} w-100 h-100 p-2`}>
                                                                {disp.type ==
                                                                  "in_stock" &&
                                                                disp.is_express ===
                                                                  "0"
                                                                  ? formatDate()
                                                                  : disp.date_disponibility}

                                                              </div>
                                                            </td>
                                                          )}

                                                          <td
                                                            style={{
                                                              textAlign:
                                                                "right",
                                                              verticalAlign:
                                                                "middle",
                                                              backgroundColor: `${colorSS}`,
                                                            }}
                                                            className='p-0'
                                                          >
                                                            <div className={`${classColors} w-100 h-100 p-2`}>
                                                              {disp.quantite}
                                                            </div>
                                                          </td>
                                                          {
                                                            typeprod === 0 && (
                                                              (itemVV.type === "restock_two" || itemVV.is_express === "1") ? (
                                                                <td
                                                                  style={{
                                                                    textAlign:
                                                                      "right",
                                                                    verticalAlign:
                                                                      "middle",
                                                                    backgroundColor: `${colorSS}`,
                                                                  }}
                                                                  className='p-0'
                                                                >
                                                                  <div className={`${classColors} w-100 h-100 p-2`}>
                                                                    <InputNumber
                                                                      defaultValue={0}
                                                                      onChange={(e) =>
                                                                        changeYSQ(
                                                                          t,
                                                                          v,
                                                                          vv,
                                                                          e,
                                                                          key_cmd_ligne
                                                                        )
                                                                      }
                                                                    />
                                                                    
                                                                  </div>
                                                                </td>
                                                                ) : (
                                                                  (itemVV.is_accelered === 0 &&  itemVV.is_express === 0) ? (
                                                                      <td></td>
                                                                  ) : (
                                                                    <td
                                                                      style={{
                                                                        textAlign:
                                                                          "right",
                                                                        verticalAlign:
                                                                          "middle",
                                                                        backgroundColor: `${colorSS}`,
                                                                      }}
                                                                      className='p-0'
                                                                    >
                                                                      <div className={`${classColors} w-100 h-100 p-2`}>
                                                                        {/* <InputNumber
                                                                          defaultValue={0}
                                                                        /> */}
                                                                        <InputNumber
                                                                          defaultValue={0}
                                                                          onChange={(e) =>
                                                                            changeYSQ(
                                                                              t,
                                                                              v,
                                                                              vv,
                                                                              e,
                                                                              key_cmd_ligne
                                                                            )
                                                                          }
                                                                        />
        
                                                                      </div>
                                                                    </td>
    
                                                                  )
                                                                
                                                              )

                                                            )
                                                          }
                                                        </tr>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </>
                                            );
                                          })}
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <Button
                          // loading={isLoading}
                          type="primary"
                          onClick={validProduction}
                          className="btn-shadow"
                        >
                          Confirm
                        </Button>
                      </Modal>
                    )}
                  </>
              )}
              {((role != "4" && order?.date_versement_accompte !== null)) && (
                <p className="mt-3"><b>Date of deposit : {order?.date_versement_accompte}</b> </p>
              )}
              {
                order?.date_in_production !== null &&
                <p className="mt-3"><b>Production started date : {order?.date_in_production}</b> </p>
              }          
            </div>
            <div className="col-md-3">
              {
                role === "3" ? (
                  <>
                    <p>
                      <span className="title-label">Total quantity :</span>{" "}
                      {footer.quantity}{" "}
                    </p>
                    <p>
                      <span className="title-label">Total price :</span>{" "}
                      {Utils.formatMoney(parseFloat(footer.totalPrice))}{" "}
                    </p>
                    <p>
                      {
                        !isNaN(parseFloat(footer.remise)) && (
                          <>
                            <span className="title-label">Discount :</span>{" "}
                            {Utils.formatMoney(parseFloat(footer.remise))} ({order?.remise}%){" "}

                          </>
                        )
                      }
                    </p>
                    <p>

                      {
                        !isNaN(parseFloat(footer.totalPay)) && (
                          <>
                            <span className="title-label">Total to pay :</span>{" "}
                            {Utils.formatMoney(parseFloat(footer.totalPay))}

                          </>
                        )
                      }
                    </p>
                    <p>
                      {
                        !isNaN(parseFloat(footer.acompte)) && (
                          <>
                            <span className="title-label">Deposit :</span>{" "}
                            {Utils.formatMoney(parseFloat(footer.acompte))}

                          </>
                        )
                      }
                    </p>
                    <p>
                      {
                        !isNaN(parseFloat(footer.leftPay)) && (
                          <>
                            <span className="title-label">Balance payment :</span>{" "}
                            {Utils.formatMoney(parseFloat(footer.leftPay))}

                          </>
                        )
                      }
                    </p>                  
                  </>
                ) : ( 
                  (role === "0" || role === "1") ? (
                    <>
                    <p>
                      <span className="title-label">Total quantity :</span>{" "}
                      {footer.quantityFabrice}{" "}
                    </p>
                    <p>
                      <span className="title-label">Total price :</span>{" "}
                      {Utils.formatMoney(parseFloat(footer.totalPriceFabrice))}{" "}
                    </p>
                    <p>
                      {
                        !isNaN(parseFloat(footer.remise)) && (
                          <>
                            <span className="title-label">Discount :</span>{" "}
                            {Utils.formatMoney(parseFloat(footer.remise))} ({order?.remise}%){" "}

                          </>
                        )
                      }
                    </p>
                    <p>

                      {
                        !isNaN(parseFloat(footer.totalPay)) && (
                          <>
                            <span className="title-label">Total to pay :</span>{" "}
                            {Utils.formatMoney(parseFloat(footer.totalPay))}

                          </>
                        )
                      }
                    </p>
                    <p>
                      {
                        !isNaN(parseFloat(footer.acompte)) && (
                          <>
                            <span className="title-label">Deposit :</span>{" "}
                            {Utils.formatMoney(parseFloat(footer.acompte))}

                          </>
                        )
                      }
                    </p>
                    <p>
                      {
                        !isNaN(parseFloat(footer.leftPay)) && (
                          <>
                            <span className="title-label">Balance payment :</span>{" "}
                            {Utils.formatMoney(parseFloat(footer.leftPay))}

                          </>
                        )
                      }
                    </p>                  
                  </>

                  ) : (
                    <>
                      <p>
                        <span className="title-label">Total quantity :</span>{" "}
                        {footer.quantityFabrice}{" "}
                      </p>
                      <p>
                        <span className="title-label">Total price :</span>{" "}
                        {Utils.formatMoney(parseFloat(footer.totalPriceFabrice))}{" "}
                      </p>
                    </>
                  )
              )}
              {
                order?.in_production == "1" && orderId === 4 && role === "4" && (
                  <>
                    <Button
                      type="primary"
                      disabled
                      className="btn-shadow"
                    >
                      In production
                    </Button>
                    {
                      order?.date_in_production !== null &&
                      <p className="mt-3"><b>Production started date : {order?.date_in_production}</b> </p>
                    }  
                  
                  </>
                )
              }
            </div>

            <div className="col-md-4 row">
              {(orderId === 3 || orderId === 6) && role === "3" && (
                <>
                  {/* <h1>{order?.acompte}</h1> */}
                  <div className="col-md-6">
                    <PaymentForm
                      total={parseFloat(order?.acompte)}
                      commandeNumber={" CM" + order?.number} //etozao
                      id={order?.id}
                      setStatusE={setStatusE}
                    />
                  </div>
                  <div className="col-md-6">
                    <Button
                      type="primary"
                      onClick={payVB}
                      className="btn-shadow ml-2 StripeCheckout"
                      style={{
                        overflow: "hidden",
                        display: "inline-block",
                        background:
                          "linear-gradient(rgb(40, 160, 229), rgb(1, 94, 148))",
                        border: "0px",
                        padding: "1px",
                        textDecoration: "none",
                        borderRadius: "5px",
                        boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 0px",
                        cursor: "pointer",
                        visibility: "visible",
                        userSelect: "none",
                      }}
                    >
                      <span
                        style={{
                          backgroundImage:
                            "linear-gradient(rgb(125, 197, 238), rgb(0, 140, 221) 85%, rgb(48, 162, 228))",
                          fontFamily: "Helvetica, Arial, sans-serif",
                          fontSize: "14px",
                          position: "relative",
                          padding: "0px 12px",
                          display: "block",
                          height: "30px",
                          lineHeight: "30px",
                          color: "rgb(255, 255, 255)",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(255, 255, 255, 0.25) 0px 1px 0px inset",
                          textShadow:
                            "rgba(0, 0, 0, 0.25) 0px -1px 0px; border-radius: 4px",
                        }}
                      >
                        Wire transfer
                      </span>
                    </Button>
                  </div>
                </>
              )}
              {
                (order?.in_production == 1 && (role === "1" || role === "2" || role === "4" )) && (
                  <div className="w-100 text-right">
                    <Button
                      loading={isLoading}
                      type="primary"
                      onClick={showConfirmChipping}
                      className="btn-shadow"
                    >
                      Submit SHIPPNG
                    </Button>
                  </div>
                )
              }
               {
                (orderId == 10 && role === "3" &&  order?.payment_type === "vb") && (
                  <>
                      {/* etozao */}
                      <Form
                        layout="inline"
                        initialValues={{ layout: "inline" }}
                        form={formProof}
                        name="formCreateItem"
                        onFinish={(e) => sendImageProof(e)}
                      >
                        <Form.Item label="Upload proof of payment" name="wire_transfer_file">
                          <label
                            htmlFor="default_image"
                            style={{
                              backgroundImage: `url("${catalog_file ? catalog_file.preview : ''}")`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}
                            className="drop-container-image-default"
                          >
                            <Text>+</Text>
                            <Input
                              style={{ display: "none" }}
                              id="default_image"
                              className="file-uploader"
                              type="file"
                              accept="image/*"
                              onChange={onChangeDImage}
                            />
                          </label>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="mr-3"
                          >
                            SEND
                          </Button>
                        </Form.Item>
                      </Form>
                  </>
                )
              }
            </div>

            
          </div>
        </div>
      </div>
      </div>
    </Card>
  );
};

export default withTranslation()(EditCommande);
