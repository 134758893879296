import React, { useEffect, useState } from 'react';
import { withTranslation  } from 'react-i18next';
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { Button, Select, Form, Input, Card, Typography } from "antd";
import BASE_URL from "../../../config.js";
import { Utils } from '../../../services/Utils.js';
const { Title } = Typography;

const AddCategory = (props) => {
    const [formCreateCategory] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [category, setCategory] = useState([]);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .get(`${BASE_URL}/api/v1/category/list?parent_id=`, { headers })
      .then((res) => {
        const categoryData = res.data?.data;
        setCategory(categoryData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

    const saveCategory = (value) => {
        setIsLoading(true);
        const parent_id = null;
        const { label, description } = value;
        // const { label, description, parent_id } = value;
        const Authorization = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Authorization,
        };
        axios.post(`${BASE_URL}/api/v1/category/add`, { label, description, parent_id }, { headers })
        .then(response => {
            setIsLoading(false);
            if(response.data.success){
                let type = 'success'
                let mess = response.data?.message
                Utils.notif(type, mess);

                clearForm();
                history.push("/dashboard/category");
            }
        })
        .catch(error => {
            setIsLoading(false);
            console.log(error.response.data.errors.email[0]);
            if(error.response.data.errors.email != null){
                let type = 'error'
                let mess = error.response.data.errors.email[0]
                Utils.notif(type, mess);
            }
        });
    };
    const clearForm = () => {
        formCreateCategory.setFieldsValue({
            label: null,
            description: null,
            parent_id: null,
        });
    }
    return (
        <>
            <Card title={<Title level={3}>{props.t('add_categorie')}</Title>}>
            <div className="row">
            <div className="col-md-12">
            <Form
                form={formCreateCategory}
                name="formCreateCategory"
                labelCol={{
                span: 8,
                }}
                wrapperCol={{
                span: 16,
                }}
                style={{
                maxWidth: 600,
                }}
                initialValues={{
                remember: true,
                }}
                onFinish={saveCategory}
                onFinishFailed={null}
                autoComplete="off"
            >
                <Form.Item
                label={props.t("label")}
                name="label"
                rules={[
                    {
                    required: true,
                    message: "Please input label!",
                    },
                ]}
                >
                <Input />
                </Form.Item>

                <Form.Item name="description" label={props.t("description")}>
                    <Input.TextArea />
                </Form.Item>
                
                
                {/* <Form.Item
                label={props.t("parent_id")}
                name="parent_id"
                >
                <Select
                    allowClear
                    placeholder={props.t('parent_id')}
                    showSearch
                    optionFilterProp="children"
                >
                    {
                        category && category.map((item) => (
                            <Select.Option
                                key={`key-${item.id}`}
                                value={item.id}
                            >
                                {item.label}
                            </Select.Option>
                        ))
                    }
                        
                </Select>
                </Form.Item> */}
                <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    className="mr-3"
                >
                    {props.t("btn_save")}
                </Button>
                <Link
                    to='/dashboard/category'
                >
                    {props.t('retour_liste')}
                </Link>
                </Form.Item>
            </Form>
            </div>
        </div>
            </Card>
        </>
    );
}

export default withTranslation()(AddCategory)