import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../../../config.js";
import {
  Button,
  Form,
  Input,
  Card,
  Typography,
  Checkbox,
  Collapse,
} from "antd";
import { Utils } from "../../../services/Utils.js";
import { withTranslation } from "react-i18next";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea.js";
const { Panel } = Collapse;
const { Title } = Typography;
const Parameters = (props) => {
  const [role, setRole] = useState(localStorage.getItem("label") || null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [fabricMail, setFabricMail] = useState(null);
  const [fabricMailL, setFabricMailL] = useState(null);
  const [adminMail, setAdminMail] = useState(null);
  const [adminMailL, setAdminMailL] = useState(null);
  const [ribFacture, setRibFacture] = useState(null);
  const [adressFacture, setAdressFacture] = useState(null);
  const [textFooterSite, setTextFooterSite] = useState(null);
  const [codeColorSite, setCodeColorSite] = useState(null);

  const getProducts = () => {
    setIsLoadingData(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };

    axios
      .get(`${BASE_URL}/api/v1/parameter/list`, { headers })
      .then((res) => {
        const productData = res.data?.data;
        setProducts(productData);
        setIsLoadingData(false);
      })
      .catch((error) => {
        setIsLoadingData(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getProducts();
  }, []);

  const changeMailFabric = (e) => {
    setFabricMail(e.target.value);
  };
  const changeMailFabricL = (e) => {
    setFabricMailL(e.target.value);
  };
  const updateMailFabric = (id, valueP, label, value, types,key) => {
    setIsLoading(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    try {
      const response = axios.post(
        `${BASE_URL}/api/v1/parameter/edit/${id}`,
        {
          value: valueP,
          children: [
            {
              label: fabricMailL ? fabricMailL : label,
              value: fabricMail ? fabricMail : value,
              type: types,
              key: key
            },
          ],
        },
        { headers }
      );

      setIsLoading(false);
      let type = "success";
      let mess = "Entity updated successfully.";

      Utils.notif(type, mess);
    } catch (error) {
      setIsLoading(false);
      let type = "error";
      let mess = "";

      Utils.notif(type, mess);
      console.log(error);
    }
  };

  const changeMailAdmin = (e) => {
    setAdminMail(e.target.value);
  };
  const changeMailAdminL = (e) => {
    setAdminMailL(e.target.value);
  };
  const updateMailAdmin = (id, valueP, label, value, types,key) => {
    setIsLoading(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    try {
      const response = axios.post(
        `${BASE_URL}/api/v1/parameter/edit/${id}`,
        {
          value: valueP,
          children: [
            { label: adminMailL ? adminMailL:label, value: adminMail ? adminMail : value, type: types,key:key },
          ],
        },
        { headers }
      );

      setIsLoading(false);
      let type = "success";
      let mess = "Entity updated successfully.";

      Utils.notif(type, mess);
    } catch (error) {
      setIsLoading(false);
      let type = "error";
      let mess = "";

      Utils.notif(type, mess);
      console.log(error);
    }
  };

  const changeRibFacture = (label, value, type, e) => {
    setRibFacture({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  const changeAdressFacture = (label, value, type, e) => {
    setAdressFacture({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  const updateFacture = (id, valueP) => {
    setIsLoading(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    try {
      const temp = [];
      if (ribFacture != null) {
        temp.push(ribFacture);
      }
      if (adressFacture != null) {
        temp.push(adressFacture);
      }
      axios.post(
        `${BASE_URL}/api/v1/parameter/edit/${id}`,
        {
          value: valueP,
          children: temp,
        },
        { headers }
      );

      setIsLoading(false);
      let type = "success";
      let mess = "Entity updated successfully.";

      Utils.notif(type, mess);
    } catch (error) {
      setIsLoading(false);
      let type = "error";
      let mess = "";

      Utils.notif(type, mess);
      console.log(error);
    }
  };

  const changeTextFooterSite = (label, value, type, e) => {
    setTextFooterSite({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  const changeCodeColorSite = (label, value, type, e) => {
    setCodeColorSite({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  const updateSite = (id, valueP) => {
    setIsLoading(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    try {
      const temp = [];
      if (textFooterSite != null) {
        temp.push(textFooterSite);
      }
      if (codeColorSite != null) {
        temp.push(codeColorSite);
      }
      axios.post(
        `${BASE_URL}/api/v1/parameter/edit/${id}`,
        {
          value: valueP,
          children: temp,
        },
        { headers }
      );

      setIsLoading(false);
      let type = "success";
      let mess = "Entity updated successfully.";

      Utils.notif(type, mess);
    } catch (error) {
      setIsLoading(false);
      let type = "error";
      let mess = "";

      Utils.notif(type, mess);
      console.log(error);
    }
  };

  const changeAdminNotifMail = (label, value, type, e) => {
    setAdminMail({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  
  const [invMail, setInvMail] = useState(null);
  const changeInvNotifMail = (label, value, type, e) => {
    setInvMail({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };

  const changeFabricNotifMail = (label, value, type, e) => {
    setFabricMail({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };

  const updateEmail = (id, valueP) => {
    setIsLoading(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    try {
      const temp = [];
      if (adminMail != null) {
        temp.push(adminMail);
      }
      if (fabricMail != null) {
        temp.push(fabricMail);
      }
      if (invMail != null) {
        temp.push(invMail);
      }
      axios.post(
        `${BASE_URL}/api/v1/parameter/edit/${id}`,
        {
          value: valueP,
          children: temp,
        },
        { headers }
      );

      setIsLoading(false);
      let type = "success";
      let mess = "Entity updated successfully.";

      Utils.notif(type, mess);
    } catch (error) {
      setIsLoading(false);
      let type = "error";
      let mess = "";

      Utils.notif(type, mess);
      console.log(error);
    }
  };

  const [stockLow, setStockLow] = useState(null);
  const [acomptePay, setAcomptePay] = useState(null);
  const [remiseRPay, setRemiseRPay] = useState(null);
  const [remiseFPay, setRemiseFPay] = useState(null);
  const [remiseDPay, setRemiseDPay] = useState(null);
  const [stripeP, setStripeP] = useState(null);
  const [stripeS, setStripeS] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const changeAcomptePay = (label, value, type, e) => {
    setAcomptePay({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  const changeStockLow = (label, value, type, e) => {
    setStockLow({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  const changeRemiseRPay = (label, value, type, e) => {
    setRemiseRPay({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  const changeRemiseFPay = (label, value, type, e) => {
    setRemiseFPay({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  const changeRemiseDPay = (label, value, type, e) => {
    setRemiseDPay({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  const changeStripePPay = (label, value, type, e) => {
    setStripeP({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  const changeStripeSPay = (label, value, type, e) => {
    setStripeS({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  const changeDueDatePay = (label, value, type, e) => {
    setDueDate({
      label: label,
      value: e.target.value,
      type: type,
      key:value
    });
  };
  const updatePay = (id, valueP) => {
    setIsLoading(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    try {
      const temp = [];
      if (acomptePay != null) {
        temp.push(acomptePay);
      }
      if (stockLow != null) {
        temp.push(stockLow);
      }
      if (remiseRPay != null) {
        temp.push(remiseRPay);
      }
      if (remiseDPay != null) {
        temp.push(remiseDPay);
      }
      if (remiseFPay != null) {
        temp.push(remiseFPay);
      }
      if (stripeP != null) {
        temp.push(stripeP);
      }
      if (stripeS != null) {
        temp.push(stripeS);
      }
      if (dueDate != null) {
        temp.push(dueDate);
      }
      axios.post(
        `${BASE_URL}/api/v1/parameter/edit/${id}`,
        {
          value: valueP,
          children: temp,
        },
        { headers }
      );

      setIsLoading(false);
      let type = "success";
      let mess = "Entity updated successfully.";

      Utils.notif(type, mess);
    } catch (error) {
      setIsLoading(false);
      let type = "error";
      let mess = "";

      Utils.notif(type, mess);
      console.log(error);
    }
  };
  return (
    <Card title={<Title level={3}>{props.t("parameters")}</Title>}>
      <div className="row">
        {products.map((item) => {
          return (
            <>
              {/* {
              item?.children?.map((itemC) => {
                return (
                  <>
                    {
                      item?.name == "email_notif" && (role === "1" || role === "2") && (
                        <>
                          <div className="col-md-6 mb-3">
                                <Collapse size="large">
                                  <Panel header={item.value} key="1">
                                  {
                                    (itemC.key == "admin_notif") &&(
                                    <div className="row mb-2">
                                      <div className="col-md-5">
                                      <p className="mt-1">{itemC?.label}:</p>
                                         <Input
                                          className="mb-2"
                                          type="text"
                                          defaultValue={itemC?.label}
                                          onChange={changeMailAdminL}
                                          placeholder="Label :"
                                        /> 
                                      </div>
                                      <div className="col-md-7">
                                        <Input
                                          className="mb-2"
                                          type="text"
                                          defaultValue={itemC?.value}
                                          onChange={changeMailAdmin}
                                          placeholder="example1@gmail.com,example2@gmail.com"
                                        />
                                      </div>
                                    </div>
                                    )}
                                    {
                                    (itemC.key == "inventory_controller_notif") &&(
                                    <div className="row mb-2">
                                      <div className="col-md-5">
                                      <p className="mt-1">{itemC?.label}:</p>
                                         <Input
                                          className="mb-2"
                                          type="text"
                                          defaultValue={itemC?.label}
                                          onChange={changeMailAdminL}
                                          placeholder="Label :"
                                        /> 
                                      </div>
                                      <div className="col-md-7">
                                        <Input
                                          className="mb-2"
                                          type="text"
                                          defaultValue={itemC?.value}
                                          onChange={changeMailFabric}
                                          placeholder="example1@gmail.com,example2@gmail.com"
                                        />
                                      </div>
                                      </div>
                                    )
                                  }
                                    
                                    <Button
                                      type="primary"
                                      onClick={() =>
                                        updateMailAdmin(
                                          item?.id,
                                          item?.value,
                                          itemC.label,
                                          itemC.value,
                                          itemC.type,
                                          itemC.key
                                        )
                                      }
                                    >
                                      Save
                                    </Button>
                                  </Panel>
                                </Collapse>
                              
                          </div>
                        </>
                      )}
                      
                  </>
                );
              })} */}
              {item?.name == "email_notif" && (role === "1" || role === "2") && (
                <>
                  <div className="col-md-6 mb-3">
                    <Collapse size="large">
                      <Panel header={item.value} key="1">
                        {item?.children?.map((itemC) => {
                          return (
                            <>
                              {itemC.key == "admin_notif" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeAdminNotifMail(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {itemC.key == "inventory_controller_notif" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeInvNotifMail(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                        <Button
                          type="primary"
                          onClick={() => updateEmail(item?.id, item?.value)}
                        >
                          Save
                        </Button>
                      </Panel>
                    </Collapse>
                  </div>
                </>
              )}
              {item?.name == "email_notif" && (role === "4") && (
                <>
                  <div className="col-md-6 mb-3">
                    <Collapse size="large">
                      <Panel header={item.value} key="1">
                        {item?.children?.map((itemC) => {
                          return (
                            <>
                              {itemC.key == "manufacturer_notif" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeFabricNotifMail(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {/* {itemC.key == "inventory_controller_notif" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeInvNotifMail(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )} */}
                            </>
                          );
                        })}
                        <Button
                          type="primary"
                          onClick={() => updateEmail(item?.id, item?.value)}
                        >
                          Save
                        </Button>
                      </Panel>
                    </Collapse>
                  </div>
                </>
              )}
              {item?.name == "site" && (role === "1" || role === "2") && (
                <>
                  <div className="col-md-6 mb-3">
                    <Collapse size="large">
                      <Panel header={item.value} key="1">
                        {item?.children?.map((itemC) => {
                          return (
                            <>
                              {itemC.key == "footer" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeTextFooterSite(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {itemC.key == "code_color" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeCodeColorSite(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                        <Button
                          type="primary"
                          onClick={() => updateSite(item?.id, item?.value)}
                        >
                          Save
                        </Button>
                      </Panel>
                    </Collapse>
                  </div>
                </>
              )}
              {item?.name == "facture" && (role === "1" || role === "2") && (
                <>
                  <div className="col-md-6 mb-3">
                    <Collapse size="large">
                      <Panel header={item.value} key="1">
                        {item?.children?.map((itemC) => {
                          return (
                            <>
                              {itemC.key == "rib" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">

                                    <TextArea
                                      rows={2}
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeRibFacture(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {itemC.key == "address" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <TextArea
                                      rows={2}
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeAdressFacture(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                        <Button
                          type="primary"
                          onClick={() => updateFacture(item?.id, item?.value)}
                        >
                          Save
                        </Button>
                      </Panel>
                    </Collapse>
                  </div>
                </>
              )}
              {item?.name == "paiement" && (role === "1" || role === "2") && (
                <>
                  <div className="col-md-6 mb-3">
                    <Collapse size="large">
                      <Panel header={item.value} key="1">
                      {item?.children?.map((itemC) => {
                          return (
                            <>
                              {itemC.key == "deposit" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeAcomptePay(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {itemC.key == "stock_alert_default" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="number"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeStockLow(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {itemC.key == "reseller_discount" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeRemiseRPay(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {itemC.key == "manufacturer_discount" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeRemiseFPay(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {itemC.key == "distributor_discount" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeRemiseDPay(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {itemC.key == "stripe_public_key" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeStripePPay(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {itemC.key == "stripe_secret_key" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeStripeSPay(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {itemC.key == "due_date" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="text"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeDueDatePay(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                        <Button
                          type="primary"
                          onClick={() => updatePay(item?.id, item?.value)}
                        >
                          Save
                        </Button>
                      </Panel>
                    </Collapse>
                  </div>
                </>
              )}
              {item?.name == "paiement" && (role === "4") && (
                <>
                  <div className="col-md-6 mb-3">
                    <Collapse size="large">
                      <Panel header="Stock" key="1">
                      {item?.children?.map((itemC) => {
                          return (
                            <>
                              {itemC.key == "stock_alert_default" && (
                                <div className="row mb-2">
                                  <div className="col-md-3">
                                    <p className="mt-1">{itemC.label}:</p>
                                  </div>
                                  <div className="col-md-9">
                                    <Input
                                      type="number"
                                      defaultValue={itemC?.value}
                                      onChange={(e) =>
                                        changeStockLow(
                                          itemC.label,
                                          itemC.key,
                                          itemC.type,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                        <Button
                          type="primary"
                          onClick={() => updatePay(item?.id, item?.value)}
                        >
                          Save
                        </Button>
                      </Panel>
                    </Collapse>
                  </div>
                </>
              )}
            </>
          );
        })}
        {/* {
          (role === "1" || role === "2") &&(
            <>
                <div className="col-md-6 mb-3">
                    <Collapse size="large">
                        <Panel header="Recipient" key="0">
                            <div className="row mb-2">
                                <div className="col-md-3">
                                    <p className='mt-1'>Recipient 1:</p>
                                </div>
                                <div className="col-md-9">
                                    <Input type='text' placeholder='example@gmail.com'/>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3">
                                    <p className='mt-1'>Recipient 2:</p>
                                </div>
                                <div className="col-md-9">
                                    <Input type='text' placeholder='example@gmail.com'/>
                                </div>
                            </div>
                            <Button type="primary" >Save</Button>
                        </Panel>
                    </Collapse>
                </div>
                <div className="col-md-6 mb-3">
            <Collapse size="large">
                <Panel header="Sites" key="1">
                    <div className="row mb-2">
                        <div className="col-md-5">
                            <Input type='text' placeholder='key' />
                        </div>
                        <div className="col-md-5">
                            <Input type='text' placeholder='value'/>
                        </div>
                        <div className="col-md-2">
                            <Button type="primary" icon={<PlusOutlined />} size="middle" style={{float:'right'}} />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-5">
                            <Input type='text' disabled={true} />
                        </div>
                        <div className="col-md-5">
                            <Input type='text' disabled={true} />
                        </div>
                        <div className="col-md-2">
                            {
                                1==1 &&(
                                    <Button type="primary" icon={<CheckOutlined />} size="middle" style={{float:'left'}} />
                                )
                            }
                            {
                                1==2 &&(
                                    <Button type="primary" icon={<EditOutlined />} size="middle" style={{float:'left'}} />
                                )
                            }
                            <Button danger type="primary" icon={<DeleteOutlined />} size="middle" style={{float:'right'}} />
                        </div>
                    </div>
                </Panel>
            </Collapse>
          </div>
          <div className="col-md-6 mb-3">
            <Collapse size="large">
                <Panel header="Facture" key="1">
                    <p>Hahaha</p>
                </Panel>
            </Collapse>
          </div>
          <div className="col-md-6 mb-3">
            <Collapse size="large">
                <Panel header="Paiement" key="1">
                    <p>Hahaha</p>
                </Panel>
            </Collapse>
          </div>
          <div className="col-md-6 mb-3">
            <Collapse size="large">
                <Panel header="Email notification" key="1">
                    <p>Hahaha</p>
                </Panel>
            </Collapse>
          </div>
            </>
          )
          
        } */}
        {/* {
          (role === "4") &&(
            <>
                <div className="col-md-6 mb-3">
                    <Collapse size="large">
                        <Panel header="Recipient" key="0">
                            <div className="row mb-2">
                                <div className="col-md-3">
                                    <p className='mt-1'>Recipient 1:</p>
                                </div>
                                <div className="col-md-9">
                                    <Input type='text' placeholder='example@gmail.com'/>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3">
                                    <p className='mt-1'>Recipient 2:</p>
                                </div>
                                <div className="col-md-9">
                                    <Input type='text' placeholder='example@gmail.com'/>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3">
                                    <p className='mt-1'>Recipient 3:</p>
                                </div>
                                <div className="col-md-9">
                                    <Input type='text' placeholder='example@gmail.com'/>
                                </div>
                            </div>
                            <Button type="primary" >Save</Button>
                        </Panel>
                    </Collapse>
                </div>
            </>
          )
          
        } */}
      </div>
    </Card>
  );
};

export default withTranslation()(Parameters);
