import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Form, Input } from 'antd';
import axios from 'axios';
import '../../assets/css/login.css';
import BASE_URL from '../../config';
import { Utils } from '../../services/Utils.js';
import { withTranslation  } from 'react-i18next';

class FormLogin extends Component {
    state = {
        email: "",
        password: "",
        isAuth: false,
        msg_ko:"",
        isLoading: false
      };
    constructor (props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }
    componentDidMount () {
        // console.log(this.props);
        if(this.props.isAuth === true){
            this.props.history.push("/dashboard");
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.isAuth === true) {
            this.props.history.push("/dashboard");
        }
    }

    handleInputChange = e => {
        this.setState({
          [e.target.name]: e.target.value,
          msg_ko:""
        });
      };
      handleReset = () => {
        this.setState({
            email: "",
            password: ""
        });
      };
    handleSubmit = (values) => {
        this.setState({
            isLoading: true
        })
    const { email, password } = values;
        axios.post(`${BASE_URL}/api/v1/login`, { email, password })
        .then(response => {
            this.setState({
                isLoading: false
            });

            if(response.data.success){
                this.props.login()
                .then((data) => { 
                    this.setState({
                        isLoading: false
                    })
                    let DATA_USER = {};
                    DATA_USER = response.data.data;
                    
                    localStorage.setItem('DATA_USER', JSON.stringify(DATA_USER));
                    localStorage.setItem('label', response.data.data.role_id);
                    localStorage.setItem('token', response.data.token);
                    this.props.history.push("/dashboard");
                });
            }
            
        })
        .catch(error => {
            this.setState({
                isLoading: false
            })
            if(error.response.data.message === "These credentials do not match our records."){
                let type = 'error'
                let mess = error.response.data.message;
                Utils.notif(type, mess);
            }
        });
      };
    render() {
      return (
        <section className="main">
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-md-5 col-10">
                <h1 className="text-center my-3">Login</h1>
                {/* <p className="mb-0 text-center msg_ko">{this.state.msg_ko}</p> */}
                 <Form
                    name="basic"
                    labelCol={{
                    span: 8,
                    }}
                    wrapperCol={{
                    span: 16,
                    }}
                    style={{
                    maxWidth: 600,
                    }}
                    initialValues={{
                    remember: true,
                    }}
                    onFinish={this.handleSubmit}
                    onFinishFailed={null}
                    autoComplete="off"
                >
                    <Form.Item
                    label={this.props.t('email')}
                    name="email"
                    rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid Email!',
                        },
                        {
                          required: true,
                          message: 'Please input your Email!',
                        },
                    ]}
                    >
                    <Input/>
                    </Form.Item>

                    <Form.Item
                    label={this.props.t('password')}
                    name="password"
                    rules={[
                        {
                        required: true,
                        message: 'Please input your password!',
                        },
                    ]}
                    >
                    <Input.Password/>
                    </Form.Item>

                    <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                    >
                    <Checkbox>{this.props.t('remember_me')}</Checkbox>
                    </Form.Item>

                    <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                    >
                    <Button type="primary" htmlType="submit" loading={this.state.isLoading}>
                        {this.props.t('btn_login')}
                    </Button>
                    </Form.Item>
                </Form>
                <hr className="w-100" />
                <div className="d-flex justify-content-around py-3">
                    <Link to="/forgot_password">{this.props.t('forgot_password')}</Link>
                        {/* <span> | </span>
                    <Link to="/register">{this.props.t('signup')}</Link> */}
                </div>
                </div>
                </div>
            </div>
        </section>
        );
    }
}

export default withTranslation()(FormLogin);
