import React, { useEffect, useState, useRef  } from 'react';
import {
    Button,
    Input,
    Checkbox
} from "antd";
import { DeleteOutlined, PrinterOutlined } from "@ant-design/icons";
import { Utils } from "../../services/Utils";
import "../../assets/css/editCommande.scss";
import ReactToPrint from "react-to-print";

const DetailCommande = (props) => {
    let componentRef = useRef();
    const [footer, setFooter] = useState({
        totalPrice: 0,
        remise: 0,
        totalPay: 0,
        acompte: 0,
        leftPay: 0,
        quantity: 0,
    });

    useEffect(() => {
        getFooter(props?.order);
    }, [props?.order]);

    const getFooter = (commande) => {
        const res = {
          totalPrice: 0,
          remise: 0,
          totalPay: 0,
          acompte: 0,
          leftPay: 0,
          quantity: 0,
        };
        commande.command_lines.map((item) => {
            res.quantity += item.quantity;
            item.stocks.map((itemS) => {
                itemS.childs.map((itemC) => {
                itemC.variants.map((itemV) => {
                    res.totalPrice =
                    parseFloat(res.totalPrice) + parseFloat(itemV?.price);
                });
                });
            });
        });
    
        res.remise = (res.totalPrice * parseFloat(commande.remise)) / 100;
        res.totalPay = parseFloat(res.totalPrice) - parseFloat(res.remise);
        res.acompte =
          (parseFloat(res.totalPay) * parseFloat(commande.acompte_percent)) / 100;
        res.leftPay = res.totalPay - res.acompte;
        console.log("res::: ", res);
        setFooter(res);
    };

    const formatDate = () => {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
    
        return [year, month, day].join("-");
    }

    const Print = (e, commande_id) =>{     
        //console.log('print');  
        e.preventDefault();
        let printContents = document.getElementById(`printablediv_${commande_id}`)?.innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;

    }
    return (
        <div id={`divcontents_${props?.order?.id}`} key={`key_${props?.order?.id}`} >
            <div id={`printablediv_${props?.order?.id}`} ref={(el) => (componentRef = el)}>
                <div className="row">
                    <div className="col-print-5 col-md-5">
                    {
                        props.pageCurrent !== 'cart' && (
                            <p>
                                <span className="title-label font-weight-bold">N° order : </span>
                                CM{props.order?.number}
                            </p>
                        )
                    }
                    <p>
                        <span className="title-label font-weight-bold">Date of order :</span>{" "}
                        {props.order?.date_commande}{" "}
                    </p>
                    <p>
                        <span className="title-label font-weight-bold">Sale's rep. :</span>{" "}
                        {props.order?.command_owner?.name}
                    </p>
                    <p>
                        <span className="title-label font-weight-bold">Contact :</span>{" "}
                        {props.order?.command_owner?.email}
                    </p>
                    </div>
                    <div className="col-print-4 col-md-4"></div>
                    <div className="col-print-3 col-md-3 text-center">
                    {props.role !== "4" && (
                        <p className="p-1 mb-1 border bg-white text-dark">Stock YS</p>
                    )}
                    <p
                        className="p-1 mb-1 border text-dark"
                        style={{ backgroundColor: "#fff7e3" }}
                    >
                        Standard production lead time
                    </p>
                    <p
                        className="p-1 mb-1 border text-dark"
                        style={{ backgroundColor: "rgb(227 255 242)" }}
                    >
                        Express production lead time
                    </p>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                        <th
                            scope="col"
                            style={{ textAlign: "center", verticalAlign: "middle" }}
                        >
                            Product name
                        </th>
                        <th
                            scope="col"
                            style={{ textAlign: "center", verticalAlign: "middle" }}
                        >
                            Color
                        </th>
                        <th
                            scope="col"
                            style={{ textAlign: "center", verticalAlign: "middle" }}
                        >
                            YS REF
                        </th>
                        <th
                            scope="col"
                            style={{ textAlign: "center", verticalAlign: "middle" }}
                        >
                            Size
                        </th>
                        <th
                            scope="col"
                            style={{ textAlign: "center", verticalAlign: "middle" }}
                        >
                            {
                                (props.role == "3") ? (
                                    "Estimated Day of Shipping"
                                ) : (
                                    "Product availability"
                                )
                            }
                        </th>
                        <th
                            scope="col"
                            style={{ textAlign: "center", verticalAlign: "middle" }}
                        >
                            Quantity ordered
                        </th>
                        {(props.role === "1" || props.role === "2") &&
                            props.show == false &&
                            props.orderId == 1 && (
                            <>
                                <th
                                scope="col"
                                style={{ textAlign: "center", verticalAlign: "middle" }}
                                >
                                Quantity in stock
                                </th>
                                <th
                                scope="col"
                                style={{ textAlign: "center", verticalAlign: "middle" }}
                                >
                                Available quantity
                                </th>
                            </>
                            )}
                        {props.role === "4" && props.orderId === 8 ? (
                            <th
                            scope="col"
                            style={{ textAlign: "center", verticalAlign: "middle" }}
                            >
                            Price
                            </th>
                        ) : (
                            <th
                            scope="col"
                            style={{ textAlign: "center", verticalAlign: "middle" }}
                            >
                            Purchase 
                            </th>
                        )}

                        {props.role === "4" && props.orderId === 8 && (
                            <th
                            scope="col"
                            style={{ textAlign: "center", verticalAlign: "middle" }}
                            >
                            Unit extra cost
                            </th>
                        )}

                        <th
                            scope="col"
                            style={{ textAlign: "center", verticalAlign: "middle" }}
                        >
                            Price
                        </th>

                        {props.show == false &&
                            (props.orderId == 9 ||
                            props.orderId == 1 ||
                            props.orderId == 2 ||
                            props.orderId == 5 ||
                            (props.orderId == 8 && props.role === "4")) && (
                            <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                                {/* { */}
                                {/* ((props.role !== "4" && props.orderId !== 8) || (props.role !== "3" && props.orderId !== 9)) &&( */}
                                <Checkbox
                                checked
                                ></Checkbox>
                                {/* ) */}
                                {/* } */}
                            </th>
                            )}

                        {
                            props.order?.in_production == 1 && (
                            <>
                                <th
                                scope="col"
                                style={{ textAlign: "center", verticalAlign: "middle" }}
                                >
                                Shipping from Factory
                                </th>
                                <th
                                scope="col"
                                style={{ textAlign: "center", verticalAlign: "middle" }}
                                >
                                Shipping from YS
                                </th>
                            </>
                            )
                        }
                            
                        </tr>
                    </thead>
                    <tbody>
                        {props.order?.command_lines?.map((item) => {
                        var row = 0;
                        item.stocks.map((itemT) => {
                            itemT.childs.map((chil) => {
                            row += chil.variants.length;
                            });
                        });
                        var test = true;
                        var istest = false;

                        const ord = { name: item.catalogue_name };
                        return (
                            <>
                            {item.stocks.map((itemT, t) => {
                                var rowV = 0;
                                itemT.childs.map((chil) => {
                                rowV += chil.variants.length;
                                });
                                var testT = true;
                                var istestT = false;
                                if (t > 0) {
                                test = false;
                                }
                                const col = { ...ord, color: itemT.color };
                                let index = 0;
                                return (
                                <>
                                    {itemT.childs.map((itemV, v) => {
                                    index = index + 1;
                                    var testV = true;
                                    if (v > 0) {
                                        test = false;
                                        testT = false;
                                    }
                                    const siz = { ...col, size: itemV.size };
                                    itemV?.variants.sort(function (a, b) {
                                        return parseInt(a.id) - parseInt(b.id);
                                    });
                                    return (
                                        <>
                                        {itemV?.variants.map((itemVV, vv) => {
                                            // console.log(itemVV)

                                            let rowVV = itemV?.variants.length;
                                            let isCheck = false;
                                            if (
                                            itemVV.is_accelered == "1" &&
                                            itemV?.variants.length > 0
                                            ) {
                                            if (
                                                (itemV?.variants[vv - 1]?.is_express ==
                                                "1" &&
                                                itemV?.variants[vv - 1]?.ready == true) ||
                                                (itemV?.variants[vv + 1]?.is_accelered ==
                                                "1" &&
                                                itemV?.variants[vv + 1]?.ready == true)
                                            ) {
                                                isCheck = true;
                                            } else {
                                                isCheck = false;
                                            }
                                            }
                                            const disp = {
                                            ...siz,
                                            ref: itemVV.reference_ys,
                                            size: itemV.size,
                                            type: itemVV.type,
                                            quantite: itemVV.quantity,
                                            unit_price: itemVV.unit_price,
                                            price: itemVV.price,
                                            real_quantity: itemVV.real_quantity,
                                            ready: itemVV.ready ? itemVV.ready : false,
                                            status: parseInt(itemVV.status_id),
                                            date_disponibility: itemVV.date_disponibility,
                                            is_valid: itemVV.is_valid,
                                            is_accelered: itemVV.is_accelered,
                                            is_express: itemVV.is_express,
                                            is_check: isCheck,
                                            chipping_from_factory: itemVV.chipping_from_factory,
                                            chipping_from_ys: itemVV.chipping_from_ys,
                                            id_variante: itemVV.id

                                            };
                                            // console.log(disp)
                                            var colorSS = "white";
                                            var classColors = "white_print";
                                            if (
                                            disp.type !== "in_stock" ||
                                            (disp.is_accelered == "0" &&
                                                disp.is_express == "1")
                                            ) {
                                                colorSS = "#fff7e3";
                                                classColors = "yellow_print";
                                            }
                                            if (
                                            disp.is_accelered == "1" &&
                                            disp.is_express == "1"
                                            ) {
                                                colorSS = "rgb(227 255 242)";
                                                classColors = "green_print";
                                            }

                                            if (vv > 0) {
                                            test = false;
                                            testT = false;
                                            testV = false;
                                            }
                                            // if(t>0 || v>0 || vv>0){
                                            //     istest = true;
                                            // }
                                            // if(t>0 || v>0){
                                            //     istestT = true;
                                            // }
                                            if (
                                            itemVV?.is_express == "1" &&
                                            itemVV?.is_accelered == "0"
                                            ) {
                                            } else if (
                                            itemVV?.is_express == "1" &&
                                            itemVV?.is_accelered == "0"
                                            ) {
                                            }
                                            return (
                                            <>
                                                <tr>
                                                {(test == true ||
                                                    (item?.stocks[t - 1]?.childs[v]
                                                    ?.variants.length == 0 &&
                                                    vv == 0) ||
                                                    (item?.stocks[t]?.childs[v - 1]
                                                    ?.variants.length == 0 &&
                                                    vv == 0)) && (
                                                    <td
                                                    style={{
                                                        textAlign: "center",
                                                        verticalAlign: "middle",
                                                    }}
                                                    rowSpan={row}
                                                    >
                                                    <span style={{ marginLeft: "10px" }}>
                                                        {disp.name}
                                                    </span>
                                                    </td>
                                                )}
                                                {(testT == true ||
                                                    (item?.stocks[t - 1]?.childs[v]
                                                    ?.variants.length == 0 &&
                                                    vv == 0) ||
                                                    (item?.stocks[t]?.childs[v - 1]
                                                    ?.variants.length == 0 &&
                                                    vv == 0)) && (
                                                    <td
                                                    style={{
                                                        textAlign: "center",
                                                        verticalAlign: "middle",
                                                    }}
                                                    rowSpan={rowV}
                                                    >
                                                    {disp.color}
                                                    </td>
                                                )}
                                                {test == true && testT == true ? (
                                                    <></>
                                                ) : (
                                                    <></>
                                                )}
                                                {testV == true && (
                                                    <>
                                                        <td
                                                            style={{
                                                                textAlign: "center",
                                                                verticalAlign: "middle",
                                                            }}
                                                            rowSpan={rowVV}
                                                            >
                                                            {disp.ref}
                                                            {/* {
                                                                item?.stocks[t - 1]?.childs[v]
                                                                ?.variants.length
                                                            } */}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: "center",
                                                                verticalAlign: "middle",
                                                            }}
                                                            rowSpan={rowVV}
                                                            >
                                                            {disp.size}{" "}
                                                            {/* {
                                                                item?.stocks[t - 1]?.childs[v]
                                                                ?.variants.length
                                                            } */}
                                                        </td>
                                                    </>
                                                )}
                                                {props.role === "4" ? (
                                                    <td
                                                    style={{
                                                        textAlign: "center",
                                                        width: "150px",
                                                        verticalAlign: "middle",
                                                        
                                                    }}
                                                    className='p-0'
                                                    >
                                                        <div className={`${classColors} w-100 h-100 p-2`}>
                                                            {disp.status == "8" ? (
                                                                <Input
                                                                type="date"
                                                                />
                                                            ) : (
                                                                <>{disp.date_disponibility}</>
                                                            )}
                                                        </div>
                                                    </td>
                                                ) : (
                                                    <td
                                                    style={{
                                                        textAlign: "center",
                                                        verticalAlign: "middle",
                                                        
                                                    }}
                                                    className='p-0'
                                                    >
                                                        <div className={`${classColors} w-100 h-100 p-2`}>
                                                            {disp.type == "in_stock" &&
                                                            disp.is_express === "0"
                                                                ? formatDate()
                                                                : disp.date_disponibility}
                                                        </div>
                                                    </td>
                                                )}

                                                <td
                                                    style={{
                                                    textAlign: "right",
                                                    verticalAlign: "middle",
                                                    
                                                    }}
                                                    className='p-0'
                                                >
                                                    <div className={`${classColors} w-100 h-100 p-2`}>
                                                        {disp.quantite}

                                                    </div>
                                                </td>
                                                {(props.role === "1" || props.role === "2") &&
                                                    props.show == false &&
                                                    props.orderId == 1 && (
                                                    <>
                                                        <td
                                                        style={{
                                                            textAlign: "right",
                                                            verticalAlign: "middle",
                                                            
                                                        }}
                                                        className='p-0'
                                                        >
                                                            <div className={`${classColors} w-100 h-100 p-2`}>
                                                                {disp.real_quantity}

                                                            </div>
                                                        </td>
                                                        <td
                                                        style={{
                                                            width: "115px",
                                                            
                                                        }}
                                                        className='p-0'
                                                        >
                                                            <div className={`${classColors} w-100 h-100 p-2`}>
                                                                <Input
                                                                    type="number"
                                                                />

                                                            </div>
                                                        </td>
                                                    </>
                                                    )}
                                                <td
                                                    style={{
                                                    textAlign: "right",
                                                    verticalAlign: "middle",
                                                    
                                                    }}
                                                    className='p-0'
                                                >
                                                    <div className={`${classColors} w-100 h-100 p-2`}>
                                                        {Utils.formatMoney(
                                                        parseFloat(disp.unit_price)
                                                        )}

                                                    </div>
                                                </td>
                                                {props.role === "4" && props.orderId === 8 && (
                                                    <td
                                                    style={{
                                                        textAlign: "right",
                                                        width: "100px",
                                                        verticalAlign: "middle",
                                                        
                                                    }}
                                                    className='p-0'
                                                    >
                                                        <div className={`${classColors} w-100 h-100 p-2`}>
                                                            {disp.is_accelered == "1" ? (
                                                                <Input
                                                                type="number"
                                                                />
                                                            ) : (
                                                                <></>
                                                            )}

                                                        </div>
                                                    </td>
                                                )}

                                                <td
                                                    style={{
                                                    textAlign: "right",
                                                    verticalAlign: "middle",
                                                    
                                                    }}
                                                    className='p-0'
                                                >
                                                    <div className={`${classColors} w-100 h-100 p-2`}>
                                                        {Utils.formatMoney(
                                                        parseFloat(disp.price)
                                                        )}

                                                    </div>
                                                </td>

                                                {props.show == false &&
                                                    (props.orderId == 9 ||
                                                    props.orderId == 1 ||
                                                    props.orderId == 2 ||
                                                    props.orderId == 5 ||
                                                    (props.orderId == 8 && props.role === "4")) && (
                                                    <>
                                                        <td
                                                        style={{
                                                            width: "115px",
                                                            verticalAlign: "middle",
                                                            textAlign: "center",
                                                            
                                                        }}
                                                        className='p-0'
                                                        >
                                                            <div className={`${classColors} w-100 h-100 p-2`}>
                                                                {disp.ready == true && (
                                                                    <Checkbox
                                                                    checked={true}
                                                                    ></Checkbox>
                                                                )}
                                                                {disp.ready == false && (
                                                                    <Checkbox
                                                                    checked={false}
                                                                    ></Checkbox>
                                                                )}

                                                            </div>
                                                        </td>
                                                        {props.role !== "4" && (
                                                        <td
                                                            style={{
                                                            width: "115px",
                                                            verticalAlign: "middle",
                                                            textAlign: "center",
                                                            
                                                            }}
                                                            className='p-0'
                                                        >
                                                            <div className={`${classColors} w-100 h-100 p-2`}>
                                                                <Button
                                                                type="danger"
                                                                shape="circle"
                                                                icon={<DeleteOutlined />}
                                                                ></Button>

                                                            </div>
                                                        </td>
                                                        )}
                                                    </>
                                                    )}
                                                    {
                                                    props.order?.in_production == 1 && (
                                                        <>
                                                        <td style={{ textAlign: "center"}} className='p-0'>
                                                        <div className={`${classColors} w-100 h-100 p-2`}>
                                                            {
                                                            disp?.chipping_from_factory !== null ? (
                                                                <a href={disp?.chipping_from_factory} target="_blank" rel="noreferrer">
                                                                {disp?.chipping_from_factory}
                                                                </a>
                                                            ) : (
                                                                <Input type="text" defaultValue={disp?.chipping_from_factory}
                                                                disabled={props.role === "3" || props.role === "1" || props.role === "2" || disp?.chipping_from_factory !== null}
                                                                placeholder="Tracking number"
                                                                />
                                                            )
                                                            }

                                                        </div>
                                                        </td>
                                                        <td style={{ textAlign: "center"}} className='p-0'>
                                                            <div className={`${classColors} w-100 h-100 p-2`}>
                                                                {
                                                                disp?.chipping_from_ys !== null ? (
                                                                    <a href={disp?.chipping_from_ys} target="_blank" rel="noreferrer">
                                                                    {disp?.chipping_from_ys}
                                                                    </a>
                                                                ) : (
                                                                    <Input type="text" defaultValue={disp?.chipping_from_ys}
                                                                    disabled={props.role === "3" || props.role === "1" || props.role === "2" || disp?.chipping_from_ys !== null}
                                                                    placeholder="Tracking number"
                                                                    />
                                                                )
                                                                }

                                                            </div>
                                                        </td>
                                                        </>
                                                    )
                                                    }
                                                </tr>
                                            </>
                                            );
                                        })}
                                        </>
                                    );
                                    })}
                                </>
                                );
                            })}
                            </>
                        );
                        })}
                    </tbody>
                </table>
                <div className="row">
                    <div className="col-print-5 col-md-5">
                        <p>
                            <span className="title-label font-weight-bold">Global status : </span>{" "}
                            {props?.statusLabel}
                        </p>
                        {
                            props.order?.in_production == "1" && props.orderId === 4 && (
                                <>
                                    <Button
                                        type="primary"
                                        disabled
                                        className="btn-shadow"
                                    >
                                        In production
                                    </Button>
                                </>
                            )
                        }
                        {(props.role != "3" && props.order?.date_versement_accompte !== null) && (
                            <p className="mt-3"><b>Date of deposit : {props.order?.date_versement_accompte}</b> </p>
                        )}
                        {
                            props.order?.date_in_production !== null &&
                            <p className="mt-3"><b>Production started date : {props.order?.date_in_production}</b> </p>
                        }
                    </div>
                    <div className="col-print-3 col-md-3"></div>
                    <div className="col-print-4 col-md-4 text-right">
                    {props.role !== "4" && (
                        <>
                            <p>
                                <span className="title-label">Total quantity :</span>{" "}
                                {footer.quantity}{" "}
                            </p>
                            <p>
                                <span className="title-label">Total price :</span>{" "}
                                {Utils.formatMoney(parseFloat(footer.totalPrice))}{" "}
                            </p>
                            <p>
                                <span className="title-label">Total to pay :</span>{" "}
                                {Utils.formatMoney(parseFloat(footer.totalPay))}{" "}
                            </p>
                            {/* <p>
                                <span className="title-label">Deposit :</span>{" "}
                                {Utils.formatMoney(parseFloat(footer.acompte))}{" "}
                            </p>
                            <p>
                                <span className="title-label">Balance payment :</span>{" "}
                                {Utils.formatMoney(parseFloat(footer.leftPay))}{" "}
                            </p> */}
                        </>
                    )}
                    </div>
                </div>
            </div>
            <div className='text-center'>
                <ReactToPrint
                    trigger={() => <Button type="primary" icon={<PrinterOutlined />}>Print</Button>}
                    content={() => componentRef}
                />
            </div>
        </div>
    );
}

export default DetailCommande;