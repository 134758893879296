import React, { useEffect } from 'react';
import { withTranslation  } from 'react-i18next';
import { Button, Card, Table, Typography } from 'antd';
import axios from "axios";
import BASE_URL from "../../../config.js";
import CORS_URL from "../../../config_cors.js";
import { LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Utils } from '../../../services/Utils.js';
const { Title } = Typography;

const PaymentList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [payement, setPayement] = useState(false);
    useEffect(() => {
       getPayement();
    }, []);
    const getPayement = () => {
        const Authorization = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Authorization,
        };
        axios
          .get(`${BASE_URL}/api/v1/payment/list`, { headers })
          .then((res) => {
            const payementD = res.data?.data;
            console.log(payementD)
            setPayement(payementD);
          })
          .catch((error) => {
            console.log(error);
          });
    };

    const facturer = (id) => {
      console.log(id);
      const Authorization = localStorage.getItem("token");
      const headers = {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + Authorization
      };
        axios
        // .get(`${CORS_URL}/${BASE_URL}/api/v1/payment/export-pdf/${id}`, { headers:headers,responseType: 'blob' })
        .get(`${BASE_URL}/api/v1/payment/export-pdf/${id}`, { headers:headers,responseType: 'blob' })
        .then((res) => {
          // Create a Blob from the response data
            const file = new Blob([res.data], { type: 'application/pdf' });

            // Create a temporary URL for the file
            const fileURL = URL.createObjectURL(file);

            // Trigger the file download
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();

            // Cleanup the temporary URL
            URL.revokeObjectURL(fileURL);
        })
        .catch((error) => {
          console.log(error);
        });

        // axios({
        //   url:
        //   `${BASE_URL}/api/v1/payment/list`,
        //   method: "GET",
        //   responseType: "blob",
        //   auth:"Bearer " + Authorization
        // }).then(response => {
        //   console.log(response);
        //   const url = window.URL.createObjectURL(new Blob([response.data]));
        //   const link = document.createElement("a");
        //   link.href = url;
        //   link.setAttribute("download", "file.pdf");
        //   document.body.appendChild(link);
        //   link.click();
        // });
    };

    const columns = [
      {
        title: props.t("N° ORDER"),
        dataIndex: "number",
        key: "number",
        align:'center',
        render: (text) => {
          return (
            <p>
              {text}
            </p>
          );
        },
      },
      {
        title: props.t("SALE'S REP."),
        dataIndex: "owner_name",
        key: "owner_name",
        align:'center',
        render: (text) => {
          return (
            <p>
              {text}
            </p>
          );
        },
      },
      {
        title: props.t("TOTAL ORDER AMOUNT"),
        dataIndex: "prix_total",
        key: "prix_total",
        align:'right',
        render: (text) => {
          return (
            <p>
              {Utils.formatMoney(Utils.formatMoney(parseFloat(text)))}
            </p>
          );
        },
      },
      {
        title: props.t("DEPOSIT AMOUNT"),
        dataIndex: "acompte",
        key: "acompte",
        align:'right',
        render: (text) => {
          return (
            <p>
              {Utils.formatMoney(Utils.formatMoney(parseFloat(text)))}
            </p>
          );
        },
      },
      {
        title: props.t("STATUS"),
        dataIndex: "status",
        key: "status",
        align:'right',
        render: (text) => {
          return (
            <b className={text.label === "Paid" ? "text-success" : "text-dark"}>
              {text.label}
            </b>
          );
        },
      },
      {
        title: props.t("DATE OF PAYMENT"),
        dataIndex: "date_paiement",
        key: "date_paiement",
        align:'right',
        render: (text) => {
          return (
            <p>
              {text.split(" ")[0]}
            </p>
          );
        },
      },
      {
        title: props.t("DEPOSIT RECEIPT"),
        dataIndex: "id",
        key: "id",
        align:'center',
        render: (text) => {
          return (
            <p>
              <Button onClick={()=>facturer(text)}>Download deposit receipt</Button>
            </p>
          );
        },
      }
    ];

    return (
        <Card title={<Title level={3}>{props.t('List of payments')}</Title>}>
          <div style={{ overflowX: 'auto' }}>
            <Table
                columns={columns}
                dataSource={payement}
                rowKey="id"
                pagination={{ hideOnSinglePage: true, pageSize: 15 }}
                loading={{indicator: <LoadingOutlined />, spinning: isLoading}}
            />
          </div>
        </Card>
    );
}

export default withTranslation()(PaymentList)