import React, { useEffect, useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import BASE_URL from "../../../config";
import axios from "axios";
import { Utils } from "../../../services/Utils";
const PaymentForm = (props) => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [success, setSuccess] = useState(false);
  const [token, setToken] = useState(false);
  useEffect(() => {
    getToken();
 }, []);

  const getToken = () => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .get(`${BASE_URL}/api/v1/parameter/show/3?parameter_value_id=12`, { headers })
      .then((res) => {
        const payementD = res.data?.data;
        setToken(payementD.value)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleToken = async (token) => {
    setIsLoadingData(true);
    token.command_id = props.id;
    const Authorization = localStorage.getItem("token");
    // const response = axios.post(`${BASE_URL}/api/v1/parameter/edit/${id}`, JSON.stringify(token), { headers });
    const response = await fetch(`${BASE_URL}/api/v1/payment/charge`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + Authorization,
      },
      body: JSON.stringify(token),
    });
    const data = await response.json();
    console.log(data);
    if (data.success === true) {
      setIsLoadingData(false);
      setSuccess(true);
      props.setStatusE(10);
      let type = 'success';
      Utils.notif(type, "Payment Successful!");
    }
  };
  return (
    <>
      {
        isLoadingData ?(
          <div style={{padding:'10px',textAlign:'center',verticalAlign:'middle'}}>
            <p style={{color:'orange'}}>Payment in progress...</p>
          </div>
        ):(
          <>
            {
              !success ? (
                <StripeCheckout
                  label="Pay by credit card"
                  stripeKey={token}
                  token={handleToken}
                  amount={props.total*100}
                  name="YACHTING SPIRIT"
                  description={`PO : ${props?.commandeNumber}`}
                  currency="EUR"
                />
              ) : (
                <div style={{padding:'10px',textAlign:'center',verticalAlign:'middle'}}>
                  <p style={{color:'blue'}}>Payment Successful!</p>
                </div>
              )
            }
          </>
        )
      }
      
    </>
  );
};
export default PaymentForm;
