export const addPanier = (productInformation) => {
  return {
      type: "ADD",
      payload: productInformation
  }
}

export const changePanier = (productInformation) => {
  return {
      type: "CHANGE",
      payload: productInformation
  }
}

export const deteleOne = (productInformation) => {
  return {
      type: "DELETE ONE",
      payload: productInformation
  }
}

export const deletePanier = () => {
  return {
      type: "DELETE"
  }
}