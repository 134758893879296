import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Typography } from 'antd';
import { connect } from 'react-redux';
import * as InqueryActions from "../../my_redux/actions/inquery";
import confirm from 'antd/lib/modal/confirm';
import axios from "axios";
import BASE_URL from '../../config';
import { Utils } from '../../services/Utils';

const ListChooseProductInquerry = (props) => {
  const [expandedRow, setExpandedRow] = React.useState(['0']);
  const removeInquery = (productId) => {
    props.addInquery(props.inquery.filter(iq => iq.product.id !== productId));
  }
  const removeCommand = (key, variant) => {
    const inquery = props.inquery;
    // const comd = inquery[key].commande.find(com => com.variant.id === variant);
    // let avCons = 0;
    // props.setQuantitiesRedux({
    //   ...props.quantities,
    //   colors: props.quantities.colors.map(c => {
    //     if(c.color === comd.label) {
    //       avCons = parseFloat(c.avConso);
    //       return {...c, quantity: c.quantity + (parseInt(comd.in) * parseFloat(c.avConso))}
    //     }
    //     return c;
    //   }),
    //   quantity: props.quantities.quantity + (parseInt(comd.in) * avCons)
    // })
    // console.log("ATO NDRAY ZAO", inquery[key].commande.find(com => com.variant.id === variant), props.quantities)
    inquery[key].commande = inquery[key].commande.filter(com => com.variant.id !== variant);
    if(inquery[key].commande.length === 0) {
      props.addInquery([]);
    }
    if(inquery[key].commande.length !== 0) {
      props.addInquery(inquery);
    }
    setExpandedRow(['0']);
  }
  const ExpandedRowRender = (props) => {
    const columns = [
      {
        title: 'Color',
        dataIndex: ['color', 'label'],
      },
      {
        title: 'Size',
        dataIndex: ['variant', 'value'],
      },
      {
        title: 'Quantity',
        dataIndex: 'in',
      },
      {
        title: '',
        key: 'operation',
        render: (row, index) => <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => removeCommand(props.record.key, row.variant.id)} />,
      },
    ];

    return <Table columns={columns} dataSource={props.record.commande} pagination={false} />;
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: ['product', 'name']
    },
    {
      title: 'Range',
      dataIndex: ['product', 'range'],
    },
    {
      title: 'Reference',
      dataIndex: ['product', 'reference'],
    },
    {
      title: '',
      key: 'operation',
      render: (row) => <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => removeInquery(row.product.id)} />,
    },
  ];

  const ajoutPanier = () => {
    if(props.inquery?.length !== 0) {
      const cart = [...props.inquery];
      delete cart.key;
      const Authorization = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Authorization,
      };
      axios.post(`${BASE_URL}/api/v1/inquiry/add`, { cart: cart }, { headers })
        .then((response) => {
          const res = response.data.data;
          if (response.data.success) {
            confirm({
              title: 'Resultat in querry:',
              okText: 'Confirm in querry',
              content:
                <div style={{ textAlign: 'left' }}>
                  <ul>
                    {
                      res.map((item) => {
                        if (item.is_valid) {
                          return (<>
                            <li><b><h6 style={{ color: 'blue' }}>{item.catalog_color} ({item.sum_quantity}) </h6> </b></li>
                          </>)
                        } else {
                          return (<>
                            <li><b><h6 style={{ color: 'red' }}>{item.catalog_color} ({item.sum_quantity}) </h6> </b></li>
                          </>)
                        }
                      })
                    }
                  </ul>
                </div>,
            });
            props.addInquery([]);
          }
        })
        .catch((error) => {
          if (error.response.data.errors.email != null) {
            let type = "error";
            let mess = error.response.data.errors.email[0];
            Utils.notif(type, mess);
          } else {
            let type = "error";
            Utils.notif(type, "Error");
          }
        });
    }
  }

  return (
    <>
      <Typography.Title level={4}>Summary</Typography.Title>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record, index) => <ExpandedRowRender record={record} />,
        }}
        dataSource={props.inquery}
        pagination={{ pageSize: 4 }}
      />
      <div className='item-footer'>
        <Button type='primary' onClick={() => { ajoutPanier() }}>
          Validate the simulation
        </Button>
      </div>
    </>
  )
}

const mapStateToProps = ({ inquery }) => {
  return {
    inquery: inquery.inquery
  }
}

const mapDispatchToProps = {
  ...InqueryActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListChooseProductInquerry)