import React, { useEffect, useState } from "react";
import { withTranslation  } from 'react-i18next';
import { Menu, Icon } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const LeftMenu = (props) => {
  const [role, setRole] = useState(localStorage.getItem("label") || null);

  const centerStyle = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  };
  const history = useHistory();
    return (
   <Menu mode="horizontal" style={centerStyle}>
      <Menu.Item key="dashboard" onClick={() => history.push("/dashboard")}>
          {props.t('dashboard')}
      </Menu.Item>
      {
        role === "3" ? (
          <Menu.Item key="item" onClick={() => history.push("/dashboard/items")}>
              Products
          </Menu.Item>
        ) : (
        <SubMenu title={<span>
            Products
          </span>}>
            <MenuItemGroup title="">
              <Menu.Item key="items:1" onClick={() => history.push('/dashboard/items')}>{props.t('list_items')}</Menu.Item>
              {
                (role === "1" || role === "2") &&
                <Menu.Item key="items:2" onClick={() => history.push('/dashboard/category')}>{props.t('categories')}</Menu.Item>
              }
            
          </MenuItemGroup>
          </SubMenu>
        )
      }
        {
          (role !== "4") &&
            <Menu.Item key="querry" onClick={() => history.push('/dashboard/inquerry')}>
              Large order request
            </Menu.Item>
        }
        
        {
            (role === "4") &&
            <Menu.Item key="items:3" onClick={() => history.push('/dashboard/stocks')}>{props.t('Stocks_fabric')}</Menu.Item>
          }
        {
          role === "4" && (
            <Menu.Item key="panier" onClick={() => history.push('/dashboard/cart')}>
              Pending inqueries
            </Menu.Item>
          )
        }
        <Menu.Item key="Orders" onClick={() => history.push('/dashboard/commande')}>{props.t('list_commandes')} history</Menu.Item>
        {
          (role === "1" || role === "2" || role === "4") &&
          <Menu.Item key="production" onClick={() => history.push('/dashboard/production')}>
           Production
          </Menu.Item>
        }
        {/* <Menu.Item key="alipay" onClick={() => history.push('/dashboard/stocks')}>
          {props.t('stock')}
        </Menu.Item> */}
        
        {
          (role === "1" || role === "2" || role === "3") &&
          <Menu.Item key="payment_history" onClick={() => history.push('/dashboard/payment_history')}>
            {props.t('Payment history')}
          </Menu.Item>
        }
        {
          (role === "1" || role === "2") &&
          <Menu.Item key="users" onClick={() => history.push('/dashboard/users')}>
            {props.t('users')}
          </Menu.Item>
        }
        {
          (role === "1" || role === "2" || role === "4") &&
          <Menu.Item key="parameters" onClick={() => history.push('/dashboard/parameters')}>
            {props.t('parameters')}
          </Menu.Item>
        }
        <Menu.Item key="profil" onClick={() => history.push('/dashboard/profil')}>
            {props.t('my_profil')}
        </Menu.Item>
      </Menu>
    );
}
export default withTranslation()(LeftMenu)