import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Card, Typography, Table, Button, Modal, Popconfirm } from "antd";
import BASE_URL from "../../../config.js";
import { DeleteOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Utils } from "../../../services/Utils.js";
const { Title } = Typography;

const Category = (props) => {
  const [category, setCategory] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    getCategory();
  }, []);

  const deleteCategory = (id) => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios.post(`${BASE_URL}/api/v1/category/delete/${id}`,null, { headers })
    .then(response => {
        if(response.data.success){
          let type = 'success'
          let mess = response.data?.message;

          Utils.notif(type, mess);
          getCategory();
        }
    })
    .catch(error => {
      console.log(error);
    });
  };

  const cancelDelete = (e) => {
    // console.log(e);
  };

  const confirmDelete = (id) => {
    deleteCategory(id);
  };

  const getCategory = () => {
    setIsLoadingData(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .get(`${BASE_URL}/api/v1/category/list?parent_id=`, { headers })
      .then((res) => {
        const categoryData = res.data?.data;
        setCategory(categoryData);
        setIsLoadingData(false);
      })
      .catch((error) => {
        setIsLoadingData(false);
        console.log(error);
      });
  };

  const columns = [
    {
      title: props.t("label"),
      dataIndex: "label",
      sorter: (a, b) => a.label.localeCompare(b.label),
      onCell: (record, rowIndex) => {
        return {
            onClick: (ev) => { props.history.push(`/dashboard/category/${record.id}`); },
        };
      },
      render: (text) => {
        return (
          <p>
            {text}
          </p>
        );
      },
    },
    {
      title: props.t("description"),
      dataIndex: "description",
      onCell: (record, rowIndex) => {
        return {
            onClick: (ev) => { props.history.push(`/dashboard/category/${record.id}`); },
        };
      },
      render: (text) => {
        return (
          <p>
            {text}
          </p>
        );
      },
    },
    // {
    //   title: props.t("parent_id"),
    //   dataIndex: "parent_id",
    //   onCell: (record, rowIndex) => {
    //     return {
    //         onClick: (ev) => { props.history.push(`/dashboard/category/${record.id}`); },
    //     };
    //   },
    //   render: (text) => {
    //     return (
    //       <p>
    //         {text}
    //       </p>
    //     );
    //   },
    // },
    {
      title: props.t("actions"),
      dataIndex: "id",
      render: (text) => {
        return (
          <>
            <Link
              to={{
                pathname: `/dashboard/category/${text}`,
                state: { modal: true },
              }}
            > 
              <Button
                type="primary"
                onClick={() => null}
                shape="circle"
                className="mr-3"
                icon={<EditOutlined />}
              ></Button>
            </Link>
            <Popconfirm
              title="Are you sure to delete this category ?"
              description=""
              onConfirm={() => confirmDelete(text)}
              onCancel={cancelDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="danger"
                onClick={() => null}
                shape="circle"
                icon={<DeleteOutlined />}
              ></Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const addButton = (
    <Link
      to={{
        pathname: `/dashboard/category/new`,
        state: { modal: true },
      }}
    >     
      <Button
          type="primary"
          onClick={() => null}
          className="btn-shadow"
      >
            + {props.t('add_categorie')}
      </Button>
    </Link>
  );

  return (
    <Card 
        title={<Title level={3}>{props.t("categorie")}</Title>}
        extra={addButton}
    >
      <div style={{ overflowX: 'auto' }}>
        <Table
            columns={columns}
            dataSource={category}
            rowKey="rowAction"
            pagination={{ hideOnSinglePage: true, pageSize: 15 }}
            loading={{indicator: <LoadingOutlined/>, spinning: isLoadingData}}
        />
      </div>
    </Card>
  );
};

export default withTranslation()(withRouter(Category));
