
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import DashboardScreen from './dashboard/index.js';
import MonProfil from './profil/index.js';
import Users from './users/index.js';
import AddUser from './users/add.js';
import EditUser from './users/edit.js';
import Stocks from './stocks/index.js';
import AddStock from './stocks/add.js';
import EditStock from './stocks/edit.js';
import Items from './items/index.js';
import AddItem from './items/add.js';
import EditItem from './items/edit.js';
import Navbar from "../layouts/navbar";
import Category from './category/index.js';
import AddCategory from './category/add.js';
import EditCategory from './category/edit.js';
import Commande from './commande/index.js';
import CommandeProd from './commande/production.js';
import EditCommande from './commande/edit.js';
import Panier from './commande/panier.js';
import Parameters from './parameters/index.js';
import PaymentForm from './payement/index.js';
import Facture from './payement/facture.js';
import PaymentHistory from './list_paiement';
import Inquerry from './inquerry/index.js';

class Dashboard extends Component {
  constructor (props){
    super(props);
    this.logout = this.logout.bind(this);
    this.handleToggle = this.handleToggle.bind(this);

    this.state = {
      open: false,
      isShow: true
    };
  }

  handleToggle = e => {
      this.setState({
          isShow: !this.state.isShow
      });
  }
  logout () {
    this.props.callLogout()
    .then(() => {
      
    }); 
  
  }

  componentDidMount () {
    if(this.props.isAuth === false && this.props.appMount === true) {
      this.props.history.push("/login");
    }

  }

  componentWillReceiveProps(nextProps) {
    if(this.props.isAuth === false && nextProps.appMount === true) {
        this.props.history.push("/login");
    }
    if(nextProps.isAuth === false && this.props.appMount === true) {
      this.props.history.push("/login");
    }
  }


  render() {
    let role = localStorage.getItem("label") || null;
    let routeAdmin;
    routeAdmin = (
      
      <Switch>
          <Route
            exact
            path='/dashboard'
            render={() => <DashboardScreen />}
          />
          <Route
            path='/dashboard/profil'
            render={() => <MonProfil />}
          />
          <Route
            exact
            path='/dashboard/users'
            render={() => role === "1" || role === "2" ? <Users /> : <Redirect to="/" />}
          />
          <Route
            
            path='/dashboard/users/new'
            render={() => role === "1" || role === "2" ? <AddUser /> : <Redirect to="/" />}
          />
          <Route
            
            path='/dashboard/users/:id'
            render={() => role === "1" || role === "2" ? <EditUser /> : <Redirect to="/" />}
          />
          <Route
            exact
            path='/dashboard/items'
            render={() => <Items />}
          />
          <Route
            exact
            path='/dashboard/items/new'
            render={() => role === "1" || role === "2" ? <AddItem /> : <Redirect to="/" />}
          />
          <Route
            
            path='/dashboard/items/:id'
            render={() => role === "1" || role === "2" || role === "4" ? <EditItem /> : <Redirect to="/" />}
          />
          <Route
            
            path='/dashboard/stocks'
            render={() => <Stocks />}
          />
          <Route
            exact
            path='/dashboard/stock/new'
            render={() => <AddStock />}
          />
          <Route
            
            path='/dashboard/stock/:id'
            render={() => <EditStock />}
          />
          <Route
            exact
            path='/dashboard/category'
            render={() => role === "1" || role === "2" ? <Category /> : <Redirect to="/" />}
          />
          <Route
            exact
            path='/dashboard/category/new'
            render={() => role === "1" || role === "2" ? <AddCategory /> : <Redirect to="/" />}
          />
          <Route
            
            path='/dashboard/category/:id'
            render={() => role === "1" || role === "2" ? <EditCategory /> : <Redirect to="/" />}
          />

          <Route
            exact
            path='/dashboard/commande'
            render={() => <Commande />}
          />
          
          <Route
            exact
            path='/dashboard/commande/:id'
            render={() => <EditCommande show={false} />}
          />

          <Route
            exact
            path='/dashboard/commande/show/:id'
            render={() => <EditCommande show={false} />}
          />

          <Route
            exact
            path='/dashboard/cart'
            render={() => <Panier />}
          />

          <Route
            exact
            path='/dashboard/parameters'
            render={() => <Parameters />}
          />

          <Route
            exact
            path='/dashboard/payer'
            render={() => <PaymentForm />}
          />

          <Route
            exact
            path='/dashboard/facture'
            render={() => <Facture />}
          />
          <Route
            exact
            path='/dashboard/payment_history'
            render={() => <PaymentHistory />}
          />
          <Route
            exact
            path='/dashboard/inquerry'
            render={() => <Inquerry />}
          />
          <Route
            exact
            path='/dashboard/production'
            render={() => <CommandeProd />}
          />
    </Switch> 
           
    );
    return (
        <>
          <Navbar/>
          <div className="container pt-3">
              <div id="content">
                    <div className="pt-3 px-3">
                          {routeAdmin}
                    </div>
              </div>
          </div>
        </>
        
    );
  }
}

export default Dashboard
