import React, { Component } from 'react';
import { Button, Form, Input } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import '../../assets/css/login.css';
import BASE_URL from '../../config';
import { Utils } from '../../services/Utils.js';
import { withTranslation  } from 'react-i18next';
class ChangePass extends Component {
    state = {
        token: "",
        password: "",
        confirmPassword: "",
        token_pass:""
    };
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        if (this.props.isAuth === true) {
            this.props.history.push("/dashboard");
        }
        // console.log('KEYYYYYYYY / / / / ', this.props.match.params.token)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isAuth === true) {
            this.props.history.push("/dashboard");
        }
    }

    handleSubmit = (values) => {
        this.setState({
            isLoading: true
        });
        const { new_password} = values;
        axios.post(`${BASE_URL}/api/v1/update-password`, { email:this.props.match.params.email, new_password:new_password, secured_token:this.props.match.params.token })
            .then(response => {
                this.setState({
                    isLoading: false
                })
                if (response.data.success) {
                    let type = 'success'
                    let mess = response.data.message;
                    Utils.notif(type, mess);

                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 1000);
                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                })
                console.log(error)
            });
    };
    render() {
        const validatePassword = (rule, value, callback) => {
            const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
            if (!value || value.match(regex)) {
              callback();
            } else {
              callback('Input password must contain at least 1 capital letter, 1 number and be at least 8 characters long');
            }
        }
        return (
            <section className="main">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5 col-10">
                            <h1 className="text-center my-3">Change Passeword</h1>
                            <Form
                                name="sendMailPass"
                                labelCol={{
                                span: 8,
                                }}
                                wrapperCol={{
                                span: 16,
                                }}
                                style={{
                                maxWidth: 600,
                                }}
                                initialValues={{
                                remember: true,
                                }}
                                onFinish={this.handleSubmit}
                                onFinishFailed={null}
                                autoComplete="off"
                            >
                                <Form.Item
                                label={this.props.t('password')}
                                name="new_password"
                                hasFeedback
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please input your password!',
                                    },
                                    {
                                        validator: validatePassword,
                                    }
                                ]}
                                >
                                <Input.Password />
                                </Form.Item>
                                <Form.Item
                                label={this.props.t('Confirm password')}
                                name="confirm_password"
                                dependencies={['new_password']}
                                hasFeedback
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (!value || getFieldValue('new_password') === value) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                                >
                                <Input.Password />
                                </Form.Item>
                                <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                                >
                                <Button type="primary" htmlType="submit" loading={this.state.isLoading}>
                                    {this.props.t('submit')}
                                </Button>
                                </Form.Item>
                            </Form>
                            <hr className="w-100" />
                            <div className="d-flex justify-content-around py-3">
                                <Link to="/login">Sign in</Link>
                                {/* <span> | </span>
                                <Link to="/register">Créer un compte</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation()(ChangePass);