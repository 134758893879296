import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Card, Typography, Table, Button, Modal, Popconfirm, Badge, Form, Select } from "antd";
import BASE_URL from "../../../config.js";
import DetailCommande from "../../layouts/detail_commande.js";
import { EditOutlined, EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import '../../../assets/css/commande.scss';
import { Utils } from "../../../services/Utils.js";
const { Title } = Typography;

const Panier = (props) => {
    const [commande, setCommande] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [idClicked, setIdClicked] = useState(null);
    const [modal2Open, setModal2Open] = useState(false);
    const [role, setRole] = useState(localStorage.getItem("label") || null);
    const [statusR, setStatusR] = useState(10);
    const [modeR, setModeR] = useState("all");
    const [status, setStatus] = useState([]);
    useEffect(() => {
        getCommande();
        getStatus();
    }, []); 

    const getStatus = () => {
        const Authorization = localStorage.getItem("token");
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Authorization,
        };
        try {
            axios.get(`${BASE_URL}/api/v1/status-list`, { headers })
            .then((res)=>{
            if(res.data.success){
                const statu = res.data?.data;
                var temp = [];
                statu.map((item)=>{
                temp.push({value:item.id,label:item.label})
                })
                setStatus([...temp]);
            }
            })
            .catch(err => {
            console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
        };

    const detailCommande = (record) =>{
        setIdClicked(record.id); 
        setModal2Open(true);
    }

    const changeMode = (value) =>{
        setModeR(value);
        getCommande({type:value})
    }
    
    const changeStatus = (value) =>{
        if(value=="all"){
          setStatusR(null);
          getCommande()
        }else{
          setStatusR(value);
          getCommande({status:value})
        }
        
    }

    const getCommande = (filtre={}) => {
        setIsLoadingData(true);
        const Authorization = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Authorization,
        };
        let fil = "";
        if(filtre?.status){
          if(modeR!=null){
            fil = `&status_id=${filtre?.status}&type_payment=${modeR}`;
          }
        }
        if(filtre?.type){
          if(statusR!=null){
            fil = `&status_id=${statusR}&type_payment=${filtre?.type}`;
          }else{
            fil = `&type_payment=${filtre?.type}`;
          }
        }
        axios
          .get(`${BASE_URL}/api/v1/command/list?is_not_payed=true${fil}`
        ,{ headers })
          .then((res) => {
            const commandeData = res.data?.data;
            console.log("comma=>",commandeData)
            setCommande(commandeData);
            // setCommande(mook);
            setIsLoadingData(false);
          })
          .catch((error) => {
            setIsLoadingData(false);
            console.log(error);
          });
    };

    const columnsD = [
        {
        title: 'Product name',
        dataIndex: 'catalogue_name',
        render: (text) => <a>{text}</a>,
        },
        {
        title: 'Quantity',
        align:"right",
        dataIndex: 'quantity'
        },
        {
        title: 'Price',
        dataIndex: 'prix_ligne',
        align:"right",
        render: (text) => <p style={{marginBottom:'0'}}>${text}</p>,
        },
    ];
    
    const dataD = [
        {
        key: '1',
        name: 'TEST BE',
        quantity: 10,
        price: 2000,
        },
        {
        key: '2',
        name: 'TEE-SHORT',
        quantity: 1,
        price: 4000,
        },
        {
        key: '3',
        name: 'TEE-SHORT BE',
        quantity: 500,
        price: 4000,
        },
    ];

    const getTotal = (allProduct) =>{
        var total = 0;
        allProduct.map((item)=>{
        total+=parseFloat(item.prix_ligne);
        })
        return total;
    }
    const columns = [
        {
            title: "Date",
            dataIndex: [],
            align:"left",
            render: (text,record) => {
              return (
                <>
                  <span style={{marginBottom:'0',fontSize:'12px',color:'#8c8c8c'}}>
                    {record.date_commande}
                  </span>
                </>
              );
            },
        },
        {
        title: "Status",
        dataIndex: [],
        align:"center",
        render: (text,record) => {
            return (
            <>
                <p className="text-uppercase font-weight-bold mb-0">
                    {record?.status?.label}
                </p>
                <div className="dot">
                {
                    record.command_lines.filter(line => line.stocks.length !== 0).map((item, i)=>{
                    if(i<8){
                        return (
                        <div title={item.status.label} className="dot-item" style={
                            {
                            backgroundColor:item.status.code
                            }
                        }>
                        
                        </div>
                        )
                    }
                    })
                }
                
                {/* <div className="dot-item" style={{backgroundColor:'red'}}>
                
                </div>
                <div className="dot-item" style={{backgroundColor:'red'}}>
                
                </div>
                <div className="dot-item" style={{backgroundColor:'red'}}>
                
                </div>
                <div className="dot-item" style={{backgroundColor:'red'}}>
                
                </div>
                <div className="dot-item" style={{backgroundColor:'red'}}> */}
                    {/* <span style={{fontSize:'11px',color:'white',display:'table-cell',fontWeight:'bold'}}>+5</span> */}
                {/* </div> */}
                </div>  
            </>
            );
        },
        },
        {
        title: "Sale's rep.",
        dataIndex: "client.name",
        align:"center",
        render: (text,record) => {
            return (
            <span>
                {record.command_owner.name}
            </span>
            );
        },
        },
        {
        title: "Contact",
        dataIndex: [],
        align:"center",
        render: (text,record) => {
            return (
            <span>
                {record.command_owner.email}
            </span>
            );
        },
        },
        {
        title: "Balance payment",
        dataIndex: [],
        align:"right",
        className: `tdColumn ${ role === "4" && "d-none"}`,
        render: (text,record) => {
            return (
            <span>
                {Utils.formatMoney(parseFloat(record.prix_remise)-parseFloat(record.acompte))}
            </span>
            );
        },
        },
        {
        title: props.t("actions"),
        dataIndex: "id",
        align:"center",
        render: (text) => {
            return (
            <>
                {
                // (role === "1" || role === "2") ?(
                    <Link
                    to={{
                        pathname: `/dashboard/commande/${text}`,
                        state: { modal: true },
                    }}
                    > 
                    <EditOutlined />
                    </Link>
                // ):(
                //     <Link
                //     to={{
                //         pathname: `/dashboard/commande/show/${text}`,
                //         state: { modal: true },
                //     }}
                //     > 
                //     <EyeOutlined />
                //     </Link>
                // )
                }
                
                {/* <span className="p-2"></span> */}
                
                
            </>
            );
        },
        },
    ];


    return (
        <Card 
        className="commande-list"
            title={<Title level={3}>Cart</Title>}
        >
            <Card style={{top:'46px',position:'sticky',zIndex:'99',marginBottom:'1px'}}>
                <div  className="row">
                    <div className="col-md-12">
                        <Form layout="inline"  style={{justifyContent:'left'}} className="row">
                            {/* <Form.Item label="Order status:" name='globalStatus' >
                                <Select
                                // onChange={showConfirm}
                                style={{minWidth:'170px'}}
                                disabled={props.show}
                                defaultValue="All"
                                options={status}
                                /> 
                            </Form.Item> */}
                            <Form.Item label="Order status:" name='orderStatus' className="col-md-4">
                                <Select
                                    style={{ minWidth: '200px',marginBottom:'10px' }}
                                    showArrow
                                    placeholder="Order status"
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={changeStatus}
                                    defaultValue="all"
                                >
                                <Select.Option key={`key-0`} value="all">
                                All status
                                </Select.Option>
                                    {status &&
                                    status.map((item) => (
                                        <Select.Option key={`key-${item.value}`} value={item.value}>
                                        {item.label}
                                        </Select.Option>
                                    ))}
                                </Select> 
                            </Form.Item>
                            <Form.Item label="Mode of payement:" name='payementMode' className="col-md-4">
                                <Select
                                    style={{ minWidth: '200px',marginBottom:'10px' }}
                                    showArrow
                                    placeholder="Mode of payement"
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={changeMode}
                                    defaultValue="all"
                                >
                                <Select.Option key={`key-1`} value="all">
                                All mode
                                </Select.Option>
                                <Select.Option key={`key-2`} value="cb">
                                Pay with cart
                                </Select.Option>
                                <Select.Option key={`key-3`} value="vb">
                                Pay with bank
                                </Select.Option>
                                </Select> 
                            </Form.Item>
                            {/* <div style={{display:'flex',alignItems:'center'}}>
                            <span style={{marginRight:'10px'}}>All items</span>
                            <Form.Item name="in_stock" >
                                
                                <Switch defaultChecked={false} onChange={(value) => {
                                        // setFiltersInStock(value) 
                                    }
                                } className="rounded" />
                            </Form.Item>
                            <span>In stock</span>
                            </div> */}
                        </Form>
                    </div>
                </div>
                
                
                
            </Card>
        <div style={{ overflowX: 'auto' }}>
            <Table
                columns={columns}
                dataSource={commande}
                bordered
                rowKey="rowAction"
                pagination={{ hideOnSinglePage: false, pageSize: 15 }}
                loading={{indicator: <LoadingOutlined/>, spinning: isLoadingData}}
            />
        </div>
        </Card>
    );
}
export default withTranslation()(Panier)