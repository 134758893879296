import { inqueryReducer } from "./inqueryReducer";
import {ordersReducer} from "./ordersReducer";
import { quantitiesReducer } from "./quantitiesReducer";

import { combineReducers } from "redux";

const allReducers = combineReducers({
    order: ordersReducer,
    quantities: quantitiesReducer,
    inquery: inqueryReducer,
});

export default allReducers;