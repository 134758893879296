const initialState = {
  inquery: [],
  quantities: {}
};

export const inqueryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SET_INQUERY':
      return {
        inquery: action.payload
      }
    case 'SET_QUANTITIES':
        return {
          inquery: action.payload
        }
    default:
      return state
  }
}