import React, { useEffect, useState } from 'react';
import { withTranslation  } from 'react-i18next';
import { withRouter } from "react-router";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button, Select, Form, Input, Card, Typography, Checkbox } from "antd";
import BASE_URL from "../../../config.js";
import { Utils } from '../../../services/Utils.js';
const { Title } = Typography;

const AddUsers = (props) => {
    const [formCreateUser] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false);
    const [isDifferent, setIsDifferent] = useState(false);
    const [roles, setRoles] = useState(JSON.parse(localStorage.getItem('LIST_ROLES')));
  
    const saveUser = (value) => {
        setIsLoading(true);
        const { nom, prenom, role_id, email, password, address,tva,acompte,remise,company,address_livraison,period } = value;
        console.log(value);
        var adressT = address;
        if(adressT==undefined){
            adressT = address_livraison;
        }
        const Authorization = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Authorization,
        };
        axios.post(`${BASE_URL}/api/v1/user/add`, { 
            name:nom, 
            firstname:prenom, 
            role_id:role_id, 
            email:email, 
            password:password, 
            delivery_address:adressT,
            company_name:company,
            tva_number:tva,
            acompte:acompte,
            remise:remise,
            billing_address:address_livraison,
            is_same_adress: !isDifferent,
            expiration_date: period
        }, { headers })
        .then(response => {
            setIsLoading(false);
            if(response.data.success){
                let type = 'success'
                let mess = response.data?.message
                Utils.notif(type, mess);

                clearForm();

                props.history.push("/dashboard/users");
            }
        })
        .catch(error => {
            setIsLoading(false);
            console.log(error.response.data.errors.email[0]);
            if(error.response.data.errors.email != null){
                let type = 'error'
                let mess = error.response.data.errors.email[0]
                Utils.notif(type, mess);
            }
        });
    };
    const clearForm = () => {
        formCreateUser.setFieldsValue({
            nom: null,
            prenom: null,
            email: null,
            password: null,
            role_id: null,
            acompte:null,
            remise:null,
            address: null,
        });
    }

    const validatePassword = (rule, value, callback) => {
        const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
        if (!value || value.match(regex)) {
          callback();
        } else {
          callback('Input password must contain at least 1 capital letter, 1 number and be at least 8 characters long');
        }
    }
    return (
        <>
            <Card title={<Title level={3}>{props.t('add_user')}</Title>}>
            <div className="row">
            <div className="col-md-12">
            <Form
                form={formCreateUser}
                name="formCreateUser"
                labelCol={{
                span: 8,
                }}
                wrapperCol={{
                span: 16,
                }}
                style={{
                maxWidth: 600,
                }}
                initialValues={{
                remember: true,
                }}
                onFinish={saveUser}
                onFinishFailed={null}
                autoComplete="off"
            >
                <Form.Item
                label={props.t("name")}
                name="nom"
                rules={[
                    {
                    required: true,
                    message: "Please input name!",
                    },
                ]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                label={props.t("firstname")}
                name="prenom"
                rules={[
                    {
                    required: true,
                    message: "Please input firstname!",
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label={props.t("company_name")}
                name="company"
                rules={[
                    {
                    required: true,
                    message: "Please input company name!",
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label={props.t("role")}
                name="role_id"
                rules={[
                    {
                    required: true,
                    message: "Please input role!",
                    },
                ]}
                >
                <Select
                    allowClear
                    placeholder={props.t('choose_role')}
                    showSearch
                    optionFilterProp="children"
                >
                    {
                        roles && roles.map((item) => (
                            <Select.Option
                                key={`key-${item.id}`}
                                value={item.id}
                            >
                                {item.label}
                            </Select.Option>
                        ))
                    }
                </Select>
                </Form.Item>
                <Form.Item
                label={props.t('tva_number')}
                name="tva"
                rules={[
                    {
                    required: true,
                    message: "Please input TVA number!",
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item name="address_livraison" label={props.t('delivery_address')} 
                rules={[
                    {
                    required: true,
                    message: "Please input Billing Adress!",
                    },
                ]}>
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                label="If it is different delivery address"
                name="different"
                style={{marginTop:'-25px'}}
                >
                <Checkbox checked={isDifferent} onChange={()=>setIsDifferent(!isDifferent)} />
                </Form.Item>
                {
                    isDifferent &&(
                        <Form.Item name="address" label={props.t("address")}>
                            <Input.TextArea />
                        </Form.Item>
                    )
                }

                <Form.Item
                label={props.t("email")}
                name="email"
                rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid Email!',
                    },
                    {
                      required: true,
                      message: 'Please input Email!',
                    },
                ]}
                >
                <Input autoComplete="off" />
                </Form.Item>

                <Form.Item
                label={props.t('pc_remise')}
                name="remise"
                rules={[
                    {
                    required: true,
                    message: "Please input remise!",
                    },
                ]}
                >
                <Input type='number' />
                </Form.Item>
                
                <Form.Item
                label={props.t('pc_acompte')}
                name="acompte"
                rules={[
                    {
                    required: true,
                    message: "Please input acompte!",
                    },
                ]}
                >
                <Input type='number' />
                </Form.Item>

                <Form.Item
                label={props.t("password")}
                name="password"
                rules={[
                    {
                      validator: validatePassword,
                    },
                    {
                        required: true,
                        message: 'Please input Password!',
                    },
                ]}
                >
                <Input.Password autoComplete="off" />
                </Form.Item>
                <Form.Item
                label="Validity period"
                name="period"
                rules={[
                    {
                      required: true,
                      message: 'Please input validiity period!',
                    },
                ]}
                >
                <Input type='date' />
                </Form.Item>
                <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    className="mr-3"
                >
                    {props.t("btn_save")}
                </Button>
                <Link
                    to='/dashboard/users'
                >
                    {props.t('retour_liste')}
                </Link>
                </Form.Item>
            </Form>
            </div>
        </div>
            </Card>
        </>
    );
}

export default withTranslation()(withRouter(AddUsers))