// Initial state
const initialState = {
    cart:[

    ],
    one:[

    ],
    two:[

    ]
};

  // Comportement
export const ordersReducer =(state = initialState, action = {}) => {
    const cart = state?.cart?[...state?.cart]:[];
    const one = state?.one?[...state?.one]:[];
    const two = state?.two?[...state?.two]:[];
    console.log("=+>",action.payload)
    switch (action.type) {
        case 'ADD':
            var testIn = false;
            if(cart.length>0){
                cart.map((item,i)=>{
                    if(item?.product?.id==action.payload?.product?.id){
                        action.payload?.commande.map((itemC)=>{
                            console.log("itemCI",itemC)
                            var comMtov = false;
                            item?.commande.map((itemComCart,k)=>{
                                if(itemC?.color?.label==itemComCart?.color?.label && itemC?.variant?.value==itemComCart?.variant?.value){
                                    if(cart[i]?.commande[k]?.in!=null && isNaN(cart[i]?.commande[k]?.in)==false && isNaN(itemC?.in)==false && itemC?.in!=null){
                                        // cart[i].commande[k].in = parseInt(itemComCart?.in)+parseInt(itemC?.in);
                                        cart[i].commande[k].in = parseInt(itemC?.in);
                                        comMtov = true;
                                    }
                                }
                            })
                            if(!comMtov){
                                if(isNaN(itemC?.in)==false && itemC?.in!=null){
                                    cart[i].commande[cart[i].commande.length] = {color:itemC.color,in:itemC?.in,max:itemC?.max,variant:itemC?.variant};
                                }
                            }
                        })
                        testIn = true;
                    }
                })
            }
            if(cart.length==0 || testIn==false){
                const temp = {product:action.payload.product,commande:[]};
                action.payload.commande.map((itemC)=>{
                    if(isNaN(itemC?.in)==false){
                        temp.commande.push({color:itemC.color,isShow:true,in:itemC.in,max:itemC?.max,variant:itemC.variant});
                    }
                })
                if(temp.commande.length>0){
                    cart.push(temp);
                }
            }
           
            var testOne = false;
            if(one.length>0){
                one.map((item,i)=>{
                    if(item?.product?.id==action.payload?.product?.id){
                        action.payload?.commande.map((itemC)=>{
                            console.log("itemCI",itemC)
                            var comMtov = false;
                            item?.commande.map((itemComCart,k)=>{
                                if(itemC?.color?.label==itemComCart?.color?.label && itemC?.variant?.value==itemComCart?.variant?.value){
                                    if(one[i]?.commande[k]?.one!=null && isNaN(one[i]?.commande[k]?.one)==false && isNaN(itemC?.one)==false && itemC?.one!=null){
                                        one[i].commande[k].one = parseInt(itemC?.one);
                                        // one[i].commande[k].one = parseInt(itemComCart?.one)+parseInt(itemC?.one);
                                        comMtov = true;
                                    }
                                }
                            })
                            if(!comMtov){
                                if(isNaN(itemC?.one)==false && itemC?.one!=null){
                                    one[i].commande[one[i].commande.length] = {color:itemC.color,one:itemC?.one,max:itemC?.max,variant:itemC?.variant};
                                }
                            }
                        })
                        testOne = true;
                    }
                })
            }
            if(one.length==0 || testOne==false){
                const temp = {product:action.payload.product,commande:[]};
                action.payload.commande.map((itemC)=>{
                    if(isNaN(itemC?.one)==false){
                        temp.commande.push({color:itemC.color,one:itemC.one,max:itemC?.max,variant:itemC.variant});
                    }
                })
                if(temp.commande.length>0){
                    one.push(temp);
                }
            }
            
            var testTwo = false;
            if(two.length>0){
                two.map((item,i)=>{
                    if(item?.product?.id==action.payload?.product?.id){
                        action.payload?.commande.map((itemC)=>{
                            console.log("itemCI",itemC)
                            var comMtov = false;
                            item?.commande.map((itemComCart,k)=>{
                                if(itemC?.color?.label==itemComCart?.color?.label && itemC?.variant?.value==itemComCart?.variant?.value){
                                    if(two[i]?.commande[k]?.two!=null && isNaN(two[i]?.commande[k]?.two)==false && isNaN(itemC?.two)==false && itemC?.two!=null){
                                        two[i].commande[k].two = parseInt(itemC?.two);
                                        // two[i].commande[k].two = parseInt(itemComCart?.two)+parseInt(itemC?.two);
                                        comMtov = true;
                                    }
                                }
                            })
                            if(!comMtov){
                                if(isNaN(itemC?.two)==false && itemC?.two!=null){
                                    two[i].commande[two[i].commande.length] = {color:itemC.color,two:itemC?.two,max:itemC?.max,variant:itemC?.variant};
                                }
                            }
                        })
                        testTwo = true;
                    }
                })
            }
            if(two.length==0 || testTwo==false){
                const temp = {product:action.payload.product,commande:[]};
                action.payload.commande.map((itemC)=>{
                    if(isNaN(itemC?.two)==false){
                        temp.commande.push({color:itemC.color,two:itemC.two,max:itemC?.max,variant:itemC.variant});
                    }
                })
                if(temp.commande.length>0){
                    two.push(temp);
                }
            }
            // if(two.length==0){
            //     const temp = {product:action.payload.product,commande:[]};
            //     action.payload.commande.map((itemC)=>{
            //         if(isNaN(itemC?.two)==false){
            //             temp.commande.push({color:itemC.color,two:itemC.two,variant:itemC.variant});
            //         }
            //     })
            //     if(temp.commande.length>0){
            //         two.push(temp);
            //     }
            // }else{
            //     two.map((item,i)=>{
            //         if(item?.product?.id==action.payload?.product?.id){
            //             action.payload?.commande.map((itemC)=>{
            //                 console.log("itemCI",itemC)
            //                 var comMtov = false;
            //                 item?.commande.map((itemComCart,k)=>{
            //                     if(itemC?.color?.label==itemComCart?.color?.label && itemC?.variant?.value==itemComCart?.variant?.value){
            //                         if(two[i]?.commande[k]?.two!=null && isNaN(two[i]?.commande[k]?.two)==false && isNaN(itemC?.two)==false && itemC?.two!=null){
            //                             two[i].commande[k].two = parseInt(itemC?.two);
            //                             // two[i].commande[k].two = parseInt(itemComCart?.two)+parseInt(itemC?.two);
            //                             comMtov = true;
            //                         }
            //                     }
            //                 })
            //                 if(!comMtov){
            //                     if(isNaN(itemC?.two)==false && itemC?.two!=null){
            //                         two[i].commande[two[i].commande.length] = {color:itemC.color,two:itemC?.two,variant:itemC?.variant};
            //                     }
            //                 }
            //             })
            //         }
            //     })
            // }
            
            // // IN
            //     var test = true;
            //     cart.map((item,i)=>{
            //         if(item?.product?.id==action.payload?.product?.id){
            //             action.payload?.commande.map((itemC,j)=>{
            //                 console.log("itemCI",itemC)
            //                 var comMtov = false;
            //                 item?.commande.map((itemComCart,k)=>{
            //                     if(itemC?.color?.label==itemComCart?.color.label && itemC?.variant?.value==itemComCart?.variant?.value){
            //                         if(cart[i]?.commande[k]?.in!=null && isNaN(cart[i]?.commande[k]?.in)==false && isNaN(itemC?.in)==false && itemC?.in!=null){
            //                             cart[i].commande[k].in = parseInt(itemComCart?.in)+parseInt(itemC?.in);
            //                         }
            //                         comMtov = true;
            //                     }
            //                 })
            //                 if(!comMtov){
            //                     if(isNaN(itemC?.in)==false && itemC?.in!=null){
            //                         cart[i].commande[cart[i].commande.length] = {color:itemC.color,in:itemC?.in,variant:itemC?.variant};
            //                     }
            //                     // cart[i].commande[cart[i].commande.length] = itemC;
            //                 }
            //             })
            //             test = false;
            //         }
            //     })
            //     if(test){
            //         // console.log("action.payload",action.payload)
            //         const temp = {product:action.payload.product,commande:[]};
            //         action.payload?.commande.map((itemC)=>{
            //             console.log(itemC?.in,isNaN(itemC.in))
            //             if(isNaN(itemC?.in)==false){
            //                 temp.commande.push({color:itemC.color,in:itemC.in,variant:itemC.variant});
            //             }
            //         })
            //         if(temp.commande.length>0){
            //             cart.push(temp);
            //         }
            //     }
            // // ONE 
                
            //     var testO = true;
            //     one.map((item,i)=>{
            //         if(item?.product?.id==action.payload?.product?.id){
            //             action.payload?.commande.map((itemC,j)=>{
            //                 console.log("itemCO",itemC)
            //                 var comMtov = false;
            //                 item?.commande.map((itemComCart,k)=>{
            //                     if(itemC?.color?.label==itemComCart?.color?.label && itemC?.variant?.value==itemComCart?.variant?.value){
            //                         if(one[i]?.commande[k]?.one!=null && isNaN(one[i]?.commande[k]?.one)==false && isNaN(itemC?.one)==false && itemC?.one!=null){
            //                             one[i].commande[k].one = parseInt(itemComCart.one)+parseInt(itemC.one);
            //                         }
            //                         comMtov = true;
            //                     }
            //                 })
            //                 if(!comMtov){
            //                     if(isNaN(itemC?.one)==false && itemC?.one!=null){
            //                         one[i].commande[cart[i].commande.length] = {color:itemC.color,one:itemC.one,variant:itemC.variant};
            //                     }
            //                 }
            //             })
            //             testO = false;
            //         }
            //     })
            //     if(testO){
            //         // one.push(action.payload);
            //         const temp = {product:action.payload.product,commande:[]};
            //         action.payload?.commande.map((itemC)=>{
            //             console.log(itemC?.one,isNaN(itemC.one))
            //             if(isNaN(itemC?.one)==false){
            //                 temp.commande.push({color:itemC.color,one:itemC.one,variant:itemC.variant});
            //             }
            //         })
            //         if(temp.commande.length>0){
            //             one.push(temp);
            //         }
            //     }
            // // TWO 
                
            //     var testT = true;
            //     two.map((item,i)=>{
            //         if(item?.product?.id==action.payload?.product?.id){
            //             action.payload?.commande.map((itemC,j)=>{
            //                 console.log("itemCT",itemC)
            //                 var comMtov = false;
            //                 item?.commande.map((itemComCart,k)=>{
            //                     if(itemC?.color.label==itemComCart?.color.label && itemC?.variant.value==itemComCart?.variant.value){
            //                         if(two[i].commande[k].two!=null && isNaN(two[i].commande[k].two)==false && isNaN(itemC.two)==false && itemC.two!=null){
            //                             two[i].commande[k].two = parseInt(itemComCart.two)+parseInt(itemC.two);
            //                         }
            //                         comMtov = true;
            //                     }
            //                 })
            //                 if(!comMtov){
            //                     if(isNaN(itemC.two)==false && itemC.two!=null){
            //                         two[i].commande[cart[i].commande.length] = {color:itemC.color,two:itemC.two,variant:itemC.variant};
            //                     }
            //                     // two[i].commande[cart[i].commande.length] = itemC;
            //                 }
            //             })
            //             testT = false;
            //         }
            //     })
            //     if(testT){
            //         const temp = {product:action.payload.product,commande:[]};
            //         action.payload.commande.map((itemC)=>{
            //             console.log(itemC?.two,isNaN(itemC.two))
            //             if(isNaN(itemC?.two)==false){
            //                 temp.commande.push({color:itemC.color,two:itemC.two,variant:itemC.variant});
            //             }
            //         })
            //         if(temp.commande.length>0){
            //             two.push(temp);
            //         }
            //     }
            return {
                cart: cart ,
                one:one,
                two:two
            };
        case 'CHANGE':
            // if(action.payload.type=='in'){
            //     cart.map((item,i)=>{
            //         if(item.product.id==action.payload.product.product.id){
            //             item.commande.map((itemComCart,k)=>{
            //                 if(action.payload.color==itemComCart.color.label && action.payload.variant==itemComCart.variant.value){
            //                     if(cart[i].commande[k].in!=null && isNaN(cart[i].commande[k].in)==false && isNaN(action.payload.value)==false && action.payload.value!=null){
            //                         cart[i].commande[k].in = parseInt(action.payload.value);
            //                     }
            //                 }
            //             })
            //         }
            //     })
            //     // console.log("TY=>",action.payload)
            // }
            // else if(action.payload.type=='one'){
            //     one.map((item,i)=>{
            //         if(item.product.id==action.payload.product.product.id){
            //             item.commande.map((itemComCart,k)=>{
            //                 if(action.payload.color==itemComCart.color.label && action.payload.variant==itemComCart.variant.value){
            //                     if(one[i].commande[k].one!=null && isNaN(one[i].commande[k].one)==false && isNaN(action.payload.value)==false && action.payload.value!=null){
            //                         one[i].commande[k].one = parseInt(action.payload.value);
            //                     }
            //                 }
            //             })
            //         }
            //     })
            // }
            // else if(action.payload.type=='two'){
            //     two.map((item,i)=>{
            //         if(item.product.id==action.payload.product.product.id){
            //             item.commande.map((itemComCart,k)=>{
            //                 if(action.payload.color==itemComCart.color.label && action.payload.variant==itemComCart.variant.value){
            //                     if(two[i].commande[k].two!=null && isNaN(two[i].commande[k].two)==false && isNaN(action.payload.value)==false && action.payload.value!=null){
            //                         two[i].commande[k].two = parseInt(action.payload.value);
            //                     }
            //                 }
            //             })
            //         }
            //     })
            // }
            
            return {
                cart: [
                ...cart
                ],
                one:[
                ...one
                ],
                two:[
                ...two
                ]
            };
        case 'DELETE ONE':
            const cartT = [];
            const oneT = [];
            const twoT = [];
            if(action.payload.type=='in'){
                console.log("cart",cart)
                cart.map((item,i)=>{
                    if(item.product.id==action.payload.product.product.id){
                        const temp = {product:item.product,commande:[]};
                        item.commande.map((itemComCart,k)=>{
                            if(action.payload.color==itemComCart?.color.label && action.payload.variant==itemComCart.variant.value){
                                
                            }else{
                                temp.commande.push(itemComCart);
                            }
                        })
                        cartT.push(temp);
                    }else{
                        cartT.push(item);
                    }
                })
                console.log("cartT",cartT)
                return {
                    cart: [
                    ...cartT
                    ],
                    one:[
                    ...one
                    ],
                    two:[
                    ...two
                    ]
                };
            }
            else if(action.payload.type=='one'){
                console.log("one",one)
                one.map((item,i)=>{
                    if(item.product.id==action.payload.product.product.id){
                        const temp = {product:item.product,commande:[]};
                        item.commande.map((itemComCart,k)=>{
                            if(action.payload?.color==itemComCart?.color.label && action.payload.variant==itemComCart.variant.value){
                                
                            }else{
                                temp.commande.push(itemComCart);
                            }
                        })
                        oneT.push(temp);
                    }else{
                        oneT.push(item);
                    }
                })
                console.log("oneT",oneT)
                return {
                    cart: [
                    ...cart
                    ],
                    one:[
                    ...oneT
                    ],
                    two:[
                    ...two
                    ]
                };
            }
            else if(action.payload.type=='two'){
                console.log("two",two)
                two.map((item,i)=>{
                    if(item.product.id==action.payload.product.product.id){
                        const temp = {product:item.product,commande:[]};
                        item.commande.map((itemComCart,k)=>{
                            if(action.payload?.color==itemComCart?.color?.label && action.payload.variant==itemComCart.variant.value){
                                
                            }else{
                                temp.commande.push(itemComCart);
                            }
                        })
                        twoT.push(temp);
                    }else{
                        twoT.push(item);
                    }
                })
                console.log("twoT",twoT)
                return {
                    cart: [
                    ...cart
                    ],
                    one:[
                    ...one
                    ],
                    two:[
                    ...twoT
                    ]
                };
            }
            return {
                cart: [
                ...cart
                ],
                one:[
                ...one
                ],
                two:[
                ...two
                ]
            };
        case 'DELETE':
            return initialState;
        default:
            return state;
    }

    // if (action.type === 'ADD') {
    //     const cart = state?.cart?[...state?.cart]:[];
    
    //     cart.push(action.payload);
    
    //     return {
    //         ...state,
    //         cart: [
    //         ...cart
    //         ]
    //     };
    // }
  
    // return state;
}