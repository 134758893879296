import { Button, Modal } from 'antd'
import React from 'react'

function ModalOrder({openModal, setOpenModal, confirmOrder, makeAnotheraOrder }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleCancel = () => {
    setOpenModal(false);
    window.location.reload();
  }
  return (
    <Modal
      open={openModal}
      title=""
      onOk={() => handleCancel()}
      onCancel={() => handleCancel()}
      maskClosable={false}
      footer={[
        <Button key="submit" type="primary" loading={loading} onClick={handleCancel}>
          Review Shopping Cart
        </Button>,
        <Button
          type="primary"
          loading={loading}
          onClick={handleCancel}
        >
          Back To Shopping
        </Button>,
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
    >
      <div style={{textAlign:'center'}}>
          <img alt='order' style={{width:"150px",height:"150px"}} src='https://img.freepik.com/free-vector/shopping-cart-realistic_1284-6011.jpg?w=2000'/>
          <p><b>Item successfully added to Cart !</b></p>
      </div>
    </Modal>
  )
}

export default ModalOrder