import { notification } from "antd";
import SweetAlert from 'sweetalert2-react';

export const Utils = {
    notif: (type, message) => {
        notification[type]({
            message: 'Information !',
            description: message,
            pnotificationlacement: 'bottomRight'
        });
    },
    notifSweet: (type, message) => {
        return (
            <SweetAlert
                show="true"
                type={type}
                text={message}
                onConfirm={() => null}
            />
        );
    },
    formatMoney: (arg) =>{
        return arg.toLocaleString('en-US', {
            style: 'currency',
            currency: 'EUR'
        })
    }
}