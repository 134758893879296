import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Card,
  Input,
  List,
  Form,
  Modal,
  Typography,
  Select,
  Checkbox,
  Switch,
  Collapse,
  Space,
  Row,
  Col,
  Popconfirm,
  message,
} from "antd";
import Meta from "antd/lib/card/Meta";
import '../../../assets/css/inquery.css';
import { ArrowUpOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import DetailProduct from "../../layouts/detail_product";
import BASE_URL from "../../../config";
import Axios from "axios";
import { Utils } from "../../../services/Utils";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import { addPanier } from "../../../my_redux/actions/orders";
import DetailProductInQuerry from "../../layouts/detail_product_inquerry";
import ListChooseProductInquerry from "../../layouts/list_choose_product_inquerry";
import * as InqueryActions from "../../../my_redux/actions/inquery";
import ItemInquery from "../../layouts/ItemInquery";
import { animateScroll as scroll, scroller } from "react-scroll";
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;
const colors = [
  { label: "NAVY", value: "NAVY" },
  { label: "BLACK", value: "BLACK" },
  { label: "BEIGE", value: "BEIGE" },
  { label: "GREY", value: "GREY" },
  { label: "PLATINUM", value: "PLATINUM" },
  { label: "RED", value: "RED" },
  { label: "BLUE", value: "BLUE" },
];
const styles = [
  { label: "MAN", value: "MAN" },
  { label: "WOMAN", value: "WOMAN" },
];

const URL_DEFAULT_IMAGE = "https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg";

const Inquerry = (props) => {
  const [products, setProducts] = useState([]);
  const [idClicked, setIdClicked] = useState(null);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [role, setRole] = useState(localStorage.getItem("label") || null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [wordSearch, setWordSearch] = useState("");
  const [itemQuery, setItemQuery] = useState(null);
  const [category, setCategory] = useState([]);
  const [formFilter] = Form.useForm();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsColors, setSelectedOptionsColors] = useState([]);
  const [selectedOptionsStyles, setSelectedOptionsStyles] = useState([]);
  const [filtersProducts, setFiltersProducts] = useState(null);
  const [filtersInStock, setFiltersInStock] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [stockList, setStockList] = useState([]);

  const dispatch = useDispatch();

  const addPanierS = (product) => {
    dispatch(addPanier(product));
    // console.log("===========================>",orders);
  };

  const detailProduct = (item) => {
    const stock = stockList.find(s => s.catalogs.find(p => p.reference === item.reference && p.name === item.name));
    const cacheQte = localStorage.getItem(stock.catalogs[0].name);
    if(cacheQte) {
      setQuantities(JSON.parse(cacheQte));
    }
    if(!cacheQte) {
      setQuantities({
        quantity: parseFloat(stock.quantity),
        colors: stock.colors.map(c => { return {color: c.color_label, quantity: c.quantity, avConso: stock.catalogs[0].consomation_moyenne}})
      })
    }
    setIdClicked(item);
    setItemQuery(item);
  };

  const getListStock = () => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };

    axios.get(`${BASE_URL}/api/v1/stock/list`, { headers })
      .then((response) => {
        setStockList(response.data.data.filter(d => d.catalogs.length !== 0));
      })
      .catch((error) => {
        console.log(error)
        Utils.notif("error", "Server error");
      });
  }

  const updateQte = (data) => {
    setQuantities(data);
  }

  useEffect(() => {
      getProducts();
    getListStock();

    // console.log("ARY ATO VE MIOVA", quantities)

    // formFilter.setFieldsValue({
    //     category: 'All'
    // });
  }, []);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setFile(null);
    setFileName(null);
  };

  const submitImport = () => {
    setIsLoading(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    const data = new FormData();
    data.append("catalog_file", file);
    Axios.post(`${BASE_URL}/api/v1/catalog/import`, data, { headers })
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          let type = "success";
          let mess = response.data?.message;
          Utils.notif(type, mess);
        }
        setOpen(false);
        getProducts();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response.data.errors.email[0]);
        if (error.response.data.errors.email != null) {
          let type = "error";
          let mess = error.response.data.errors.email[0];
          Utils.notif(type, mess);
        }
        // setOpen(false);
      });
  };

  const onImportExcel = (event) => {
    const { files } = event.target;
    if (files.length >= 1) {
      setFile(files[0]);
      setFileName(files[0].name);
    }
  };

  const downloadTemplate = () => {
    // replace the file path and name with your own
    const filePath = "/files/Template-import-catalogues.xlsx";
    const fileName = "Template-import-catalogues.xlsx";
    const fileUrl = `${process.env.PUBLIC_URL}${filePath}`;

    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const addButton = (
    <>
      <Link
        to={{
          pathname: `/dashboard/items/new`,
          state: { modal: true },
        }}
      >
        <Button type="primary" onClick={() => null} className="btn-shadow">
          List of inQuery
        </Button>
      </Link>
    </>
  );

  const getProducts = (filterData = {}) => {
    setIsLoadingData(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };

    axios
      .post(`${BASE_URL}/api/v1/catalog/list`, { filterData }, { headers })
      .then((res) => {
        const productData = res.data?.data;
        setProducts(productData);
        setIsLoadingData(false);
      })
      .catch((error) => {
        setIsLoadingData(false);
        console.log(error);
      });
  };
  const onSearch = (value) => {
    const text = value.toLowerCase();
    setWordSearch(text);
  };

  // const getCategory = () => {
  //   const Authorization = localStorage.getItem("token");
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + Authorization,
  //   };
  //   axios
  //     .get(`${BASE_URL}/api/v1/category/list?parent_id=`, { headers })
  //     .then((res) => {
  //       const categoryData = res.data?.data;
  //       let tab = [];
  //       categoryData.forEach((obj) => {
  //         const newObj = { ...obj, value: obj.id };
  //         tab.push(newObj);
  //       });
  //       setCategory(tab);
  //       console.log(tab);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const changeInstock = (e) => {
    console.log(e);
  };


  const totalStock = (item) => {
    const total = parseInt(
      item?.stocks?.in_stock[0]?.quantity
        ? item?.stocks?.in_stock[0]?.quantity
        : 0
    );

    return total;
  };

  const confirmAsOrder = (e) => {
    const localInquery = window.localStorage.getItem('inquery');
    const dataToSend = [];
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };

    if(localInquery) {
      JSON.parse(localInquery).map(li => {
        const findProduct = products.find(p => parseInt(p.id) === li.request[0].productId);
        if(findProduct) {
          const tabRequestPerColor = li.request.map(lr => {
            const findColor = findProduct.colors.find(color => color.id === lr.colorId);
            if(findColor) {
              const reqT = li.reqTotal.find(lir => lir.colorId === findColor.id);
              const max = (reqT.totalLeft + reqT.totalRequest);
              return {
                color: findColor,
                variant: findColor.variantes.find(cv => cv.value === lr.size),
                in: lr.quantity,
                max,
              }
            }
            return null;
          })
          dataToSend.push({
            product: findProduct,
            commande: tabRequestPerColor
          })
        }
        return true
      })
    }

    if(!localInquery || dataToSend.length === 0) {
      message.error('Please, make a simulation');
    }

    if(dataToSend.length > 0) {
      const hide = message.loading('Action in progress..', 0, () => {
        message.success('Item added to order');
      });
      Axios.post(`${BASE_URL}/api/v1/inquiry/add`, {data_json: JSON.stringify(dataToSend)}, { headers })
      .then(response => {
        localStorage.removeItem('inquery');
        localStorage.removeItem('colorShown');
        setTimeout(hide, 2500)
        setTimeout(() => window.location.reload() , 5000);
      })
    }

  };
  const cancelAsOrder = (e) => {
    console.log(e);
  };

  const getLastObjectWithNullColorId = (data) => {
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i].color_id === null) {
        return data[i].path;
      }
    }
    return null; // If no object with color_id = null is found
  }

  return (
    <>
      <Card
        className="card-items"
        style={{
          paddingBottom: 100
        }}
        title={
          <div className="row">
            <div className="col-md-12">
              <Title level={3}>{props.t("list_items")}</Title>
            </div>
            {/* <div className="col-md-6 col-lg-4">
              <Search
                placeholder={props.t("search_for_catalogue")}
                enterButton
                onKeyUp={(event) => onSearch(event.target.value)}
              />
            </div> */}
          </div>}
      >
        <Card 
          style={{top:'46px',position:'sticky',zIndex:'99',marginBottom:'1px'}} 
          bodyStyle={{ paddingBottom: '0.25rem', paddingTop: '0.25rem', paddingLeft: '0.25rem' }}>
          <div className="col-md-6 col-lg-4 pl-0">
            <Search
              placeholder={props.t("search_for_catalogue")}
              enterButton
              onKeyUp={(event) => onSearch(event.target.value)}
            />
          </div>
        </Card>
        <div className="row mt-2">
          <div className="col-md-4 col-12 items-inquery">
            <List
              grid={{ gutter: 16, xs: 2, sm: 2, md: 3, lg: 3, xl: 3, xxl: 3 }}
              dataSource={products.filter((item) => {
                if (
                  item.reference.toLowerCase().includes(wordSearch) ||
                  item.name.toLowerCase().includes(wordSearch)
                ) {
                  return true;
                }
                return false;
              })}
              pagination={{ hideOnSinglePage: true, pageSize: 8 }}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    className="card-item"
                    hoverable={true}
                    onClick={() => {
                      scroller.scrollTo(item.name, {
                        duration: 800,
                        delay: 0,
                        smooth: 'easeInOutQuart'
                      })
                    }}
                    style={{ textAlign: "center", padding: "1px" }}
                    cover={
                      // URL_DEFAULT_IMAGE
                      // item.images[0] ? (
                      //   <img
                      //     alt="images"
                      //     src={BASE_URL + item?.images[0].path}
                      //   />
                      // ) : (
                      //   <img
                      //     alt="images"
                      //     src="https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg"
                      //   />
                      // )
                      <div 
                          className="cover-product"
                          // URL_DEFAULT_IMAGE
                          style={{ 
                              backgroundImage: `url("${getLastObjectWithNullColorId(item.images) ? BASE_URL+getLastObjectWithNullColorId(item.images) : URL_DEFAULT_IMAGE}")`,
                          }}
                      />
                    }
                  >
                    <Meta
                      title={
                        <>
                          <b>{item.name}</b>
                          <br />
                          <small>{item.reference}</small>
                        </>
                      }
                    />
                  </Card>
                </List.Item>
              )}
              loading={{
                indicator: <LoadingOutlined />,
                spinning: isLoadingData,
              }}
            />
          </div>
          <div className="col-md-8 col-12">
            {
              products && products.filter((item) => {
                if (
                  item.reference.toLowerCase().includes(wordSearch) ||
                  item.name.toLowerCase().includes(wordSearch)
                ) {
                  return true;
                }
                return false;
              }).map((item) => (
                  <div key={`prod-${item.id}`}>
                    <ItemInquery
                      data={item}
                      stock={stockList}
                    />
                  </div>
              ))
            }
          </div>
        </div>
        {
          props.inquery.length !== 0 ? (
            <div className="row mt-5">
              <div className="col-12">
                <ListChooseProductInquerry {...props} quantities={quantities} setQuantities={setQuantities} data={itemQuery} />
              </div>
            </div>
          ) : (<></>)
        }
      </Card>
      <div
        style={{
          position: 'fixed',
          bottom: 20,
          left: 20,
          right: 20
        }}
      >
        <Row style={{width: '100%'}} align='middle'>
          <Col span={12}>
            <Popconfirm
              title="Add as order?"
              onConfirm={confirmAsOrder}
              onCancel={cancelAsOrder}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: "orange",
                  fontSize: "70%",
                }}
              >
                REQUEST PRODUCTION LEAD TIME TO FACTORY
              </Button>
            </Popconfirm>
          </Col>
          <Col span={12}>
            <Button className="float-right" type="primary" shape="circle" icon={<ArrowUpOutlined />} onClick={() => scroll.scrollToTop()} />
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = ({ inquery }) => {
  return {
    inquery: inquery.inquery
  }
}

const mapDispatchToProps = {
  ...InqueryActions
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Inquerry));
