import { withTranslation } from "react-i18next";
import logoYs from '../../../assets/img/logoYS.jpg';
const Facture = (props) => {
  return (
    <>
        <div className="pl-5 pr-5" style={{backgroundColor:'#e7e7e7'}}>
            <div className="row mb-3">
                <div className="col-md-6" style={{padding:'10px'}}>
                    <img src={logoYs}
                        width="250"
                        alt="Yachting Spirit logo"
                        title="Yachting Spirit"
                    />
                </div>
                <div className="col-md-6">
                    <h1 style={{fontWeight:'bold',fontSize:'45px',marginBottom:'5px'}}>FACTURE</h1>
                    <div>
                        <p style={{marginBottom:'0',fontSize:'18px'}}><strong>Numéro du facture</strong> : id-001</p>
                        <p style={{marginBottom:'0',fontSize:'14px'}}><strong>Date de facturation</strong> : 14/12/2023</p>
                        <p style={{marginBottom:'0',fontSize:'14px'}}><strong>Echeance</strong> : 14/12/2023</p>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <h2 style={{fontWeight:'bold',fontSize:'25px',marginBottom:'0px'}}>Facturé à</h2>
                    <strong>John Leong</strong>
                    <p style={{width:'250px'}}><strong>Address</strong> xxxxxxxxxxxxxxxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
                </div>
                <div className="col-md-6">
                    <h2 style={{fontWeight:'bold',fontSize:'25px',marginBottom:'0px'}}>Envoyé à</h2>
                    <strong>John Leong</strong>
                    <p style={{width:'250px'}}><strong>Address</strong> xxxxxxxxxxxxxxxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
                </div>
            </div>

            <div className="row">
                
            </div>
        </div>
    </>
  );
}

export default withTranslation()(Facture);
