import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Select,
  Form,
  Input,
  Card,
  Typography,
  Row,
  Col,
  Tag,
  Modal,
  Popconfirm,
  Table,
  Upload,
  Space,
  Radio,
  Checkbox,
} from "antd";
import BASE_URL from "../../../config.js";
import { Utils } from "../../../services/Utils.js";
import "../../../assets/css/addItems.css";
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { Title,Text } = Typography;

const AddItem = (props) => {
  const [formCreateItem] = Form.useForm();
  const [formVariant] = Form.useForm();
  const [selectedRange, setSelectedRange] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileNameC, setFileNameC] = useState(null);
  const [catalog_file, setCatalog_file] = useState(null);
  const [category, setCategory] = useState([]);
  const [colorTab, setColorTab] = useState([]);
  const [colorTabT, setColorTabT] = useState([]);
  const [colors, setColors] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [columns, setColumns] = useState([]);
  const [imageColor, setImageColor] = useState(null);
  const [sizeAdd, setSizeAdd] = useState(null);

  const history = useHistory();
  const [valueStocks, setValueStocks] = useState([]);
  const [checkeds, setCheckeds] = useState([]);
  const [fabricSelected, setFabricSelected] = useState();
  const [colorSelected, setColorSelected] = useState(null);
  const [sizeMin, setSizeMin] = useState(32);
  const [sizeMax, setSizeMax] = useState(54);
  const [weeks, setWeeks] = useState([]);
  const [weekSelected, setWeekSelected] = useState();
  const [weekSelected1, setWeekSelected1] = useState();
  const [weekMin, setWeekMin] = useState();
  useEffect(() => {
    getCategory();
    setColumns(columnsList);
    getFabrics();
    // setCheckeds(size);
    getWeeks();
    getWeekNumber(new Date());
  }, []);

  const getWeekNumber = (d) => {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    setWeekMin(weekNo);
  }
  
  const changeWeek1 = (value)=>{
    setWeekSelected1(value);
  }

  const getWeeks = () => {
    let weeksR = [];
    for(let i=1;i<=52;i++){
      weeksR.push(i);
    }
    setWeeks(weeksR);
  };

  const getCategory = () => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .get(`${BASE_URL}/api/v1/category/list?parent_id=`, { headers })
      .then((res) => {
        const categoryData = res.data?.data;
        setCategory(categoryData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveItems = (value) => {
    console.log("value",value);
    console.log("valueStocks",valueStocks);
    console.log("checkeds",checkeds);
    console.log("colors",colors);
    console.log("fabricSelected",fabricSelected);
    console.log("catalog_file",catalog_file);
    console.log("stock",stockColor);
    console.log("weekSelected",weekSelected);
    console.log("weekSelected1",weekSelected1);
    const {
      name,
      reference,
      description,
      matiere,
      category_id,
      range,
      famille,
      sale_price,
      purchase_price,
    } = value;
    // if(weekSelected==undefined){
    //   let type = "error";
    //   Utils.notif(type, "Please, add week restock 1!");
    //   return;
    // }
    // if(weekSelected1==undefined){
    //   let type = "error";
    //   Utils.notif(type, "Please, add week restock 2!");
    //   return;
    // }
      // let type = "success";
      // Utils.notif(type, "Entity created successfully.");
      // return;
    // if (COLORTABS.length === 0) {
    //   let type = "error";
    //   Utils.notif(type, "Please, add color!");
    //   return;
    // }
    // console.log("catalog_file",catalog_file);
    // console.log("value",value);
    // console.log("value",colorTab);
    // return;
    setIsLoading(true);
    // const { name, reference, description, matiere, category_id, range } =
    //   value;
    
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    var image = [];
    image.push(catalog_file);
    console.log(image)
    const data = new FormData();
    data.append("name", name);
    data.append("reference", reference);
    data.append("description", description);
    data.append("fabricant_id", matiere);
    data.append("category_id", category_id);
    data.append("catalog_files[]", catalog_file);
    data.append("range", selectedRange);
    data.append("famille", famille);
    data.append("ressel_price", sale_price);
    data.append("purchase_price", purchase_price);

    stockColor.map((item,i)=>{
        data.append("colors["+i+"][label]",item.label);
        data.append("colors["+i+"][color_file]",item.color_file);
        item.variantes.map((items,j)=>{
            if(items.future_one_week==null){
              let type = "error";
              Utils.notif(type, "Please, add week restock 1!");
              return;
            }
            if(items.future_two_week==null){
              let type = "error";
              Utils.notif(type, "Please, add week restock 2!");
              return;
            }
            data.append("colors["+i+"][variantes]["+j+"][key]",items.key);
            data.append("colors["+i+"][variantes]["+j+"][value]",items.value);
            data.append("colors["+i+"][variantes]["+j+"][in_stock_quantity]",items.in_stock_quantity?items.in_stock_quantity:0);
            data.append("colors["+i+"][variantes]["+j+"][future_one_quantity]",items.future_one_quantity?items.future_one_quantity:0);
            data.append("colors["+i+"][variantes]["+j+"][future_two_quantity]",items.future_two_quantity?items.future_two_quantity:0);
            data.append("colors["+i+"][variantes]["+j+"][future_one_week]",items.future_one_week);
            data.append("colors["+i+"][variantes]["+j+"][future_two_week]",items.future_two_week);
        })
    })

    for (var pair of data.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
    }
    // return;
    axios
      .post(`${BASE_URL}/api/v1/catalog/add`, data, { headers })
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          let type = "success";
          let mess = response.data?.message;
          Utils.notif(type, mess);

          clearForm();
          history.push("/dashboard/items");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.data.errors.email != null) {
          let type = "error";
          let mess = error.response.data.errors.email[0];
          Utils.notif(type, mess);
        }else{
          let type = "error";
          Utils.notif(type, "Error");
        }
      });
  };

  const onChangeImage = (event) => {
    const { files } = event.target;
    if (files.length >= 1) {
      setCatalog_file(files[0]);
      setFileName(files[0].name);
    }
  };

  const clearForm = () => {
    formCreateItem.setFieldsValue({
      reference: null,
      name: null,
      description: null,
      matiere: null,
      default_iamge: null,
      categorie_id: null,
      category: null,
      variantes: null,
    });
  };

  const clearFormColor = () => {
    formVariant.setFieldsValue({
      label: null,
      image: null,
      fields: null,
      photo: null,
    });
    setImageColor(null);
  };

  const onFinish = (values) => {
    // let field = "";
    // let tabColor = COLORTABS;
    // // setColorTab([]);
    // if (imageColor == null) {
    //   let type = "error";
    //   Utils.notif(type, "Please, add image color!");
    //   return;
    // }
    // if (!values.fields) {
    //   let type = "error";
    //   Utils.notif(type, "Please, add one field!");
    //   return;
    // }
    // values.fields.map((item, i) => {
    //   if (i === 0) {
    //     field = item.size;
    //   } else {
    //     field += "," + item.size;
    //   }
    // });
    // let test = true;
    // tabColor.map((item) => {
    //   if (item.label == values.label) {
    //     test = false;
    //   }
    // });
    // if (tabColor.length == 0 || test) {
    //   tabColor.push({ ...values, field: field, image: imageColor });
    // }
    // if (test == false) {
    //   let type = "error";
    //   Utils.notif(type, "Color already used!");
    // } else {
    //   setIsModalOpen2(false);
    //   clearFormColor();
    //   setFileNameC(null);
    // }
    // // setColor(tabColor);
    // setColorTabT([...tabColor]);
    // COLORTABS = [...tabColor];
    // setColorTab([...tabColor]);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const confirmDeleteColor = (labelColor) => {
    // let tabColor = COLORTABS;
      
    //   let tempTab = tabColor.length > 0 && tabColor.filter(item => item.label !== labelColor);


    //   COLORTABS = tempTab;
    //   setColorTab(tempTab);

  };

  const cancelDelete = (e) => {
    console.log(e);
  };

  const changeImage = (event) => {
    const { files } = event.target;
    if (files.length >= 1) {
      setImageColor(files[0]);
      setFileNameC(files[0].name);
    }
  };

  const getFabrics = () => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .get(`${BASE_URL}/api/v1/fabricant/list`, { headers })
      .then((res) => {
        const fabrics = res.data?.data;
        setFabrics(fabrics);
        // setFabrics(fabricsT);
        console.log(fabrics);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const couleur = [
    {
      id: "1",
      label: "COTTON",
      decription: "Description",
      color_possible: "1",
    },
    {
      id: "2",
      label: "AUTRES",
      decription: "Description",
      color_possible: "2",
    },
  ];

  const getColors = (id) => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .get(`${BASE_URL}/api/v1/color-list?fabricant_id=` + id, { headers })
      .then((res) => {
        const colors = res.data?.data;
        // setColors(couleur);
        // setImageColors(couleur);
        // console.log("couleur",couleur)
        // console.log("color",colors)
        // console.log("imageColors",imageColors)
        setColors(colors);
        // setColorSelected(colors[0]);
        console.log("colors",colors);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // const size = [
  //   { id: "1", label: "XL", check: false },
  //   { id: "2", label: "3XL", check: false },
  //   { id: "2", label: "M", check: false },
  //   { id: "2", label: "4XL", check: false },
  // ];

  

  const onChangeDImage = (event) =>{
    var photo = Object.assign(event.target.files[0], {
      preview: URL.createObjectURL(event.target.files[0]),
    });
    setCatalog_file(photo);
  }
  const choseValueStock = (event, item, col) => {
    var tempValues = [...valueStocks];

    var temp = {
      color: col.label,
      size: item.label,
      stock: event.target.value,
    };
    var test = false;
    tempValues.map((val, i) => {
      if (val.color == col.label && val.size == item.label) {
        tempValues[i].stock = event.target.value;
        test = true;
      }
    });
    if (test == false) {
      tempValues.push(temp);
    }

    setValueStocks(tempValues);
    console.log("TY", tempValues);
  };

  const changeFabric = (value) => {
      setFabricSelected(value);
      getColors(value);
      getSizeSelect(selectedRange);
  };

  const onChecked = (size) => {
    var tempCheck = [...checkeds];
    tempCheck.map((item, i) => {
      if (size == item.label) {
        tempCheck[i].check = !tempCheck[i].check;
      }
    });
    setCheckeds(tempCheck);
    console.log("tempCheck", tempCheck);
  };


  const [allSize, setAllSize] = useState([]);

  
  const choseRange = (event) =>{
    setSelectedRange(event.target.value);
    getSize(event.target.value,colorSelected);
    getSizeSelect(event.target.value);
    
  }
  const [allSizeSelect, setAllSizeSelect] = useState([]);
  const [allSizeSelectAdd, setAllSizeSelectAdd] = useState([]);
  const getSizeSelect = (rangeArg) =>{
    
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .get(`${BASE_URL}/api/v1/size-list?range=${rangeArg}`, { headers })
      .then((res) => {
        const sizeR = res.data?.data;
        setAllSizeSelect([...sizeR]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const isAdded = (size,color) => {
    stockColor.map((item)=>{
      console.log("itemIsAdded",item)
      console.log("sizeIsAdded",size)
      console.log("colorIsAdded",color)
      if(item.label==color.label){
        item.variantes.map((itemV)=>{
          if(itemV.value==size){
            alert("TRUE")
            return true;
          }
        })
      }
    })
    return false;
  }
  const getSize = (rangeArg,colorArg) =>{
    if(colorArg!=null){
      const Authorization = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Authorization,
      };
      axios
        .get(`${BASE_URL}/api/v1/size-list?range=${rangeArg}`, { headers })
        .then((res) => {
          const sizeR = res.data?.data;
          var resR = [];
          sizeR.map((item)=>{
            if(item.size>=sizeMin && item.size<=sizeMax){
              console.log("itemSize",item)
              resR.push(item);
            }
          })
          // Ato amzay miasa manao init
          init(resR,colorArg);
          setAllSize([...resR]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  const addLine = ()=>{
    console.log("colorSelected",colorSelected)
    console.log("sizeAdd",sizeAdd);
    if(sizeAdd==undefined){
      let type = "error";
      Utils.notif(type, "Chose size added");
      return;
    }
    const temp = [...stockColor];
    const res = {
      key:"SIZE",
      value: sizeAdd,
      in_stock_quantity:null,
      future_one_quantity:null,
      future_two_quantity:null,
      future_one_week:null,
      future_two_week:null,
    };
    temp.map((item,i)=>{
      console.log("ITEMAD",item)
      console.log("colorSelected",colorSelected)
      if(item.label==colorSelected.label){
        temp[i].variantes.push(res);
      }
    })
    setStockColor([...temp]);
    var rest = [];
    allSizeSelect.map((itemA)=>{
      temp.map((item)=>{
        
        if(item.label==colorSelected.label){
          var isEx = false;
          item.variantes.map((itemV)=>{
            if(itemV.value==itemA.name){
              isEx = true;
            }
          })
          if(isEx==false){
            rest.push(itemA);
          }
        }
      })
    })

    setAllSizeSelectAdd([...rest]);
    setSizeAdd(null);
    // getSize(selectedRange,colorSelected);
    // const res = {
    //   color:colorArg,
    //   label:colorArg.label,
    //   color_file:null,
    //   variantes:variants,
    // };
    // var isExist = false;
    // temp.map((item,i)=>{
    //   if(item.color.id==colorArg.id){
    //     temp[i] = res;
    //     isExist = true;
    //   }
    // })
    // if(!isExist){
    //   temp.push(res);
    // }
    // console.log(temp)
   
  }
  const init = (sizeArg,colorArg) =>{
    const temp = [...stockColor];
    var variants = [];
    sizeArg.map((item)=>{
      const res = {
        key:"SIZE",
        value: item.name,
        in_stock_quantity:null,
        future_one_quantity:null,
        future_two_quantity:null,
        future_one_week:null,
        future_two_week:null,
      };
      variants.push(res);
    })
    const res = {
      color:colorArg,
      label:colorArg.label,
      color_file:null,
      variantes:variants,
    };
    var isExist = false;
    temp.map((item,i)=>{
      if(item.color.id==colorArg.id){
        temp[i] = res;
        isExist = true;
      }
    })
    if(!isExist){
      temp.push(res);
    }
    var rest = [];
    allSizeSelect.map((itemA)=>{
      temp.map((item)=>{
        
        if(item.label==colorArg.label){
          var isEx = false;
          item.variantes.map((itemV)=>{
            if(itemV.value==itemA.name){
              isEx = true;
            }
          })
          if(isEx==false){
            rest.push(itemA);
          }
        }
      })
    })

    setAllSizeSelectAdd([...rest]);
    setStockColor([...temp]);
  }

  // const changeMin = (event) =>{
  //   if(event.target.value==''){
  //     setSizeMin(32);
  //   }else{
  //     setSizeMin(event.target.value);
  //   }
  // }

  // const changeMax = (event) =>{
  //   alert(event.target.value)
  //   if(event.target.value==''){
  //     setSizeMax(54);
  //   }else{
  //     setSizeMax(event.target.value);
  //   }
  // }

  const changeMin = (value) =>{
    if(value==undefined){
      setSizeMin(32);
    }else{
      setSizeMin(value);
    }
  }

  const changeMax = (value) =>{
    if(value==undefined){
      setSizeMax(54);
    }else{
      setSizeMax(value);
    }
  }

  const columnsList = [
    {
      title: props.t("name"),
      dataIndex: "label",
      key: "label",
      //   sorter: (a, b) => a.label.localeCompare(b.label),
    },
    {
      title: "Fields",
      dataIndex: "field",
      key: "field",
    },
    {
      title: props.t("actions"),
      dataIndex: "label",
      render: (text) => {
        return (
          <>
            {/* <Link
                    to={{
                      pathname: `/dashboard/users/${text}`,
                      state: { modal: true },
                    }}
                  > 
                    <Button
                      type="primary"
                      onClick={() => null}
                      shape="circle"
                      className="mr-3"
                      icon={<EditOutlined />}
                    ></Button>
                  </Link> */}
            <Popconfirm
              title="Are you sure to delete this color ?"
              description=""
              onConfirm={() => confirmDeleteColor(text)}
              onCancel={cancelDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="danger"
                onClick={() => null}
                shape="circle"
                icon={<DeleteOutlined />}
              ></Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const choseImageColor = (event, color) => {
    const temp = [...stockColor];
    var photo = Object.assign(event.target.files[0], {
      preview: URL.createObjectURL(event.target.files[0]),
    });
    
    temp.map((item, i) => {
      if (item.color.id == color.id) {
        temp[i] = { ...temp[i], color_file: photo };
      }
    });
    setStockColor([...temp]);
  };

  const isNotNull = (item) =>{
    stockColor.map((itemStock)=>{
      if(itemStock.color.id==item.id){
        return (<label className="selected-color">{item.label}</label>)
      }
    })
    return (<label>{item.label}</label>)
  }

  const choseColor = (color) =>{
    const temp = [...stockColor];
    const stock = {
      color: color,
    };
    var isExist = false;
    console.log("color",color)
    temp.map((item)=>{
      console.log("item",item)
      if(item.color.id==color.id){
        isExist = true;
      }
    })
    
    if(isExist==false){
      temp.push(stock);
      getSize(selectedRange,color);
    }
    setColorSelected(color);
    setStockColor([...temp]);
    setWeekSelected(null);
    setWeekSelected1(null);
    console.log("STOCK",temp);
    
    console.log("allSizeSelect",temp);

    // var rest = [];

    // allSizeSelect.map((itemA)=>{
    //   temp.map((item)=>{
    //     console.log("colorSelected",color)
    //     console.log("item",item)
    //     if(item.color.label==color.label){
    //       var isEx = false;
    //       item?.variantes?.map((itemV)=>{
    //         if(itemV.value==itemA.name){
    //           isEx = true;
    //         }
    //       })
    //       if(isEx==false){
    //         rest.push(itemA);
    //       }
    //     }
    //   })
    // })

    // setAllSizeSelectAdd([...rest]);
    // temp.map((item)=>{
    //   if(item.label==colorSelected.label){
    //     allSizeSelect.map((itemA)=>{
    //       item.variantes.map((itemV)=>{
    //         var isEx = false;
    //       if(itemV.value==itemA.name){
    //         isEx = true;
    //       }
    //       })
    //       if(isEx==false){
    //         rest.push()
    //       }
    //     })
    //   }
    // })
  }

  const getChoseSize = (color) =>{
    const temp = [...stockColor];
    console.log("tempA",temp)
    console.log("colorA",color)
    
  }

  const deleteColor = (color) =>{
    // const temp = [...stockColor];
    // const tempE = [];
    // temp.map((item)=>{
    //   if(item.color.id!=color.id){
    //     tempE.push(item);
    //   }
    // })
    // setStockColor([...tempE]);
    // if(tempE.length>0){
    //   setColorSelected(tempE[0]);
    //   getSize(selectedRange,tempE[0]);
    // }else{
    //   setColorSelected(null);
    // }
    
  }

  const change = (value,stock,sizeArg,colorArg) =>{
    const temp = [...stockColor];
    // console.log("value",value)
    // console.log("stock",stock)
    // console.log("sizeArg",sizeArg)
    // console.log("colorArg",colorArg)
    // console.log("temp",colorArg)
    temp.map((item,i)=>{
      if(item.color.id==colorArg.id){
        item.variantes.map((tem,j)=>{
          if(tem.value==sizeArg.value){
            // alert(value)
            if(stock=='in'){
              temp[i].variantes[j].in_stock_quantity = parseInt(value);
            }
            if(stock=='one'){
              temp[i].variantes[j].future_one_quantity = parseInt(value);
            }
            if(stock=='two'){
              temp[i].variantes[j].future_two_quantity = parseInt(value);
            }
          }
        })
      }
    })
    setStockColor([...temp]);
  }

  const changeWeekR1 = (value,colorArg)=>{
    setWeekSelected(value);
    const temp = [...stockColor];
    temp.map((item,i)=>{
      if(item.color.label==colorSelected.label){
        item.variantes.map((tem,j)=>{
          temp[i].variantes[j].future_one_week = parseInt(value);
        })
      }
    })
    console.log(temp);
    setStockColor([...temp]);
  }

  const changeWeekR2 = (value)=>{
    setWeekSelected(value);
    const temp = [...stockColor];
    temp.map((item,i)=>{
      if(item.color.label==colorSelected.label){
        item.variantes.map((tem,j)=>{
          temp[i].variantes[j].future_two_week = parseInt(value);
        })
      }
    })
    console.log(temp);
    setStockColor([...temp]);
  }


  const choseR1 = (event,sizeArg,colorArg) =>{
    change(event.target.value,'r1',sizeArg,colorArg);
  }

  const choseR2 = (event,sizeArg,colorArg) =>{
    change(event.target.value,'r2',sizeArg,colorArg);
  }

  const choseImbretex = (event,sizeArg,colorArg) =>{
    change(event.target.value,'in',sizeArg,colorArg);
  }

  const chose2Weeks = (event,sizeArg,colorArg) =>{
    change(event.target.value,'one',sizeArg,colorArg);
  }

  const chose4Weeks = (event,sizeArg,colorArg) =>{
    change(event.target.value,'two',sizeArg,colorArg);
  }

  const [stockColor, setStockColor] = useState([]);

  const confirmDeleteLine = (item,color) => {
    // console.log("ItemD",item);
    // console.log("colorD",color);
    // console.log("stockColorD",stockColor);
    var temp = [];
    const stockColorT = [...stockColor];
    stockColorT.map((itemS,i)=>{
      if(itemS.color.id==color.id){
        itemS.variantes.map((itemV,j)=>{
          if(itemV.value!=item.value){
            temp.push(itemV);
          }
        })
        stockColorT[i].variantes = temp;
      }
    })
    var rest = [];
    allSizeSelect.map((itemA)=>{
      stockColorT.map((item)=>{
        
        if(item.label==colorSelected.label){
          var isEx = false;
          item.variantes.map((itemV)=>{
            if(itemV.value==itemA.name){
              isEx = true;
            }
          })
          if(isEx==false){
            rest.push(itemA);
          }
        }
      })
    })

    setAllSizeSelectAdd([...rest]);
    setSizeAdd(null);
    setStockColor([...stockColorT]);
  };


  return (
    <>
      <Card className="card-add__item" title={<Title level={3}>{props.t("add_items")}</Title>}>
        <Form
          form={formCreateItem}
          name="formCreateItem"
          layout="vertical"
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={saveItems}
          onFinishFailed={null}
          autoComplete="off"
        >
          <h4 style={{ marginBottom: "20px" }}>Basic information :</h4>
          <div className="row basic-infos">
            <div className="col-md-6 col-12">
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label={props.t("reference")}
                name="reference"
                className="perso"
                rules={[
                  {
                    required: true,
                    message: "Please input reference!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label={props.t("famille")}
                name="famille"
                rules={[
                  {
                    required: true,
                    message: "Please select famille!",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={props.t("famille")}
                  showSearch
                  optionFilterProp="children"
                >
                  <Select.Option key={`key-1`} value="1">
                    KEY PRODUCT
                  </Select.Option>
                  <Select.Option key={`key-2`} value="2">
                    ON ORDER PRODUCT
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label={props.t("categorie")}
                name="category_id"
                rules={[
                  {
                    required: true,
                    message: "Please select category!",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={props.t("categorie")}
                  showSearch
                  optionFilterProp="children"
                >
                  {category &&
                    category.map((item) => (
                      <Select.Option key={`key-${item.id}`} value={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>


              <Form.Item name="catalog" label="Default Image" labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}>
                {catalog_file!=null && (
                    <label
                      htmlFor="default_image"
                      style={{
                        backgroundImage: `url("${catalog_file.preview}")`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                      className="drop-container-image-default"
                    >
                      <Text>+</Text>
                      <Input
                        style={{ display: "none" }}
                        id="default_image"
                        className="file-uploader"
                        type="file"
                        accept="image/*"
                        onChange={onChangeDImage}
                      />
                    </label>
                  )}
                  {catalog_file==null && (
                    <label htmlFor="default_image" className="drop-container-image-default">
                    <Text>+</Text>
                    <Input
                      style={{ display: "none" }}
                      id="default_image"
                      className="file-uploader"
                      type="file"
                      accept="image/*"
                      onChange={onChangeDImage}
                    />
                  </label>
                  )}
              </Form.Item>
            
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label={props.t("fabric")}
                name="matiere"
                rules={[
                  {
                    required: true,
                    message: "Please input fabric!",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={props.t("fabric")}
                  showSearch
                  optionFilterProp="children"
                  onChange={changeFabric}
                >
                  {fabrics &&
                    fabrics.map((item) => (
                      <Select.Option key={`key-${item.id}`} value={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-6 col-12">
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label={props.t("name")}
                name="name"
                className="perso"
                rules={[
                  {
                    required: true,
                    message: "Please input name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label={props.t("sale_price")}
                className="perso"
                name="sale_price"
                rules={[
                  {
                    required: true,
                    message: "Please input sale price!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                // labelCol= { xs: { span: 24 }, sm: { span: 12 }, md: { span: 8 }, lg: { span: 8 } }
                wrapperCol={{ span: 16 }}
                label={props.t("purchase_price")}
                className="perso"
                name="purchase_price"
                rules={[
                  {
                    required: true,
                    message: "Please input purchase price!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                // wrapperCol={{xs: { span: 24 }, sm: { span: 12 }, md: { span: 23 }, lg: { span: 23 }}}
                className="card-add__item-description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input description!",
                  },
                ]}
                label={props.t("description")}
              >
                <Input.TextArea rows={2} />
              </Form.Item>
              <Form.Item label={props.t("style")} name="range" 
                rules={[
                  {
                    required: true,
                    message: "Chose style!",
                  },
                ]}>
                <Radio.Group onChange={choseRange}>
                  <Radio value="MAN">MAN</Radio>
                  <Radio value="WOMAN">WOMAN</Radio>
                </Radio.Group>
                {/* <Input /> */}
              </Form.Item>
            </div>
          </div>
          

          {fabricSelected != null && selectedRange &&(
            <>
              <div className="variants">
                <div className="row">
                  <div className="col-md-4">
                  <Form.Item
                    labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                    label="Size min"
                    name="size_min"
                  >
                    <Select
                      allowClear
                      placeholder="Size min"
                      showSearch
                      optionFilterProp="children"
                      onChange={changeMin}
                    >
                      {allSizeSelect &&
                        allSizeSelect.map((item) => (
                          <Select.Option key={`key-${item.id}`} value={item.size}>
                            {item.name} ({item.size})
                          </Select.Option>
                        ))}
                    </Select>
                    {/* <Input onChange={(event)=>{changeMin(event)}} type="number" /> */}
                  </Form.Item>
                  <Form.Item
                    labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                    label="Size max"
                    name="size_max"
                  >
                    <Select
                      allowClear
                      placeholder="Size min"
                      showSearch
                      optionFilterProp="children"
                      onChange={changeMax}
                    >
                      {allSizeSelect &&
                        allSizeSelect.map((item) => (
                          <Select.Option key={`key-${item.id}`} value={item.size}>
                            {item.name} ({item.size})
                          </Select.Option>
                        ))}
                    </Select>
                    {/* <Input onChange={(event)=>{changeMax(event)}} type="number" /> */}
                  </Form.Item>
                  </div>
                  <div className="col-md-8">
                    <div style={{marginBottom:'5px'}} className="variants-title">
                      <span>Chose color :</span>
                    </div>
                    <div className="variants-lists">
                      {
                        colors.map((item)=>
                          {
                            if(item.label==colorSelected?.label){
                              return (
                                <div onClick={()=>{choseColor(item)}} className="variants-miniature active">
                                  <div className="row variants-miniature__item" style={{backgroundColor:item.code}}></div>
                                  <div>
                                    
                                      <label>{item.label}</label>
                                      {
                                        stockColor.map((itemStock)=>{
                                          if(itemStock.color.id==item.id){
                                            return (<hr className="selected-color"></hr>)
                                          }
                                        })
                                      }
                                    
                                  </div>
                                </div>
                              );
                            }else{
                              return (
                                <div onClick={()=>{choseColor(item)}} className="variants-miniature">
                                  <div className="row variants-miniature__item" style={{backgroundColor:item.code}}></div>
                                  <div>
                                    <label>{item.label}</label>
                                    {
                                      stockColor.map((itemStock)=>{
                                        if(itemStock.color.id==item.id){
                                          return (<hr className="selected-color"></hr>)
                                        }
                                      })
                                    }
                                  </div>
                                </div>
                              );
                            }
                          }
                        )
                      }
                    </div>
                    
                  </div>
                </div>
                
              </div> 
              {
                stockColor.map((itemStock)=>{
                  if(itemStock.color.id==colorSelected?.id){
                    return (
                      <>
                        <h4 style={{ marginBottom: "20px" }}>Variants :</h4>
                        <div className="row">
                          <div className="col-md-1 tableau-mark">
                            {/* <div title="Click to delete color in stock" onClick={()=>{deleteColor(colorSelected)}} className="row variants-miniature__mark"> */}
                            <div onClick={()=>{deleteColor(colorSelected)}} className="row variants-miniature__mark">
                              <div className="row variants-miniature__item" style={{backgroundColor:colorSelected.code}}></div>
                              <div>
                                <label>{colorSelected.label}</label>
                              </div>
                            </div>
                            <label
                              title="Chose image"
                              htmlFor={colorSelected.label}
                              style={{
                                backgroundImage: `url("${itemStock?.color_file?.preview}")`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                marginBottom:'10px'
                              }}
                              className="drop-container-image"
                            >
                              <Text>+</Text>
                              <Input
                                style={{ display: "none" }}
                                id={colorSelected.label}
                                className="file-uploader"
                                type="file"
                                accept="image/*"
                                name={colorSelected.label}
                                onChange={(event) => {
                                  choseImageColor(event, colorSelected);
                                }}
                              />
                            </label>
                          </div>
                          <div style={{overflowX:"auto"}} className="col-md-11">
                            <table className="table table-bordered table-variants table-striped">
                              <thead>
                                <tr style={{textAlign:'center'}}>
                                  <th scope="col">Size</th>
                                  <th scope="col">Stock YS</th>
                                  <th scope="col" className="week-input">
                                  <div className="week-input__restock">
                                    RESTOCK 1 :
                                    {
                                      stockColor.map((itemST)=>{
                                        if(itemST.label==colorSelected.label){
                                          if(itemST.variantes.length>0){
                                            return (
                                              <Input type='date' onChange={(event) => changeWeekR1(event.target.value)} className="w-50" />
                                            )
                                          }
                                          
                                        }
                                      })
                                    }
                                  </div>
                                  </th>
                                  <th scope="col" className="week-input">
                                    <div className="week-input__restock">
                                      RESTOCK 2 :
                                      {
                                        stockColor.map((itemST)=>{
                                          if(itemST.label==colorSelected.label){
                                            if(itemST.variantes.length>0){
                                              return (
                                                <Input type='date' onChange={(event) => changeWeekR2(event.target.value)} className="w-50" />
                                              )
                                            }
                                            
                                          }
                                        })
                                      }
                                      {/* <Select
                                        allowClear
                                        placeholder="WEEK"
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={changeWeekR2}
                                      >
                                        { weeks &&
                                          weeks.map((item) => 
                                          {
                                            if(weekSelected==item){
                                              return (
                                                <Select.Option key={`key-${item}`} value={item} disabled>
                                                  W-{item} 
                                                </Select.Option>
                                              )
                                            }else{
                                              return (
                                                <Select.Option key={`key-${item}`} value={item}>
                                                  W{item} 
                                                </Select.Option>
                                              )
                                            }
                                          }
                                          
                                          )}
                                      </Select>  */}
                                    </div>
                                         
                                  </th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  // allSize.map((item)=>(
                                      itemStock?.variantes?.map((itemVariante)=>(
                                        // itemVariante.value==item.name &&(
                                            <>
                                              <tr style={{textAlign:'center'}}>
                                                <th scope="col">{itemVariante.value} </th>
                                                <td>            
                                                  <Input
                                                    placeholder={'INPUT STOCK YS '+itemVariante.value}
                                                    type="number"
                                                    defaultValue={itemVariante.in_stock_quantity}
                                                    onInput={(event) => {
                                                      choseImbretex(event,itemVariante,colorSelected)
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <Input
                                                    placeholder={'INPUT RESTOCK 1 '+itemVariante.value}
                                                    type="number"
                                                    defaultValue={itemVariante.future_one_quantity}
                                                    onInput={(event) => {
                                                      chose2Weeks(event,itemVariante,colorSelected)
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <Input
                                                    placeholder={'INPUT RESTOCK 2 '+itemVariante.value}
                                                    type="number"
                                                    defaultValue={itemVariante.future_two_quantity}
                                                    onInput={(event) => {
                                                      chose4Weeks(event,itemVariante,colorSelected)
                                                    }}
                                                  />
                                                </td>
                                                <th scope="col">
                                                  <Popconfirm
                                                    title="Are you sure to delete this size ?"
                                                    description=""
                                                    onConfirm={()=> {confirmDeleteLine(itemVariante,colorSelected)}}
                                                    onCancel={cancelDelete}
                                                    okText="Yes"
                                                    cancelText="No"
                                                  >
                                                    <DeleteOutlined style={{color:'red'}} />
                                                  </Popconfirm>
                                                </th>
                                              </tr>
                                            </>
                                        // )
                                      ))
                                    // )
                                  // )
                                  
                                  // allSize.map((item)=>(
                                  //   <>
                                  //     <tr style={{textAlign:'center'}}>
                                  //       <th scope="col">{item.name} ({item.size})</th>
                                  //       <td>            
                                  //         <Input
                                  //           defaultValue={null}
                                  //           onInput={(event) => {
                                  //             choseImbretex(event,item,colorSelected)
                                  //           }}
                                  //         />
                                  //         </td>
                                  //       <td>
                                  //         <Input
                                  //           onInput={(event) => {
                                  //             chose2Weeks(event,item,colorSelected)
                                  //           }}
                                  //         />
                                  //       </td>
                                  //       <td>
                                  //         <Input
                                  //           onInput={(event) => {
                                  //             chose4Weeks(event,item,colorSelected)
                                  //           }}
                                  //         />
                                  //       </td>
                                  //     </tr>
                                  //   </>
                                  // ))
                                }
                                                
                              </tbody>
                            </table>
                            <div className="row" style={{float:'right'}}>
                              <div className="m-1">
                                <Select
                                  defaultValue={sizeAdd}
                                  allowClear
                                  placeholder="Chose size"
                                  showSearch
                                  optionFilterProp="children"
                                  onChange={(value)=>{setSizeAdd(value)}}
                                >
                                  {allSizeSelectAdd &&
                                    allSizeSelectAdd.map((itemS) => {
                                      // stockColor.map((item)=>{
                                        // if(item.label==colorSelected.label){
                                        //   item.variantes.map((itemV)=>{
                                        //     if(itemV.value==item.name){
                                              return (
                                                <Select.Option key={`key-${itemS.id}`} value={itemS.name}>
                                                  {itemS.name} ({itemS.size})
                                                </Select.Option>
                                              )
                                          //   }else  {
                                          //     return (
                                          //       <Select.Option key={`key-${itemS.id}`} value={itemS.name}>
                                          //         {itemS.name} ({itemS.size})
                                          //       </Select.Option>
                                          //     )
                                          //   }
                                          // 
                                          //   }
                                          // )
                                        // }
                                      })
                                      // alert(isAdded(item.name,colorSelected));
                                      // if(!isAdded(item.name,colorSelected)){
                                      //   return (
                                      //     <Select.Option key={`key-${item.id}`} value={item.name} disabled>
                                      //       {item.name} ({item.size})
                                      //     </Select.Option>
                                      //   )
                                      // }else{
                                      //   return (
                                      //     <Select.Option key={`key-${item.id}`} value={item.name}>
                                      //       {item.name} ({item.size})
                                      //     </Select.Option>
                                      //   )
                                      // }
                                    }
                                    {/* )} */}
                                </Select>
                              </div>
                              <div className="m-1">
                                <Button
                                  type="primary"
                                  onClick={()=>{addLine()}}
                                >
                                  {props.t("btn_line")}
                                </Button>
                              </div>
                              
                              
                            </div>
                            
                          </div>
                        </div>
                      </>
                    )
                  }
                })
                // colorSelected &&(
                //   <>
                //     <h4 style={{ marginBottom: "20px" }}>Variants :</h4>
                //     <div className="row">
                //       <div className="col-md-1 tableau-mark">
                //         <div onClick={()=>{choseColor(colorSelected)}} className="row variants-miniature__mark">
                //           <div className="row variants-miniature__item" style={{backgroundColor:colorSelected.code}}></div>
                //           <div>
                //             <label>{colorSelected.label}</label>
                //           </div>
                //         </div>
                //         <label
                //           htmlFor={colorSelected.label}
                //           style={{
                //             // backgroundImage: `url("${item.photo.preview}")`,
                //             backgroundRepeat: "no-repeat",
                //             backgroundSize: "cover",
                //             marginBottom:'10px'
                //           }}
                //           className="drop-container-image"
                //         >
                //           <Text>+</Text>
                //           <Input
                //             style={{ display: "none" }}
                //             id={colorSelected.label}
                //             className="file-uploader"
                //             type="file"
                //             accept="image/*"
                //             name={colorSelected.label}
                //             onChange={(event) => {
                //               choseImageColor(event, colorSelected);
                //             }}
                //           />
                //         </label>
                //       </div>
                //       <div style={{overflowX:"auto"}} className="col-md-11">
                //         <table className="table table-bordered table-variants">
                //           <thead>
                //             <tr style={{textAlign:'center'}}>
                //               <th scope="col">Size</th>
                //               <th scope="col">Imbretex Stock</th>
                //               <th scope="col">Supplied Stock 2 Weeks</th>
                //               <th scope="col">Supplied Stock 4 Weeks</th>
                //             </tr>
                //           </thead>
                //           <tbody>
                //             {
                //               allSize.map((item)=>(
                //                 <>
                //                   <tr style={{textAlign:'center'}}>
                //                     <th scope="col">{item.name} ({item.size})</th>
                //                     <td>            
                //                       <Input
                //                         defaultValue={null}
                //                         onInput={(event) => {
                //                           choseImbretex(event,item,colorSelected)
                //                         }}
                //                       />
                //                       </td>
                //                     <td>
                //                       <Input
                //                         onInput={(event) => {
                //                           chose2Weeks(event,item,colorSelected)
                //                         }}
                //                       />
                //                     </td>
                //                     <td>
                //                       <Input
                //                         onInput={(event) => {
                //                           chose4Weeks(event,item,colorSelected)
                //                         }}
                //                       />
                //                     </td>
                //                   </tr>
                //                 </>
                //               ))
                //             }
                                            
                //           </tbody>
                //         </table>
                //       </div>
                //     </div>
                //   </>
                // )
              }
              

            </>
            // <>
            //   <h4 style={{ marginBottom: "20px" }}>Variants :</h4>
            //   <div style={{overflowX:"auto"}}>
            //     <table className="table table-bordered table-variants">
            //       <thead>
            //         <tr>
            //           <th scope="col" style={{ border: "none" }}></th>
            //           <th scope="col" style={{ border: "none" }}></th>
            //           {colors &&
            //             colors.map((item) => <th scope="col">{item.label}</th>)}
            //         </tr>
            //       </thead>
            //       <tbody>
            //         {checkeds &&
            //           checkeds.map((item) => (
            //             <tr>
            //               <td>
            //                 <Checkbox
            //                   onChange={() => {
            //                     onChecked(item.label);
            //                   }}
            //                   style={{ marginRight: "10px" }}
            //                 />
            //               </td>
            //               <th>{item.label}</th>
            //               {colors &&
            //                 colors.map((col) => (
            //                   <td>
            //                     <Input
            //                       disabled={!item.check}
            //                       onInput={(event) => {
            //                         choseValueStock(event, item, col);
            //                       }}
            //                     />
            //                   </td>
            //                 ))}
            //             </tr>
            //           ))}
            //         <tr>
            //           <td style={{ border: "none" }}></td>
            //           <td style={{ border: "none" }}></td>
            //           {colors.map((item) => {
            //             return (
            //               <td className="champ-image">
            //                 {item.photo && (
            //                   <label
            //                     htmlFor={item.label}
            //                     style={{
            //                       backgroundImage: `url("${item.photo.preview}")`,
            //                       backgroundRepeat: "no-repeat",
            //                       backgroundSize: "cover",
            //                     }}
            //                     className="drop-container-image"
            //                   >
            //                     <Text>+</Text>
            //                     <Input
            //                       style={{ display: "none" }}
            //                       id={item.label}
            //                       className="file-uploader"
            //                       type="file"
            //                       accept="image/*"
            //                       name={item.label}
            //                       onChange={(event) => {
            //                         choseImageColor(event, item.label);
            //                       }}
            //                     />
            //                   </label>
            //                 )}
            //                 {!item.photo && (
            //                   <label
            //                     htmlFor={item.label}
            //                     className="drop-container-image"
            //                   >
            //                     <Text>+</Text>
            //                     <Input
            //                       style={{ display: "none" }}
            //                       id={item.label}
            //                       className="file-uploader"
            //                       type="file"
            //                       accept="image/*"
            //                       name={item.label}
            //                       onChange={(event) => {
            //                         choseImageColor(event, item.label);
            //                       }}
            //                     />
            //                   </label>
            //                 )}
            //               </td>
            //             );
            //           })}
            //         </tr>
            //       </tbody>
            //     </table>
            //   </div>
            // </>
          )}
          <div className="footer-addItem">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                className="mr-3"
              >
                {props.t("btn_save")}
              </Button>
              <Link to="/dashboard/items">{props.t("retour_liste")}</Link>
            </Form.Item>
          </div>
        </Form>
      </Card>

      <Modal
        title="Add new color"
        open={isModalOpen2}
        onOk={handleOk2}
        footer={[]}
        onCancel={handleCancel2}
      >
        <Form
          form={formVariant}
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          style={{ maxWidth: 600 }}
          autoComplete="off"
        >
          {/* <Form.Item label={props.t("label")} name="label">
            <Input />
          </Form.Item> */}
          <Form.Item
            label={props.t("label")}
            name="label"
            rules={[
              {
                required: true,
                message: "Please select color!",
              },
            ]}
          >
            <Select
              allowClear
              placeholder={props.t("label")}
              showSearch
              optionFilterProp="children"
            >
              {colors &&
                colors.map((item) => (
                  <Select.Option key={`key-${item.id}`} value={item.label}>
                    {item.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Image" name="photo">
            <label htmlFor="filesColor" className="drop-container-image">
              {fileNameC == null && <Text>{props.t("upload_image")} : </Text>}
              {fileNameC != null && <Text>{fileNameC}</Text>}
              <Input
                style={{ display: "none" }}
                id="filesColor"
                className="file-uploader"
                type="file"
                accept="image/*"
                onChange={changeImage}
              />
            </label>
            {/* <Input onChange={changeImage} type="file" accept="image/*" /> */}
          </Form.Item>
          <Form.List name="fields">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "size"]}
                      rules={[{ required: true, message: "Missing size" }]}
                    >
                      <Input placeholder="Size" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "pricep"]}
                      rules={[
                        { required: true, message: "Missing price purchase" },
                      ]}
                    >
                      <Input placeholder="Price purchase" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "pricer"]}
                      rules={[
                        { required: true, message: "Missing price ressel" },
                      ]}
                    >
                      <Input placeholder="Price ressel" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit">
            {props.t("btn_save")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default withTranslation()(AddItem);
