export const addInquery = (data) => {
  return {
    type: "SET_INQUERY",
    payload: data
  }
}

export const setQuantitiesRedux = (data) => {
  return {
    type: "SET_QUANTITIES",
    payload: data
  }
}