import { Button, Modal } from 'antd'
import React from 'react'

function ModalOutStock({openModal, setOpenModal, confirmOrder, makeAnotheraOrder, qte_available_out, inputIdSelectedOut }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleCancel = (inputID) => {
    const inputElement = document.getElementById(inputID);
    inputElement.value = null;
    setOpenModal(false);
  }

  const adjustQte = (inputID) => {
    const inputElement = document.getElementById(inputID);
    inputElement.value = qte_available_out;
    setOpenModal(false);
  }

  return (
    <Modal
      open={openModal}
      title="The quantity requested is larger than the actual stock available, please confirm any of the options below"
      footer={null}
      width={1000}
      closable={false}
      keyboard={false}
    >
      <div style={{textAlign:'center'}}>
        <Button className='mb-4' key="submit" type="primary" onClick={() => setOpenModal(false)}>
            Would you like to keep initial quantities ordered and a delivery time for the back orders will be proposed in the next 24h ?
        </Button>
        <br />
        <Button className='mb-4'
        type="primary"
        onClick={() => adjustQte(inputIdSelectedOut)}
        >
            Would you like to adjust you order to the quantities avaiable in stock ?
        </Button>
        <br />
        <Button key="back" onClick={() => handleCancel(inputIdSelectedOut)}>
            Would you like to cancel the order ?
        </Button>
      </div>
    </Modal>
  )
}

export default ModalOutStock