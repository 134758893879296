import { Button, Drawer, Space } from 'antd'
import React from 'react'
import OrderTable from '../orderTable/OrderTable'
import { Utils } from '../../../services/Utils';
import Axios from 'axios';
import confirm from 'antd/lib/modal/confirm';
import BASE_URL from '../../../config';


function OrderDrawer({open, onClose}) {
  const [dataSource, setDataSource] = React.useState([]);
  const [currentOrder, setCurrentOrder] = React.useState(localStorage.getItem('currentOrder'));

  // const prepareOrderData = React.useCallback(() => {
    
  // }, [dataSource, currentOrder])
  
  React.useEffect(() => {
    if((currentOrder && dataSource.length === 0) || localStorage.getItem('currentOrder') && dataSource.length === 0) {
      const localCurrentOrder = localStorage.getItem('currentOrder');
      const data = dataSource.slice();
      JSON.parse(localCurrentOrder).cart.map( (c) => {
        c.commande.map((com, index2) => {
          data.push({
            key: (c.product.name) + (index2),
            productName: c.product.name,
            quantity: com.in,
            max_stock: com.max,
            color: com.color.label,
            size: com.variant.reference_ys + ' / ' + com.variant.value,
            disponibility: com.variant.stocks.in_stock[0].date_disponibility,
            unitPrice: c.product.price.purchase_price,
            totalPrice: (c.product.price.purchase_price * parseInt(com.in)).toFixed(2),
            productId: c.product.id,
            variantId: com.variant.id
          })
          return true;
        })
        return true;
      });
      setDataSource(data);
    }
  },[currentOrder, dataSource])

  const jsonFormat = (inA,oneA,twoA, isDraft, dataDraft) =>{
      const res = {cart:[],one:[],two:[], isDraft: isDraft, data_draft: dataDraft};
      inA.map((item)=>{
          const temp = {
              product_id:item.product.id,
              commande:[]
          };
          item.commande.map((itemC,i)=>{
              temp.commande.push({
                  color:itemC.color.label,
                  in:itemC.in,
                  variant:itemC.variant.value
              })
          })
          if(temp.commande.length>0){
              res.cart.push(temp)
          }
      })
      oneA.map((item)=>{
          const temp = {
              product_id:item.product.id,
              commande:[]
          };
          item.commande.map((itemC,i)=>{
              temp.commande.push({
                  color:itemC.color.label,
                  one:itemC.one,
                  variant:itemC.variant.value
              })
          })
          if(temp.commande.length>0){
              res.one.push(temp)
          }
      })
      twoA.map((item)=>{
          const temp = {
              product_id:item.product.id,
              commande:[]
          };
          item.commande.map((itemC,i)=>{
              temp.commande.push({
                  color:itemC.color.label,
                  two:itemC.two,
                  variant:itemC.variant.value
              })
          })
          if(temp.commande.length>0){
              res.two.push(temp)
          }
      })
      return res;
  }
  const validerOrder = (product, productOne, productTwo, asDraft = false) =>{
    if(product.length==0 && productOne.length==0 && productTwo.length==0){
        let type = "error";
        let mess = "Cart empty!";
        Utils.notif(type, mess);
        return;
    }

    const Authorization = localStorage.getItem("token");
    const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + Authorization,
    };
    const orderStorage = localStorage.getItem('currentOrder');
    // const data_draft = asDraft ? JSON.parse(orderStorage) : null;
    // console.log(orderStorage);
    // return
    Axios.post(`${BASE_URL}/api/v1/command/add`, jsonFormat(product,productOne,productTwo, asDraft, orderStorage), { headers })
    .then((response) => {
        if (response.data.success) {
            let type = "success";
            let mess = response.data?.message;
            Utils.notif(type, mess);
            localStorage.removeItem('currentOrder');
            setDataSource([]);
            setCurrentOrder(null);
            onClose()
            setTimeout(() => window.location.reload(), 2000);
        }
    })
    .catch((error) => {
        if (error.response.data.errors.email != null) {
        let type = "error";
        let mess = error.response.data.errors.email[0];
        Utils.notif(type, mess);
        }else{
        let type = "error";
        Utils.notif(type, "Error");
        }
    });
}

  const confirmOrder = (iSDraft = false) => {
    const order = localStorage.getItem('currentOrder');
    if(order) {
      if(iSDraft){
        confirm({
          title:'',
          content: 
            <div style={{textAlign:'center'}}>
                <img style={{width:"150px",height:"150px"}} src='https://img.freepik.com/free-vector/shopping-cart-realistic_1284-6011.jpg?w=2000'/>
                <p><b>you are about to save your inquiry as Draft ?</b></p>
            </div>,
          okText:'OK',
          cancelText:'Cancel',
          onOk:()=>{
            const { cart, one, two } = JSON.parse(order);
            validerOrder(cart, one, two, true);
          },
          onCancel: () =>{}
        });
      }
      if(!iSDraft){
        confirm({
          title:'',
          content: 
            <div style={{textAlign:'center'}}>
                <img style={{width:"150px",height:"150px"}} src='https://img.freepik.com/free-vector/shopping-cart-realistic_1284-6011.jpg?w=2000'/>
                <p><b>you are about to submit your inquiry to Admin ?</b></p>
            </div>,
          okText:'OK',
          cancelText:'Cancel',
          onOk:()=>{
            const { cart, one, two } = JSON.parse(order);
            validerOrder(cart,one,two, false);
          },
          onCancel: () =>{}
        });
      }
    }
    if(!order) {
      Utils.notif('error', 'No order found');
    }
  }

  const emptyOrder = () => {
    confirm({
      title:'',
      content: 
      <div style={{textAlign:'center'}}>
          <p><b>Are you sure to cancel the inquiry ?</b></p>
      </div>,
      okText:'YES',
      cancelText:'NO',
      onOk:()=>{
        localStorage.removeItem('currentOrder');
        setDataSource([]);
        setCurrentOrder(null);
        onClose()
        setTimeout(() => window.location.reload(), 2000);
      },
      onCancel: ()=>{
          
      }

    });
  }

  return (
    <Drawer
      title="Orders"
      placement="right"
      width={'50%'}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button type="primary" onClick={() => confirmOrder(false)}>
            Submit the inquiry
          </Button>
        </Space>
      }
    >
      <OrderTable dataSource={dataSource} />
      <div className='d-flex justify-content-between'>
        <Button onClick={onClose} type="primary" >Add product</Button>
        <Button onClick={() => confirmOrder(true)}>Save as Draft</Button>
        <Button onClick={emptyOrder} danger>Cancel the inquiry</Button>
      </div>

    </Drawer>
  )
}

export default OrderDrawer