import React, { useEffect, useState } from 'react';
import axios from "axios";
import BASE_URL from "../../../config.js";
import { Button, Form, Input, Card, Typography, Checkbox } from "antd";
import { Utils } from '../../../services/Utils.js';
import { withTranslation } from "react-i18next";

const { Title } = Typography;
const Profil = (props) => {
  const [formProfil] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isDifferent, setIsDifferent] = useState(false);
  const [profil, setProfil] = useState(JSON.parse(localStorage.getItem('DATA_USER')));
  const [roles, setRoles] = useState(JSON.parse(localStorage.getItem('LIST_ROLES')));
  const [role, setRole] = useState(JSON.parse(localStorage.getItem('label')));
  useEffect(() => {
    // alert(role)
    let temp = '';
    roles?.filter((item)=>{
        console.log(item.id)
        if(item.id==role){
            temp = item.label;
        }
    })
  
    if(role==1){
        temp = 'Admin';
    }
//   alert(temp.label)
  formProfil.setFieldsValue({
      name: profil?.name,
      firstname: profil?.firstname,
      email: profil?.email,
      role_id: profil?.role_id,
      acompte: profil?.acompte,
      remise: profil?.remise,
      address: profil?.delivery_address,
      company: profil?.company_name,
      address_livraison: profil?.billing_address,
      tva: profil?.tva_number,
      role: temp
  });
  if(profil?.is_same_adress=="0"){
    setIsDifferent(true);
  }else{
      setIsDifferent(false);
  }
  }, []);
  const saveUser = async (value) => {
    setIsLoading(true);
    const { name, firstname, email, address,tva,company,address_livraison } = value;
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    try {
        const response = await axios.post(`${BASE_URL}/api/v1/user/update-profile`, { is_same_adress: !isDifferent,name, firstname, email, delivery_address:address,tva_number:tva,company_name:company,billing_address:address_livraison }, { headers });

        setIsLoading(false);

        if(response.data.success){
            let type = 'success'
            let mess = response.data?.message;
            let DATA_USER = {};
            DATA_USER = response.data.data;
            localStorage.setItem('DATA_USER', JSON.stringify(DATA_USER));
            Utils.notif(type, mess);
        } 

    } catch (error) {
        setIsLoading(false);
        console.log(error);    
    } 
};

  return (
    <Card title={<Title level={3}>{props.t('my_profil')}</Title>}>
      <div className="row">
          <div className="col-md-12">
          <Form
              form={formProfil}
              name="profil_form"
              labelCol={{
              span: 8,
              }}
              wrapperCol={{
              span: 16,
              }}
              style={{
              maxWidth: 600,
              }}
              initialValues={{
              remember: true,
              }}
              onFinish={saveUser}
              onFinishFailed={null}
              autoComplete="off"
          >
              <Form.Item
              label={props.t("name")}
              name="name"
              rules={[
                  {
                  required: true,
                  message: "Please input your name!",
                  },
              ]}
              >
              <Input />
              </Form.Item>

              <Form.Item
              label={props.t("firstname")}
              name="firstname"
              rules={[
                  {
                  required: true,
                  message: "Please input your firstname!",
                  },
              ]}
              >
              <Input />
              </Form.Item>
              <Form.Item
                label={props.t("company_name")}
                name="company"
                rules={[
                    {
                    required: true,
                    message: "Please input company name!",
                    },
                ]}
                >
                <Input />
              </Form.Item>
              <Form.Item
                label={props.t("role")}
                name="role"
                >   
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={props.t("tva_number")}
                name="tva"
                rules={[
                    {
                    required: true,
                    message: "Please input TVA Number!",
                    },
                ]}
                >
                <Input />
                </Form.Item>
                <Form.Item name="address_livraison" label={props.t("delivery_address")}
                rules={[
                  {
                  required: true,
                  message: "Please input Billing Adress!",
                  },
              ]}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item
                label="If it is different delivery address"
                name="different"
                style={{marginTop:'-25px'}}
                >
                <Checkbox checked={isDifferent} onChange={()=>setIsDifferent(!isDifferent)} />
                </Form.Item>
                {
                    isDifferent &&(
                        <Form.Item name="address" label={props.t("address")}>
                            <Input.TextArea />
                        </Form.Item>
                    )
                }
              <Form.Item
              label={props.t("email")}
              name="email"
              rules={[
                  {
                  required: true,
                  message: "Please input your email!",
                  },
              ]}
              >
              <Input />
              </Form.Item>

              <Form.Item
              label={props.t("pc_remise")}
              name="remise"
              >
              <Input type='number' disabled />
              </Form.Item>
              <Form.Item
              label={props.t("pc_acompte")}
              name="acompte"
              >
              <Input type='number' disabled />
              </Form.Item>
              <Form.Item
              wrapperCol={{
                  offset: 8,
                  span: 16,
              }}
              >
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                >
                    {props.t("btn_save")}
                </Button>
              </Form.Item>
          </Form>
          </div>
      </div>
    </Card>
  );
}

export default withTranslation()(Profil);
