import React, { useEffect } from 'react';
import { withTranslation  } from 'react-i18next';
import { Card, Statistic, Typography, Row, Col } from 'antd';
import axios from "axios";
import BASE_URL from "../../../config.js";
import { LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Utils } from '../../../services/Utils.js';
const { Title } = Typography;

const Dashboard = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statistic, setStatisctic] = useState(false);
  const [role, setRole] = useState(JSON.parse(localStorage.getItem('label')));
    useEffect(() => {
       getStatisctic();
      if (localStorage.getItem("LIST_ROLES") === null) {
        getRoles();
      }
    }, []);
    const getStatisctic = () => {
        const Authorization = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Authorization,
        };
        axios
          .get(`${BASE_URL}/api/v1/dashboard`, { headers })
          .then((res) => {
            if(res.data.success){
              const statisticD = res.data?.data;
              let filtered;
              if(role === 3){
                filtered = Object.entries(statisticD).filter(([key, value]) => 
                  key !== 'countReseller' && 
                  key !== 'countTissus' && 
                  key !== 'countInqueryPending'
                );
              }
              if(role === 4){
                filtered = Object.entries(statisticD).filter(([key, value]) => 
                  key === 'countCatalog' || 
                  key === 'countTissus' || 
                  key === 'countInqueryPending'
                );
              } 
              if(role === 1 || role === 2) {
                filtered = Object.entries(statisticD).filter(([key, value]) => 
                  key !== 'countInqueryPending'
                );
              }
              
              const justStrings = Object.fromEntries(filtered);
  
              setStatisctic(justStrings);
            }

          })
          .catch((error) => {
            console.log(error);
          });
    };
    const getRoles = () => {
      const Authorization = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Authorization,
      };
      axios
        .get(`${BASE_URL}/api/v1/role-list`, { headers })
        .then((res) => {
          const rolesData = res.data?.data;

          localStorage.setItem('LIST_ROLES', JSON.stringify(rolesData));
        })
        .catch((error) => {
          console.log(error);
        });
    };
    
    const formatKey = (beforeKey) => {
      let afterKey = "";
      switch (beforeKey) {
        case "countReseller":
          afterKey = "Customer";
          break;
        case "countOrder":
          afterKey = "Orders";
          break;
        case "countCatalog":
          afterKey = "Catalogs";
          break;
        case "countPayment":
          afterKey = "Payments";
          break;
        case "countStock":
          afterKey = "Catalog stock";
          break;
        case "countTissus":
          afterKey = "Fabric stock (meter)";
          break;
        case "countInqueryPending":
          afterKey = "Inquiries pending";
          break;
        default:
          break;
      }
      return afterKey;
    }

    return (
        <Card title={<Title level={3}>{props.t('dashboard')}</Title>}>
          {
            statistic && (
              <div className='row'>
                {
                  Object.keys(statistic).map((state, index) => (
                    <div className='col-12 col-sm-6 col-md-3' key={index} show={false}>
                      <Card className='border border-dark mb-4'>
                        <Statistic
                          title={( 
                            <Title level={5}> {formatKey(state)} </Title>
                          )}
                          value={statistic[state]}
                          valueStyle={{
                            color: '#1890ff',
                            textAlign: 'right'
                          }}
                          suffix=""
                        />
                      </Card>
                    </div>
                  ))
                }
              </div>
            )
          }
        </Card>
    );
}

export default withTranslation()(Dashboard)