import { Button, Carousel, Col, Image, Input, Modal, Row, Space, Tabs, Typography } from 'antd';
import React,{ Component } from 'react';
import '../../assets/css/detailProduct.scss';
import BASE_URL from '../../config';
import axios from "axios";
import confirm from 'antd/lib/modal/confirm';
import { Utils } from '../../services/Utils';
import { Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as InqueryActions from "../../my_redux/actions/inquery";
const { Text } = Typography;

class DetailProductInQuerry extends Component {
    state = {
        modalOpen:true,
        disponible:"",
        primaryImage:this.props.data?.images[0]?.path?BASE_URL+this.props.data.images[0].path:"https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg",
        secondaryImage:this.props.data.colors[0].image?BASE_URL+this.props.data.colors[0].image.path:"https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg",
        activeColor: this.props.data.colors[0],
        media: null,
        nav: null,
        stocks:null,
        stockMax:0,
        insuff:false,
        commande:[],
        redirect: false,
        quantities: 0,
        qteMoyenne: 0
    }

    changeIn = (event,color,variant) =>{
        if(event.target.value!=null && event.target.value!="" && event.target.value!=undefined){
            // if(variant.stocks.in_stock.length==0){
            //     let type = "error";
            //     Utils.notif(type, `OUT OF STOCK`);
            //     return;
            // }
            // if(parseInt(event.target.value)>variant.stocks.in_stock[0].quantity){
            //     let type = "error";
            //     Utils.notif(type, `OUT OF STOCK: You can order with up to ${variant.stocks.in_stock[0].quantity} quantities`);
            //     // this.setState({insuff:true,stockMax:variant.stocks.in_stock[0].quantity});
            //     const el = document.getElementById(color.id+"-"+variant.id+'-in');
            //     el.value = variant.stocks.in_stock[0].quantity;
            //     const temp = [...this.state.commande];
            //     var test = true;
            //     temp.map((item,i)=>{
            //         if(item.color.id==color.id && item.variant.id==variant.id){
            //             temp[i].in = variant.stocks.in_stock[0].quantity;
            //             test = false;
            //         }
            //     })
            //     if(test){
            //         temp.push({
            //             color:color,
            //             variant:variant,
            //             in:variant.stocks.in_stock[0].quantity,
            //             max:variant.stocks.in_stock[0].quantity
            //         })
            //     }
            //     this.state.commande = temp;
            // }else{
                const temp = [...this.state.commande];
                var test = true;
                temp.map((item,i)=>{
                    if(item.color.id==color.id && item.variant.id==variant.id){
                        temp[i].in = event.target.value;
                        test = false;
                    }
                })
                if(test){
                    temp.push({
                        color:color,
                        variant:variant,
                        in:event.target.value,
                        max:variant.stocks.in_stock[0].quantity
                    })
                }
                this.state.commande = temp;
            // }
        }else{
            const temp = [...this.state.commande];
            var test = true;
            temp.map((item,i)=>{
                if(item.color.id==color.id && item.variant.id==variant.id){
                    temp[i].in = undefined;
                    test = false;
                }
            })
            if(test){
                temp.push({
                    color:color,
                    variant:variant,
                    in:undefined,
                    max:variant.stocks.in_stock[0].quantity
                })
            }
            this.state.commande = temp;
        }   
    }


    changeOne = (event,color,variant) =>{
        if(event.target.value!=null && event.target.value!="" && event.target.value!=undefined){
            if(variant.stocks.future_one.length==0){
                let type = "error";
                Utils.notif(type, `OUT OF STOCK`);
                return;
            }
            if(parseInt(event.target.value)>variant.stocks.future_one[0].quantity){
                // this.setState({insuff:true,stockMax:variant.stocks.future_one[0].quantity});
                let type = "error";
                Utils.notif(type, `OUT OF STOCK: You can order with up to ${variant.stocks.future_one[0].quantity} quantities`);
                // this.setState({modalOpen:false})
                const el = document.getElementById(color.id+"-"+variant.id+'-one');
                el.value = variant.stocks.future_one[0].quantity;
                const temp = [...this.state.commande];
                var test = true;
                temp.map((item,i)=>{
                    if(item.color.id==color.id && item.variant.id==variant.id){
                        temp[i].one = variant.stocks.future_one[0].quantity;
                        test = false;
                    }
                })
                if(test){
                    temp.push({
                        color:color,
                        variant:variant,
                        one:variant.stocks.future_one[0].quantity,
                        max:variant.stocks.future_one[0].quantity
                    })
                }
                this.state.commande = temp;
            }else{
                const temp = [...this.state.commande];
                var test = true;
                temp.map((item,i)=>{
                    if(item.color.id==color.id && item.variant.id==variant.id){
                        temp[i].one = event.target.value;
                        test = false;
                    }
                })
                if(test){
                    temp.push({
                        color:color,
                        variant:variant,
                        one:event.target.value,
                        max:variant.stocks.future_one[0].quantity
                    })
                }
                this.state.commande = temp;
            }
        }else{
            const temp = [...this.state.commande];
            var test = true;
            temp.map((item,i)=>{
                if(item.color.id==color.id && item.variant.id==variant.id){
                    temp[i].one = undefined;
                    test = false;
                }
            })
            if(test){
                temp.push({
                    color:color,
                    variant:variant,
                    one:undefined,
                    max:variant.stocks.future_one[0].quantity
                })
            }
            this.state.commande = temp;
        }
    }

    changeTwo = (event,color,variant) =>{
        if(event.target.value!=null && event.target.value!="" && event.target.value!=undefined){
            if(variant.stocks.future_two.length==0){
                let type = "error";
                Utils.notif(type, `OUT OF STOCK`);
                return;
            }
            if(parseInt(event.target.value)>variant.stocks.future_two[0].quantity){
                let type = "error";
                Utils.notif(type, `OUT OF STOCK: You can order with up to ${variant.stocks.future_one[0].quantity} quantities`);
                // this.setState({insuff:true,stockMax:variant.stocks.future_two[0].quantity});
                const el = document.getElementById(color.id+"-"+variant.id+'-two');
                el.value = variant.stocks.future_two[0].quantity;
                const temp = [...this.state.commande];
                var test = true;
                temp.map((item,i)=>{
                    if(item.color.id==color.id && item.variant.id==variant.id){
                        temp[i].two = variant.stocks.future_two[0].quantity;
                        test = false;
                    }
                })
                if(test){
                    temp.push({
                        color:color,
                        variant:variant,
                        two:variant.stocks.future_two[0].quantity,
                        max:variant.stocks.future_two[0].quantity
                    })
                }
                this.state.commande = temp;
            }else{
                const temp = [...this.state.commande];
                var test = true;
                temp.map((item,i)=>{
                    if(item.color.id==color.id && item.variant.id==variant.id){
                        temp[i].two = event.target.value;
                        test = false;
                    }
                })
                if(test){
                    temp.push({
                        color:color,
                        variant:variant,
                        two:event.target.value,
                        max:variant.stocks.future_two[0].quantity
                    })
                }
                this.state.commande = temp;
            }
        }else{
            const temp = [...this.state.commande];
            var test = true;
            temp.map((item,i)=>{
                if(item.color.id==color.id && item.variant.id==variant.id){
                    temp[i].two = undefined;
                    test = false;
                }
            })
            if(test){
                temp.push({
                    color:color,
                    variant:variant,
                    two:undefined,
                    max:variant.stocks.future_two[0].quantity
                })
            }
            this.state.commande = temp;
        }
    }
    
    init = (color) =>{
        color.variantes.map((item)=>{
            const el = document.getElementById(color.id+"-"+item.id+'-in');
        
            el.addEventListener('input', (e) => this.changeIn(e,color,item));

            // const elOne = document.getElementById(color.id+"-"+item.id+'-one');
        
            // elOne.addEventListener('input', (e) => this.changeOne(e,color,item));

            // const elTwo = document.getElementById(color.id+"-"+item.id+'-two');
        
            // elTwo.addEventListener('input', (e) => this.changeTwo(e,color,item));
        })
    }
    
    componentDidUpdate(prevProps) {
        this.init(this.state.activeColor);

        if(JSON.stringify(this.props.quantities) !== JSON.stringify(this.state.quantities)) {
            // const lastInquery = this.props.inquery.find(iq => iq.product.id === this.props.data.id).commande;
            // const totalQte = lastInquery.reduce((prev,li) => prev + parseInt(li.in), 0) * this.state.qteMoyenne
            // console.log("MIOVA VE", lastInquery, this.props.quantities, lastInquery.reduce((prev,li) => prev + parseInt(li.in), 0))
            // // this.setState({
            // //     quantities: {
            // //         quantity: this.state.quantity - totalQte,
            // //         colors
            // //     }
            // // })
            this.setState({
                quantities: this.props.quantities
            })
        }
    }

    componentDidMount() {
        this.setState({
            media: this.media,
            nav: this.nav,
            quantities: this.props.quantities
        });
        this.props.addInquery([]);
    };

    codeColor = (quantity) =>{
        if(quantity<=0){
            return `<td style="vertical-align: middle!important;"><span class="bg-danger" style="font-weight: bold;display: inline-block;color:white;width: 44px;">${quantity}</span></td>`;
        }else if(quantity>0 && quantity<=12){
            return `<td style="vertical-align: middle!important;"><span class="bg-warning" style="font-weight: bold;display: inline-block;width: 44px;">${quantity}</span></td>`;
        }else if(quantity>12){
            return `<td style="vertical-align: middle!important;"><span class="bg-success" style="font-weight: bold;display: inline-block;color:white;width: 44px;">${quantity}</span></td>`;
        }
    }

    monComposant = (color) => {
        this.state.disponible = 
        `<div style="overflow-x: auto;">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col" class="title" style="font-weight: bold;verical-align:middle" >SIZE</th>
                    `;
        var size = "";      
        color.variantes.map((item)=>{
            size += `<th scope="col"><p style="margin:0;font-weight: bold;">${item.value}</p> <p style="margin:0;">${item.reference_ys}</p></th>`;
        })
        this.state.disponible +=size+`
                </tr>
                </thead>
                <tbody>`;
        var ligne = "";
        

        var total = {
            in:0,
            one:0,
            two:0
        };

        color.variantes.map((item)=>{
            total.in = parseInt(total.in) + parseInt(item?.stocks?.in_stock[0]?.quantity?item?.stocks?.in_stock[0]?.quantity:0);
            total.one = parseInt(total.one)+ parseInt(item?.stocks?.future_one[0]?.quantity?item?.stocks?.future_one[0]?.quantity:0);
            total.two = parseInt(total.two)+ parseInt(item?.stocks?.future_two[0]?.quantity?item?.stocks?.future_two[0]?.quantity:0);
        })


        ligne += `<tr>`;
        
        color.variantes.map((item,i)=>{
            if(i === 0){
                ligne += `<th class="title" style="text-align: center;vertical-align: middle;">Quantity</th>`;
                // ligne += `<td class="commande-input"></td>`;
            }
            let inT = true;
            this.state.commande.map((itemC, index)=>{
                if(itemC.color.id === color.id){
                    if(itemC.variant.id === item.id){
                        inT = false;
                        ligne += `<td class="commande-input" key="${index}"><input id="${color.id}-${item.id}-in" class="input-commande" style="height: 30px;" value="${itemC?.in?itemC?.in:null}" type="number"/></td>`;  
                    }
                }
            })
            if(inT){
                ligne += `<td class="commande-input"><input id="${color.id}-${item.id}-in" class="input-commande" style="height: 30px;" type="number"/></td>`;  
            }
        })
        ligne += `</tr>`;
        

        
        this.state.disponible +=ligne;
        return (
          <>
            <div dangerouslySetInnerHTML={{ __html: this.state.disponible }} />
            <Button onClick={() => this.addInquery() }>Add</Button>
          </>
        );
    }

    constructor(props) {
        super(props);
        this.state.stocks = 
            [
                   {
                    key: '3',
                    label: `Quantity`,
                   //  disabled:true,
                    children: this.monComposant(this.props.data?.colors[0]),
                  },
                   {
                    key: '1',
                    label: `Description`,
                    children: `${this.props.data?.description}`,
                  },
                  {
                    key: '2',
                    label: `Matiere`,
                    children: `${this.props.data?.fabric?.label}`,
                  }
            ];
        this.addInquery = this.addInquery.bind(this);
    }

    combineObjectsWithSameLabel(arr) {
        const combinedObjects = {};
      
        arr.forEach((obj) => {
          const { label, comQte, colProdQte } = obj;
          if (!combinedObjects[label]) {
            combinedObjects[label] = { label, comQte, colProdQte };
          } else {
            combinedObjects[label].comQte += comQte;
          }
        });
      
        return Object.values(combinedObjects);
      }

    addInquery() {
        let inquery = [...this.props.inquery];
        let qtePerColor = [];
        let lowQuantities = [];
        const Authorization = localStorage.getItem("token");
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Authorization,
        };

        axios.get(`${BASE_URL}/api/v1/stock/list`, { headers })
        .then((response) => {
            const stockList = response.data.data.find(d => d.catalogs.length !== 0 && d.catalogs.find(p => p.reference === this.props.data.reference && p.name === this.props.data.name));
            console.log("STOCK LIST", stockList, inquery, this.props.data.id, this.state.commande)
            if(!(inquery.find(iq => iq.product.id === this.props.data.id)) && this.state.commande.length !== 0) {
                const dataInquery = [...inquery, ...[{ key: '' + inquery.length,product:this.props.data,commande:this.state.commande}]];
                const qteMoyenne = parseFloat(stockList.catalogs[0].consomation_moyenne);
                this.state.commande.map(com => {
                    this.props.quantities.colors.map(c => {
                        if(c.color === com.color.label) {
                            if(qtePerColor.find(qpc => qpc.label === com.label)) {
                                qtePerColor = qtePerColor.map(qpc => {
                                    if(qpc.label === com.label) {
                                        return {label: com.color.label, comQte: ((qpc.in + parseInt(com.in)) * qteMoyenne), colProdQte: c.quantity }
                                    }
                                    return qpc;
                                })
                            } else {
                                qtePerColor = [...qtePerColor, {label: com.color.label, comQte: (parseInt(com.in) * qteMoyenne), colProdQte: c.quantity }]
                            }
                        }
                        return true;
                    })
                    return true;
                })

                if(qtePerColor.length !== 0)  {
                    qtePerColor = this.combineObjectsWithSameLabel(qtePerColor);
                    lowQuantities = qtePerColor.filter(qpc => qpc.comQte > qpc.colProdQte);
                    
                    if(lowQuantities.length === 0) {
                        const setData = {
                            quantity: (this.state.quantities.quantity - qtePerColor.reduce((prev,qpc) => prev + qpc.comQte, 0)),
                            colors: this.state.quantities.colors.map(c => {
                                const comColor = qtePerColor.find(cc => cc.label === c.color);
                                if(comColor)  {
                                    return {...c, quantity: c.quantity - comColor.comQte};
                                }
                                return c;
                            })
                        }
                        this.setState({
                            quantities: setData
                        })
                        this.props.addInquery(dataInquery);
                        Utils.notif("success", "Add with success");
                    } else {
                        Utils.notif("error", `Request: ${(Math.round(lowQuantities[0].comQte * 100)/100).toFixed(2)}m but only ${(Math.round(lowQuantities[0].colProdQte * 100)/100).toFixed(2)}m for color ${lowQuantities[0].label}`);
                    }
                }
            }
            if((inquery.find(iq => iq.product.id === this.props.data.id)) && this.state.commande.length !== 0) {
                const qteMoyenne = parseFloat(stockList.catalogs[0].consomation_moyenne);
                this.setState({
                    qteMoyenne: qteMoyenne
                });
                const lastInquery = inquery.find(iq => iq.product.id === this.props.data.id).commande.map( lcom => {
                    return {
                        label: lcom.color.label,
                        comQte: parseInt(lcom.in) * qteMoyenne,
                    }
                });
                inquery = inquery.map( iq => {
                    if(iq.product.id === this.props.data.id) {
                        this.state.commande.map(comState => {
                            let commande = iq.commande.find(com => com.variant.id === comState.variant.id);
                            this.props.quantities.colors.map(colQte => {
                                if(colQte.color === comState.color.label) {
                                    if(qtePerColor.find(qpc => qpc.label === comState.label)) {
                                        qtePerColor = qtePerColor.map(qpc => {
                                            if(qpc.label === comState.label) {
                                                return {label: comState.color.label, comQte: ((qpc.in + parseInt(comState.in)) * qteMoyenne), colProdQte: colQte.quantity }
                                            }
                                            return qpc;
                                        })
                                    } else {
                                        qtePerColor = [...qtePerColor, {label: comState.color.label, comQte: (parseInt(comState.in) * qteMoyenne), colProdQte: colQte.quantity }]
                                    }
                                }
                                return true;
                            })
                            lowQuantities = qtePerColor.filter(qpc => qpc.comQte > qpc.colProdQte);
                            if(!commande) {
                                if(lowQuantities.length === 0) {
                                    iq.commande = [...iq.commande, ...[comState]];
                                }
                                
                            } else {
                                if(lowQuantities.length === 0) {
                                    iq.commande = iq.commande.map(com => com.variant.id === comState.variant.id ? comState : com);
                                }
                            }
                            return 1;
                        });
                    }
                    return iq;
                })
                if(qtePerColor.length !== 0)  {
                    qtePerColor = [...qtePerColor, ...lastInquery]
                    qtePerColor = this.combineObjectsWithSameLabel(qtePerColor);
                    lowQuantities = qtePerColor.filter(qpc => qpc.comQte > qpc.colProdQte);
                    console.log("AYUTUEAEIU", qtePerColor, lastInquery)
                    
                    if(lowQuantities.length !== 0) {
                        Utils.notif("error", `Request: ${(Math.round(lowQuantities[0].comQte * 100)/100).toFixed(2)}m but only ${(Math.round(lowQuantities[0].colProdQte * 100)/100).toFixed(2)}m for color ${lowQuantities[0].label}`);
                    } else {
                        const setData = {
                            quantity: (this.state.quantities.quantity - qtePerColor.reduce((prev,qpc) => prev + qpc.comQte, 0)),
                            colors: this.state.quantities.colors.map(c => {
                                const comColor = qtePerColor.find(cc => cc.label === c.color);
                                if(comColor)  {
                                    return {...c, quantity: c.quantity - comColor.comQte};
                                }
                                return c;
                            })
                        }
                        this.setState({
                            quantities: setData
                        })
                        Utils.notif("success", "Add with success");
                    }
                }
            }
            this.props.setQuantities(this.state.quantities);
            this.setState({
                commande: []
            })
            // localStorage.setItem("currentProduct", stockList.catalogs[0].name);
        })
        .catch((error) => {
            console.log(error)
            Utils.notif("error", "Server error");
        });
    }


    
    render() {
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to='/dashboard/cart'/>;
        }
        const submitOrder = () =>{
            console.log(this.state.commande);
            console.log(this.props.data);
        }

        const changeColor = (item) =>{
            this.setState({activeColor:item});
            this.setState({
                stocks: 
                    [
                        {
                            key: '3',
                            label: `Disponibilite`,
                            children: this.monComposant(item),
                        },
                        {
                            key: '1',
                            label: `Description`,
                            children: `${this.props.data?.description}`,
                        },
                        {
                            key: '2',
                            label: `Matiere`,
                            children: `${this.props.data?.fabric?.label}`,
                        }
                    ],
            });
        }
        
        const jsonFormat = (inA,oneA,twoA) =>{
            const res = {cart:[],one:[],two:[]};
            inA.map((item)=>{
                const temp = {
                    product_id:item.product.id,
                    commande:[]
                };
                item.commande.map((itemC,i)=>{
                    temp.commande.push({
                        color:itemC.color.label,
                        in:itemC.in,
                        variant:itemC.variant.value
                    })
                })
                if(temp.commande.length>0){
                    res.cart.push(temp)
                }
            })
            oneA.map((item)=>{
                const temp = {
                    product_id:item.product.id,
                    commande:[]
                };
                item.commande.map((itemC,i)=>{
                    temp.commande.push({
                        color:itemC.color.label,
                        one:itemC.one,
                        variant:itemC.variant.value
                    })
                })
                if(temp.commande.length>0){
                    res.one.push(temp)
                }
            })
            twoA.map((item)=>{
                const temp = {
                    product_id:item.product.id,
                    commande:[]
                };
                item.commande.map((itemC,i)=>{
                    temp.commande.push({
                        color:itemC.color.label,
                        two:itemC.two,
                        variant:itemC.variant.value
                    })
                })
                if(temp.commande.length>0){
                    res.two.push(temp)
                }
            })
            return res;
        }
        const validerOrder = (product,productOne,productTwo) =>{
            if(product.length==0 && productOne.length==0 && productTwo.length==0){
                let type = "error";
                let mess = "Cart empty!";
                Utils.notif(type, mess);
                // setIsLoading(false);
                return;
            }
            console.log("product",product)
            console.log("productOne",productOne)
            console.log("productTwo",productTwo)
            console.log(jsonFormat(product,productOne,productTwo));
            const Authorization = localStorage.getItem("token");
            const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Authorization,
            };
            axios.post(`${BASE_URL}/api/v1/command/add`, jsonFormat(product,productOne,productTwo), { headers })
            .then((response) => {
                // dispatch(deletePanier());
                const temp = [];
                // setProduct([...temp]);
                // setProductOne([...temp]);
                // setProductTwo([...temp]);
                if (response.data.success) {
                    let type = "success";
                    let mess = response.data?.message;
                    Utils.notif(type, mess);
                // clearForm();
                // history.push("/dashboard/items");
                }
            })
            .catch((error) => {
                if (error.response.data.errors.email != null) {
                let type = "error";
                let mess = error.response.data.errors.email[0];
                Utils.notif(type, mess);
                }else{
                let type = "error";
                Utils.notif(type, "Error");
                }
            });
        }

        const ajoutPanier = () =>{
            console.log({product:this.props.data,commande:this.state.commande});
            const cart = [];

            const temp = {product:this.props.data,commande:[]};
            this.state.commande.map((itemC)=>{
                if(isNaN(itemC?.in)==false){
                    temp.commande.push({color:itemC.color,in:itemC.in,max:itemC?.max,variant:itemC.variant});
                }
            })
            if(temp.commande.length>0){
                cart.push(temp);
            }

            const Authorization = localStorage.getItem("token");
            const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Authorization,
            };
            axios.post(`${BASE_URL}/api/v1/inquiry/add`, {cart:cart}, { headers })
            .then((response) => {
                const res = response.data.data;
                if(response.data.success==true){
                    confirm({
                        title:'Resultat in querry:',
                        okText:'Confirm in querry',
                        content: 
                        <div style={{textAlign:'left'}}>
                            <ul>
                            {
                                res.map((item)=>{
                                    if(item.is_valid==true){
                                        return (<>
                                            <li><b><h6 style={{color:'blue'}}>{item.catalog_color} ({item.sum_quantity}) </h6> </b></li>
                                        </>)
                                    }else{
                                        return (<>
                                            <li><b><h6 style={{color:'red'}}>{item.catalog_color} ({item.sum_quantity}) </h6> </b></li>
                                        </>)
                                    }
                                   
                                })
                            }
                            </ul>
                        </div>,
                    });
                }
            })
            .catch((error) => {
                if (error.response.data.errors.email != null) {
                let type = "error";
                let mess = error.response.data.errors.email[0];
                Utils.notif(type, mess);
                }else{
                let type = "error";
                Utils.notif(type, "Error");
                }
            });
            
            
        }

        return (
            <>
            <Modal
                className='stock-insuffisant'
                // centered
                open={this.state.insuff}
                style={{ top: 10 }}
                onOk={() => {
                    this.setState({insuff:false})
                }}
                onCancel={() => {
                    this.setState({insuff:false});
                }}
                footer={[]}
            >
                <h1 style={{fontSize:'15px',fontWeight:'bold'}}>OUT OF STOCK</h1> <span style={{color:'red'}}>You can order with up to {this.state.stockMax} quantities</span>
            </Modal>
             <Row className='detail-modal'>
                {/* <Col flex="1 1 100px" style={{marginRight:'10px',marginBottom:'10px'}}>
                    <Row className='detail-items__image'>
                        <Image className='item-image__primary' height={450} alt="images" src={this.state.primaryImage} />
                    </Row> 
                </Col> */}
                <Col flex="1 1 150px">
                {/* <h2 className='detail-items__name'>{this.props.data.name}&nbsp;({(Math.round(this.state.quantities.quantity * 100)/100).toFixed(2)}m)</h2> */}
                <h2 className='detail-items__name'>{this.props.data.name}</h2>
                <h3 className='detail-items__title'>{this.props.data?.category?.label} • {this.props.data.range} • {this.props.data.reference}</h3>
                <div className='detail-items__swatch'>
                    <div className='item-image__couleur'>
                        {
                            this.props.data.colors.map((item)=>{
                                var classe = 'item-image__swatch';
                                if(this.state.activeColor.label === item.label){
                                    classe = 'item-image__swatch color-active';
                                }
                                if(item.image){
                                    return (
                                        <>
                                            <img className={classe} title={item.label} onClick={()=>{changeColor(item)}} alt="images" src={BASE_URL+item?.image?.path} width={50} />
                                        </>
                                        );
                                }else{
                                    return (
                                        <img className={classe} title={item.label} onClick={()=>{changeColor(item)}} alt="images" src="https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg" width={50} />
                                    );
                                }
                            })
                        }
                    </div>
                </div>
                <Tabs
                    defaultActiveKey="3"
                    size='small'
                    style={{ marginBottom: 5 }}
                    items={this.state.stocks}
                />
                    
                </Col>
            </Row>
            </>
        );
    }
}

const mapStateToProps = ({ inquery }) => {
    return {
      inquery: inquery.inquery
    }
  }

const mapDispatchToProps = {
  ...InqueryActions
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailProductInQuerry)