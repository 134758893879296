import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Card, Typography, Table, Button, Input, Popconfirm } from "antd";
import BASE_URL from "../../../config.js";
import { EditOutlined, FilterOutlined, LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import { Utils } from '../../../services/Utils.js';

const { Title } = Typography;
const { Search } = Input;

const Users = (props) => {
  const [users, setUsers] = useState([]);
  const [wordSearch, setWordSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState(JSON.parse(localStorage.getItem('LIST_ROLES')));
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    setIsLoading(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .get(`${BASE_URL}/api/v1/user/list`, { headers })
      .then((res) => {
        const usersData = res.data?.data;
        const userList = usersData?.filter((item) => item.role_id != 1);
        setUsers(userList);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteUser = (idUser) => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios.post(`${BASE_URL}/api/v1/user/delete`, { id:idUser }, { headers })
    .then(response => {
        if(response.data.success){
          let type = 'success'
          let mess = response.data?.message;

          Utils.notif(type, mess);
          getUsers();
        }
    })
    .catch(error => {
      console.log(error);
    });
  };

  const setTabFilters = (arr) => {
    if (arr?.length > 0) {
      let i;
      for (i = 0; i < arr?.length; i++) {
        arr[i].text = arr[i].label;
        arr[i].value = arr[i].id;
      }
      return arr;
    }
  };

  const onSearch = (value) => {
    const text = value.toLowerCase();
    setWordSearch(text);
  }

  const confirmDelete = (id) => {
    deleteUser(id);
  };

  const cancelDelete = (e) => {
    console.log(e);
  };

  const goDetail = (idUser) => {
    props.history.push(`/dashboard/users/${idUser}`)
  }

  const columns = [
    {
      title: props.t("company_name"),
      dataIndex: "company_name",
      key: "company_name",
      sorter: (a, b) => a.company_name.localeCompare(b.company_name),
      onCell: (record, rowIndex) => {
        return {
            onClick: () => {
              goDetail(record.id);
            },
        };
      },
    },
    {
      title: props.t("name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      onCell: (record, rowIndex) => {
        return {
            onClick: () => {
              goDetail(record.id);
            },
        };
      },
    },
    {
      title: props.t("firstname"),
      dataIndex: "firstname",
      key: "firstname",
      onCell: (record, rowIndex) => {
        return {
            onClick: () => {
              goDetail(record.id);
            },
        };
      },
    },
    {
      title: props.t("email"),
      dataIndex: "email",
      key: "email",
      onCell: (record, rowIndex) => {
        return {
            onClick: () => {
              goDetail(record.id);
            },
        };
      },
      render: (text) => {
        return (
          <p>
            {text}
          </p>
        );
      },
    },
    {
      title: props.t("delivery_address"),
      dataIndex: "billing_address",
      key: "billing_address",
      onCell: (record, rowIndex) => {
        return {
            onClick: () => {
              goDetail(record.id);
            },
        };
      },
      render: (text) => {
        return (
          <p>
            {text}
          </p>
        );
      },
    },
    {
      title: props.t("role"),
      dataIndex: 'role_id',
      key: "role_id",
      filters: setTabFilters(roles),
      onFilter: (value, record) =>
        record.role_id
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
      filterIcon: (filtered) => (
        <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onCell: (record, rowIndex) => {
        return {
            onClick: () => {
              goDetail(record.id);
            },
        };
      },
      render: (text, row) => { return row.role?.label }
    },
    {
      title: props.t("pc_remise"),
      dataIndex: "remise",
      key: "remise",
      align:'right',
      render: (text) => {
        return (
          <p>
            {text?text:0}
          </p>
        );
      },
    },
    {
      title: props.t("pc_acompte"),
      dataIndex: "acompte",
      key: "acompte",
      align:'right',
      render: (text) => {
        return (
          <p>
            {text?text:0}
          </p>
        );
      },
    },
    {
      title: props.t("actions"),
      dataIndex: "id",
      render: (text) => {
        return (
          <>
          <div style={{display:'flex'}}>
            <Link
              to={{
                pathname: `/dashboard/users/${text}`,
                state: { modal: true },
              }}
            > 
              <Button
                type="primary"
                onClick={() => null}
                shape="circle"
                className="mr-3"
                icon={<EditOutlined />}
              ></Button>
            </Link>
            <Popconfirm
              title="Are you sure to delete this user ?"
              description=""
              onConfirm={() => confirmDelete(text)}
              onCancel={cancelDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="danger"
                onClick={() => null}
                shape="circle"
                icon={<DeleteOutlined />}
              ></Button>
            </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];

  const addButton = (
    <Link
      to={{
        pathname: `/dashboard/users/new`,
        state: { modal: true },
      }}
    >     
      <Button
          type="primary"
          onClick={() => null}
          className="btn-shadow"
      >
            + {props.t('add_user')}
      </Button>
    </Link>
  );

  return (
    <Card 
        title={<Title level={3}>{props.t("users")}</Title>}
        extra={addButton}
    >
      <Card>
          <Search
            placeholder={props.t("search_for_user")}
            allowClear
            enterButton
            onKeyUp={(event) => onSearch(event.target.value)}
          />
        </Card>
      <div style={{ overflowX: 'auto' }}>
        <Table
            columns={columns}
            dataSource={users.filter((item) => {
              if (
                item.name.toLowerCase().includes(wordSearch) ||
                item.firstname.toLowerCase().includes(wordSearch) ||
                item.email.toLowerCase().includes(wordSearch) ||
                item.address.toLowerCase().includes(wordSearch)
              ) {
                return true;
              }
              return false;
            })}
            rowKey="id"
            pagination={{ hideOnSinglePage: true, pageSize: 15 }}
            loading={{indicator: <LoadingOutlined/>, spinning: isLoading}}
        />
      </div>
    </Card>
  );
};

export default withTranslation()(withRouter(Users));
