import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Button,
  Select,
  Form,
  Input,
  Card,
  Typography,
  Row,
  Col,
  Tag,
  Modal,
  Popconfirm,
  Table,
  Upload,
  Space,
  Radio,
  Checkbox,
} from "antd";
import BASE_URL from "../../../config.js";
import { Utils } from "../../../services/Utils.js";
import "../../../assets/css/addItems.css";
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { Title,Text } = Typography;

const AddStock = (props) => {
  const [formCreateItem] = Form.useForm();
  const params = useParams();
  const [formVariant] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileNameC, setFileNameC] = useState(null);
  const [catalog_file, setCatalog_file] = useState(null);
  const [category, setCategory] = useState([]);
  const [colorTab, setColorTab] = useState([]);
  const [colorTabT, setColorTabT] = useState([]);
  const [colors, setColors] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [columns, setColumns] = useState([]);
  const [imageColor, setImageColor] = useState(null);

  const history = useHistory();
  const [valueStocks, setValueStocks] = useState([]);
  const [checkeds, setCheckeds] = useState([]);
  const [fabricSelected, setFabricSelected] = useState();
  const [colorSelected, setColorSelected] = useState(null);
  const [sizeMin, setSizeMin] = useState(32);
  const [sizeMax, setSizeMax] = useState(54);
  const [weeks, setWeeks] = useState([]);
  const [weekSelected, setWeekSelected] = useState();
  const [newStock, setNewStock] = useState([]);
  const [allSizeSelectAdd, setAllSizeSelectAdd] = useState([]);
  const [sizeAdd, setSizeAdd] = useState(null);
  const [weekSelected1, setWeekSelected1] = useState();
  const [sizeDeleted, setSizeDeleted] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [selectedReference, setSelectedReference] = useState();
  const [selectedRescolor, setSelectedRescolor] = useState();
  const [fabricName, setFabricName] = useState(null);
  const [previsionalArrival, setPrevisionalArrival] = useState();
  const [lastCheck, setLastCheck] = useState();
  const [references, setReferences] = useState([]);
  const [rescolor, setRescolor] = useState([]);
  const [erreur, setErreur] = useState({});

  const changeFabric = (value) =>{
    setFabricName(value);
  }

  const changePrevision = (value) =>{
    setPrevisionalArrival(value.target.value);
  }
  const changeLast = (value) =>{
    console.log(value.target.value);
    setLastCheck(value.target.value);
  }

  const saveStock = () =>{
    // console.log(new Date())
    // console.log(new Date(previsionalArrival+"T23:59:59"))
    if(new Date(previsionalArrival+"T23:59:59")<new Date()){
      setErreur({borderColor:'red'});
      return;
    }
    const data = {
      // fabric:1,
      fabric:fabricName,
      previsional_arrival_date:previsionalArrival,
      last_date_check: lastCheck || dateNow,
      color:rescolor
    }
    console.log(data);
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    
    axios
      .post(`${BASE_URL}/api/v1/stock/add`, data, { headers })
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          let type = "success";
          let mess = response.data?.message;
          Utils.notif(type, mess);
          history.push("/dashboard/stocks");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.data.errors.email != null) {
          let type = "error";
          let mess = error.response.data.errors.email[0];
          Utils.notif(type, mess);
        }else{
          let type = "error";
          Utils.notif(type, "Error");
        }
      });
  }

  const addColor = () => {
    const temp = [...rescolor];
    let isClick = false;
    temp.map(item =>{
      if(item.id==selectedRescolor.split(':.:.:')[0]){
        isClick = true;
      }
    })
    temp.push({id:selectedRescolor.split(':.:.:')[0],label:selectedRescolor.split(':.:.:')[1],quantity:0});
    const tempR = [...colors];
    const res = [];

    tempR.map(itemR=>{
      let isExist = false;
      temp.map(item =>{
        if(itemR.id == item.id){
          isExist = true;
        }
      })
      if(isExist==false){
        res.push(itemR);
      }
    })

    if(isClick==false){
      setColors([...res]);
      setRescolor([...temp]);
    }
  }

  const addReference = () => {
    const temp = [...references];
    let isClick = false;
    temp.map(item =>{
      if(item.ref==selectedReference.split(':.:.:')[0]){
        isClick = true;
      }
    })
    temp.push({ref:selectedReference.split(':.:.:')[0],name:selectedReference.split(':.:.:')[1],consommation:0});

    const tempR = [...products];
    const res = [];
    
    tempR.map(itemR=>{
      let isExist = false;
      temp.map(item =>{
        if(itemR.id == item.ref){
          isExist = true;
        }
      })
      if(isExist==false){
        res.push(itemR);
      }
    })
    
    if(isClick==false){
      setProducts([...res]);
      setReferences([...temp]);
    }
    
  }

  const changeColor = (value) =>{
    setSelectedRescolor(value);
  }

  const changeReference = (value) =>{
    // getItem(value);
    console.log(value)
    setSelectedReference(value);
  }

  const getProducts = () => {
    setIsLoadingData(true);
    const Authorization = localStorage.getItem("token");
    const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + Authorization,
    };

    axios
    .get(`${BASE_URL}/api/v1/stock/list`, { headers })
    .then((res) => {
        const productData = res.data?.data;
        setProducts(productData);
        setIsLoadingData(false);
    })
    .catch((error) => {
        setIsLoadingData(false);
        console.log(error);
    });
  };
  const [dateNow, setDateNow] = useState(new Date().getFullYear()+"-"+("0" + ((new Date().getMonth()+1))).slice(-2)+"-"+("0" + ((new Date().getDate()))).slice(-2));
  useEffect(() => {
    getColors("");
    getProducts();
    // getCategory();
    setColumns(columnsList);
    // getFabrics();
  }, []);

  const changeTissu = (value) =>{
    const temp = [...stockColor];
    temp.map((item,i)=>{
      if(item.color.label==colorSelected.label){
        temp[i].stocks.tissu = parseInt(value);
      }
    })
    setStockColor([...temp]);
  }
  const changeAncien = (value) =>{
    const temp = [...stockColor];
    temp.map((item,i)=>{
      if(item.color.label==colorSelected.label){
        temp[i].stocks.ancien = parseInt(value.target.value);
      }
    })
    setStockColor([...temp]);
  }
  const changeQuantity = (id,value) =>{
    const temp = [...rescolor];
    temp.map((item,i)=>{
      if(item.id==id){
        temp[i].quantity = value.target.value;
      }
    })
    setRescolor(temp);
    // const temp = [...stockColor];
    // temp.map((item,i)=>{
    //   if(item.color.label==colorSelected.label){
    //     temp[i].stocks.quantity = parseInt(value.target.value);
    //   }
    // })
    // setStockColor([...temp]);
  }
  const changeConssomation = (id,value) =>{
    const temp = [...references];
    temp.map((item,i)=>{
      if(item.ref==id){
        temp[i].consommation = value.target.value;
      }
    })
    setReferences(temp);
    // const temp = [...stockColor];
    // temp.map((item,i)=>{
    //   if(item.color.label==colorSelected.label){
    //     temp[i].stocks.consommation = parseInt(value.target.value);
    //   }
    // })
    // setStockColor([...temp]);
  }

  const init = (colorsArg) =>{
    const temp = [];
    console.log("ARF",colorsArg)
    colorsArg.map((item)=>{
      var variants = [];
      item.variantes.map((itemVariante)=>{
        const res = {
          id:itemVariante.id,
          key: itemVariante.key,
          value: itemVariante.value,
          in_stock_quantity: parseInt(itemVariante.stocks.in_stock[0]?.quantity?itemVariante.stocks.in_stock[0].quantity:0),
          future_one_quantity: parseInt(itemVariante.stocks?.future_one[0]?.quantity?itemVariante?.stocks?.future_one[0]?.quantity:0),
          future_two_quantity: parseInt(itemVariante.stocks?.future_two[0]?.quantity?itemVariante?.stocks?.future_two[0]?.quantity:0),
          future_one_week: parseInt(itemVariante.stocks?.future_one[0]?.number_week?itemVariante?.stocks?.future_one[0]?.number_week:0),
          future_two_week: parseInt(itemVariante.stocks?.future_two[0]?.number_week?itemVariante?.stocks?.future_two[0]?.number_week:0)
        };
        // console.log("RESY",res)
        // alert(itemVariante.stocks?.future_one[0]?.number_week)
        variants.push(res);
      })
      const res = {
        color:item,
        label:item.label,
        color_file:item.image,
        variantes:variants,
      };
      
      temp.push(res);
    })
    console.log("TEEEEEE",temp)
    getSizeSelect(selectedRange,temp,colorsArg[0]);
    setStockColor([...temp]);
    setNewStock([...temp]);
  }

  const choseColor = (color) =>{
    const temp = [...stockColor];
    var isExist = false;
    temp.map((item,i)=>{
      if(item.label==color.label){
        isExist = true;
        temp[i].stocks = {
          tissu:item.stocks.tissu,
          ancien:item.stocks.ancien,
          quantity:item.stocks.quantity,
          consommation:item.stocks.consommation
        };
        setStockColor([...temp]);
        setColorSelected(color); 
      }
    })
    if(isExist==false){
      
      const resA = {
        color:color,
        label:color.label,
        stocks:{
          tissu:null,
          ancien:null,
          quantity:null,
          consommation:null
        },
      };
      temp.push(resA);
      setStockColor([...temp]);
      setColorSelected(color);
        
    }

    
    setStockColor([...temp]);
    setColorSelected(color);
  }

  const getItem = (id) => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    try {
      axios.get(`${BASE_URL}/api/v1/catalog/show/${id}`, { headers })
      .then((res)=>{
        if(res.data.success){
          const catalog = res.data?.data;
          console.log("catalog",catalog)
          // // console.log("catalog", catalog);
          formCreateItem.setFieldsValue({
            name: catalog.name,
            reference: catalog.reference +" - "+catalog.name,
            description: catalog.description,
            matiere: catalog.fabric.id,
            range: catalog.range,
            famille: catalog.famille,
            sale_price: catalog.price.ressel_price,
            purchase_price: catalog.price.purchase_price,
            category_id: parseInt(catalog.category.id),
          });
          setFabricSelected(catalog.fabric);
          setSelectedRange(catalog.range);
          getColors(catalog.fabric.id);
          // setColorSelected(catalog.colors[0]);
          // setCatalog_file(catalog.images[0]);
          // init(catalog.colors);
          
          // getSize(catalog,catalog.range);
        }
      })
      .catch(err => {
        console.log(err)
      })
    } catch (error) {
      console.log(error)
    }
  };

  const getCategory = () => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .get(`${BASE_URL}/api/v1/category/list?parent_id=`, { headers })
      .then((res) => {
        const categoryData = res.data?.data;
        setCategory(categoryData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveItems = (value) => {
    console.log("stock",stockColor);
    
  };



  const confirmDeleteColor = (labelColor) => {
    // let tabColor = COLORTABS;
      
    //   let tempTab = tabColor.length > 0 && tabColor.filter(item => item.label !== labelColor);


    //   COLORTABS = tempTab;
    //   setColorTab(tempTab);

  };

  const cancelDelete = (e) => {
    console.log(e);
  };


  const getFabrics = () => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .get(`${BASE_URL}/api/v1/fabricant/list`, { headers })
      .then((res) => {
        const fabrics = res.data?.data;
        setFabrics(fabrics);
        // setFabrics(fabricsT);
        console.log(fabrics);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getColors = (id) => {
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .get(`${BASE_URL}/api/v1/color-list?fabricant_id=` + id, { headers })
      .then((res) => {
        const colors = res.data?.data;
        // setColors(couleur);
        // setImageColors(couleur);
        // console.log("couleur",couleur)
        // console.log("color",colors)
        // console.log("imageColors",imageColors)
        setColors(colors);
        // setColorSelected(colors[0]);
        console.log("colors",colors);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeDImage = (event) =>{
    var photo = Object.assign(event.target.files[0], {
      preview: URL.createObjectURL(event.target.files[0]),
    });
    setCatalog_file(photo);
  }

  const [selectedRange, setSelectedRange] = useState("MAN");

  const [allSizeSelect, setAllSizeSelect] = useState([]);
  const getSizeSelect = (rangeArg,temp,color) =>{
    const Authorization = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Authorization,
    };
    axios
      .get(`${BASE_URL}/api/v1/size-list?range=${rangeArg}`, { headers })
      .then((res) => {
        const sizeR = res.data?.data;
        var rest = [];
        sizeR.map((itemA)=>{
          temp.map((item)=>{    
            if(item.label==color.label){
              var isEx = false;
              item.variantes.map((itemV)=>{
                if(itemV.value==itemA.name){
                  isEx = true;
                }
              })
              if(isEx==false){
                rest.push(itemA);
              }
            }
          })
        })

        setAllSizeSelectAdd([...rest]);
        setAllSizeSelect([...sizeR]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const columnsList = [
    {
      title: props.t("name"),
      dataIndex: "label",
      key: "label",
      //   sorter: (a, b) => a.label.localeCompare(b.label),
    },
    {
      title: "Fields",
      dataIndex: "field",
      key: "field",
    },
    {
      title: props.t("actions"),
      dataIndex: "label",
      render: (text) => {
        return (
          <>
            {/* <Link
                    to={{
                      pathname: `/dashboard/users/${text}`,
                      state: { modal: true },
                    }}
                  > 
                    <Button
                      type="primary"
                      onClick={() => null}
                      shape="circle"
                      className="mr-3"
                      icon={<EditOutlined />}
                    ></Button>
                  </Link> */}
            <Popconfirm
              title="Are you sure to delete this color ?"
              description=""
              onConfirm={() => confirmDeleteColor(text)}
              onCancel={cancelDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="danger"
                onClick={() => null}
                shape="circle"
                icon={<DeleteOutlined />}
              ></Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const [stockColor, setStockColor] = useState([]);

  return (
    <>
      <Card className="card-add__item" title={<Title level={3}>{props.t("add_stocks")}</Title>}>
      <div className="row basic-infos">
            <div className="col-md-3 col-12">
            </div>
            <div className="col-md-6 col-12">
                <p style={{marginBottom:'0px'}}>Fabric name</p>
                <Select
                  style={{width:'100%'}}
                  allowClear={false}
                  placeholder={props.t("catalogue")}
                  showSearch
                  optionFilterProp="children"
                  onChange={changeFabric}
                >
                  {products &&
                    products.map((item) => (
                      <Select.Option key={`key-${item.id}`} value={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                </Select>
            </div>
            <div className="col-md-3 col-12">
            </div>
        </div>
        <br></br>
        <div className="row basic-infos">
            <div className="col-md-3 col-12">
            </div>
            <div className="col-md-6 col-12">
                <p style={{marginBottom:'0px'}}>Last stock check</p>
                <Input type="date" defaultValue={dateNow} onChange={changeLast} />
            </div>
            <div className="col-md-3 col-12">
            </div>
        </div>
        <br></br>
        <div className="row basic-infos">
            <div className="col-md-3 col-12">
            </div>
            <div className="col-md-6 col-12">
                <p style={{marginBottom:'0px'}}>Estimated date of arrival</p>
                <Input style={erreur} id="dateEst" type="date" onChange={changePrevision} />
            </div>
            <div className="col-md-3 col-12">
            </div>
        </div>
        <br></br>
        {
          references.length>0 &&(
            <>
              <div className="row basic-infos">
                <div className="col-md-3 col-12">
                </div>
                <div className="col-md-6 col-12">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Product name</th>
                      <th scope="col">Consommation moyenne</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      references.map((item) =>{
                        return (
                          <tr>
                            <th scope="row" style={{verticalAlign:'middle'}}>{item.name}</th>
                            <td><Input type="number" defaultValue={item.consommation} onChange={(e)=>{changeConssomation(item.ref,e)}} /></td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                </div>
                <div className="col-md-3 col-12">
                </div>
            </div>
            <br></br>
            </>
          )
        }
        <div className="row basic-infos">
            <div className="col-md-3 col-12">
            </div>
            <div className="col-md-5 col-10">
              <p style={{marginBottom:'0px'}}>Color</p>
                <Select
                  style={{width:'100%'}}
                  allowClear={false}
                  placeholder={props.t("color")}
                  showSearch
                  optionFilterProp="children"
                  onChange={changeColor}
                >
                  {colors &&
                    colors.map((item) => (
                      <Select.Option key={`key-${item.id}`} value={item.id+":.:.:"+item.label}>
                        {item.label}
                      </Select.Option>
                    ))}
                </Select>
            </div>
            <div className="col-md-1 col-2">
              <br></br>
                <Button type="primary" onClick={addColor} icon={<PlusOutlined />} size="middle" style={{float:'right'}} />
            </div>
            <div className="col-md-3 col-12">
            </div>
        </div>
        <br></br>
        {
          rescolor.length>0 &&(
            <>
              <div className="row basic-infos">
                <div className="col-md-3 col-12">
                </div>
                <div className="col-md-6 col-12">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Color</th>
                      <th scope="col">Quantity (m)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      rescolor.map((item) =>{
                        return (
                          <tr>
                            <th scope="row" style={{verticalAlign:'middle'}}>{item.label}</th>
                            <td><Input type="number" defaultValue={item.quantity} onChange={(e)=>{changeQuantity(item.id,e)}} /></td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                </div>
                <div className="col-md-3 col-12">
                </div>
            </div>
            <br></br>
            </>
          )
        }
        <br></br>
        <div className="row basic-infos">
            <div className="col-md-3 col-12">
            </div>
            <div className="col-md-6 col-12">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                className="mr-3"
                onClick={saveStock}
                disabled={rescolor.length === 0}
              >
                {props.t("btn_save")}
              </Button>
              <Link to="/dashboard/stocks">{props.t("retour_liste")}</Link>
            </Form.Item>
            </div>
            <div className="col-md-3 col-12">
            </div>
        </div>
        
        {/* <Form
          form={formCreateItem}
          name="formCreateItem"
          layout="vertical"
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={saveItems}
          onFinishFailed={null}
          autoComplete="off"
        >
          <h4 style={{ marginBottom: "20px" }}>Basic information :</h4>
          <div className="row basic-infos">
            <div className="col-md-6 col-12">
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label={props.t("reference")}
                name="reference"
                className="perso"
                rules={[
                  {
                    required: true,
                    message: "Please input reference!",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={props.t("categorie")}
                  showSearch
                  optionFilterProp="children"
                  onChange={changeReference}
                >
                  {products &&
                    products.map((item) => (
                      <Select.Option key={`key-${item.id}`} value={item.id}>
                        {item.reference} - {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label={props.t("famille")}
                name="famille"
                rules={[
                  {
                    required: true,
                    message: "Please select famille!",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={props.t("famille")}
                  showSearch
                  optionFilterProp="children"
                  disabled
                >
                  <Select.Option key={`key-1`} value="1">
                    KEY PRODUCT
                  </Select.Option>
                  <Select.Option key={`key-2`} value="2">
                    ON ORDER PRODUCT
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label={props.t("categorie")}
                name="category_id"
                rules={[
                  {
                    required: true,
                    message: "Please select category!",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={props.t("categorie")}
                  showSearch
                  optionFilterProp="children"
                  disabled
                >
                  {category &&
                    category.map((item) => (
                      <Select.Option key={`key-${item.id}`} value={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>


              <Form.Item name="catalog" label="Default Image" labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}>
                {catalog_file!=null && (
                    <label
                      htmlFor="default_image"
                      style={{
                        backgroundImage: `url("${catalog_file.path?BASE_URL+catalog_file.path:catalog_file.preview}")`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                      className="drop-container-image-default"
                    >
                      <Text>+</Text>
                      <Input
                        disabled
                        style={{ display: "none" }}
                        id="default_image"
                        className="file-uploader"
                        type="file"
                        accept="image/*"
                        onChange={onChangeDImage}
                      />
                    </label>
                  )}
                  {catalog_file==null && (
                    <label htmlFor="default_image" className="drop-container-image-default">
                    <Text>+</Text>
                    <Input
                      disabled
                      style={{ display: "none" }}
                      id="default_image"
                      className="file-uploader"
                      type="file"
                      accept="image/*"
                      onChange={onChangeDImage}
                    />
                  </label>
                  )}
              </Form.Item>
            
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label={props.t("fabric")}
                name="matiere"
                rules={[
                  {
                    required: true,
                    message: "Please input fabric!",
                  },
                ]}
              >
                <Select
                  allowClear
                  disabled
                  placeholder={props.t("fabric")}
                  showSearch
                  optionFilterProp="children"
                >
                  {fabrics &&
                    fabrics.map((item) => (
                      <Select.Option key={`key-${item.id}`} value={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label="Fabric used"
                className="perso"
                name="tissu"
              >
                <Select
                  allowClear
                  placeholder={props.t("categorie")}
                  showSearch
                  optionFilterProp="children"
                  onChange={changeTissu}
                >
                  {products &&
                    products.map((item) => (
                      <Select.Option key={`key-${item.id}`} value={item.id}>
                        {item.reference} - {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label="Average consumption"
                className="perso"
                name="consommation"
              >
                <Input onChange={changeConssomation} />
              </Form.Item>
            </div>
            <div className="col-md-6 col-12">
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label={props.t("name")}
                name="name"
                className="perso"
                rules={[
                  {
                    required: true,
                    message: "Please input name!",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label={props.t("sale_price")}
                className="perso"
                name="sale_price"
                rules={[
                  {
                    required: true,
                    message: "Please input sale price!",
                  },
                ]}
              >
                <Input type="number" disabled />
              </Form.Item>
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                wrapperCol={{ span: 16 }}
                label={props.t("purchase_price")}
                className="perso"
                name="purchase_price"
                rules={[
                  {
                    required: true,
                    message: "Please input purchase price!",
                  },
                ]}
              >
                <Input type="number" disabled/>
              </Form.Item>
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                className="card-add__item-description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input description!",
                  },
                ]}
                label={props.t("description")}
              >
                <Input.TextArea style={{height:'60px'}} rows={2} disabled />
              </Form.Item>
              <Form.Item label={props.t("style")} name="range" >
                <Radio.Group defaultValue={selectedRange} disabled>
                  <Radio value="MAN">MAN</Radio>
                  <Radio value="WOMAN">WOMAN</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                label="Ancienne référence"
                className="perso"
                name="ancien"
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          

          {fabricSelected != null && (
            <>
              <div className="variants">
                <div className="row">
                  <div className="col-md-12">
                    <div style={{marginBottom:'5px'}} className="variants-title">
                      <span>Chose color :</span>
                    </div>
                    <div className="variants-lists">
                      {
                        colors.map((item)=>
                          {
                            if(item.label==colorSelected?.label){
                              return (
                                <div onClick={()=>{choseColor(item)}} className="variants-miniature active">
                                  <div className="row variants-miniature__item" style={{backgroundColor:item.code}}></div>
                                  <div>
                                    
                                      <label>{item.label}</label>
                                      {
                                        stockColor.map((itemStock)=>{
                                          if(itemStock.color.label==item.label){
                                            return (<hr className="selected-color"></hr>)
                                          }
                                        })
                                      }
                                    
                                  </div>
                                </div>
                              );
                            }else{
                              return (
                                <div onClick={()=>{choseColor(item)}} className="variants-miniature">
                                  <div className="row variants-miniature__item" style={{backgroundColor:item.code}}></div>
                                  <div>
                                    <label>{item.label}</label>
                                    {
                                      stockColor.map((itemStock)=>{
                                        if(itemStock.color.label==item.label){
                                          return (<hr className="selected-color"></hr>)
                                        }
                                      })
                                    }
                                  </div>
                                </div>
                              );
                            }
                          }
                        )
                      }
                    </div>
                    
                  </div>
                </div>
                
              </div> 
              {
                stockColor.map((itemStock)=>
                {
                  if(itemStock.color.label==colorSelected?.label){
                    return (
                      <>
                        <hr></hr>
                        <div className="row basic-infos">
                          <div className="col-md-4 col-12">

                          </div>

                          <div className="col-md-4 col-12">
                            
                            <Form.Item
                              labelCol={{xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 }}}
                              label="Quantity"
                              className="perso"
                            >
                              <Input defaultValue={itemStock.stocks.quantity} onChange={changeQuantity} />
                            </Form.Item>
                          </div>
                          <div className="col-md-4 col-12">
                            
                          </div>
                        </div>
                      </>
                    )
                  }
                }
                )
              }
              

            </>
          )}
          <div className="footer-addItem">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                className="mr-3"
              >
                {props.t("btn_save")}
              </Button>
              <Link to="/dashboard/stocks">{props.t("retour_liste")}</Link>
            </Form.Item>
          </div>
        </Form> */}
      </Card>
    </>
  );
};

export default withTranslation()(AddStock);
